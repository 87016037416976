import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link } from "@mui/material";
import "./../../exploreCampaigns/exploreCampaignsPage.scss";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useParams } from "react-router-dom";
import { getData } from "../../../api/apiController";

const BlogDetailPage = () => {
  const { id } = useParams();
  const [blogDetail, setBlogDetail] = useState({})

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    const result = await getData(`/blogs/${id}`)
    if (result?.status === 200) {
      setBlogDetail(result?.data?.data)
    }
  }
  const handleFacebookShare = () => {
    // Create a new URL object
    const url = new URL(window.location.href);
    // Get the endpoint or path
    const endpoint = url.pathname; // This will give you "/explore-campaigns/24"

    const urlToShare = `https://www.digidonation.com${endpoint}`;

    // Check if the Facebook SDK is already loaded
    if (window.FB) {
      window.FB.ui({
        method: "share",
        href: urlToShare,
      });
    } else {
      // Load the Facebook SDK asynchronously
      loadFacebookSDK(() => {
        window.FB.ui({
          method: "share",
          href: urlToShare,
        });
      });
    }
  };
  const handleTwitterShare = () => {
    const tweetText = "Check out latest blog on our website!"; // Customize your tweet text
    // Create a new URL object
    const url = new URL(window.location.href);

    // Get the endpoint or path
    const endpoint = url.pathname; // This will give you "/explore-campaigns/24"

    const urlToShare = `https://www.digidonation.com${endpoint}`; // Replace with your actual campaign URL

    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText
    )}&url=${encodeURIComponent(urlToShare)}`;

    window.open(twitterShareUrl, "_blank");
  };

  const handleWhatsAppShare = () => {
    const textToShare = "Check out latest blog on our website: "; // Customize your share message
    // Create a new URL object
    const url = new URL(window.location.href);

    // Get the endpoint or path
    const endpoint = url.pathname; // This will give you "/explore-campaigns/24"

    const urlToShare = `https://www.digidonation.com${endpoint}`; // Replace with your actual campaign URL

    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      textToShare + urlToShare
    )}`;

    window.open(whatsappShareUrl, "_blank");
  };

  const loadFacebookSDK = (callback) => {
    // Load the Facebook SDK asynchronously
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "your-facebook-app-id", // Replace with your actual Facebook App ID
        autoLogAppEvents: true,
        xfbml: true,
        version: "v11.0", // Replace with the desired version
      });
      if (callback) {
        callback();
      }
    };

    // Load the Facebook SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };

  useEffect(() => {
    // Load Facebook SDK on component mount
    loadFacebookSDK();
  }, []);
  return (
    <Box className="exploreCampaignWrapper">
      <div className="blog_detail_banner">
        <img  src={blogDetail?.banner} alt="" />
      </div>
      <Box className="blog_detailpage_inner">
        <Grid container spacing={3} className="row">
          <Grid xs={8} >
            <h3>
              {blogDetail?.title}
            </h3>
          </Grid>
          <Grid xs={4} textAlign={"right"}>
            <div className="social-links">
            <Link>
                  <FacebookRoundedIcon
                    className="fb"
                    onClick={() => handleFacebookShare()}
                  />
                </Link>
                <Link>
                  <TwitterIcon
                    className="tw"
                    onClick={() => handleTwitterShare()}
                  />
                </Link>
                <Link>
                  <WhatsAppIcon
                    className="wp"
                    onClick={() => handleWhatsAppShare()}
                  />
                </Link>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="row">
          <Grid xs={12}>
            <p>
            <div
              dangerouslySetInnerHTML={{
                __html: blogDetail.description,
              }}
            ></div>
            </p>
            
          </Grid>
        </Grid>
        {/* <Grid className="mt-4" xs={12}>{blogDetail.author}</Grid> */}
      </Box>
    </Box>
  );
};

export default BlogDetailPage;
