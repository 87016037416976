import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, Tooltip } from "@mui/material";
import "./Signup.scss";
import { Navigate, useNavigate } from "react-router-dom";
import InputTextField from "./../../components/FieldTypes/TextField/TextField"
const Signin = () => {
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    userID: { value: "", warning: false },
    password: { value: "", warning: false },
  });
  const fieldsUpdateState = (attrName, value) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };
  return (
    <Box className="signupWrapper">
      <Grid container className="row">
        <Grid xs={6} className="left-part">
          <Link
            className="logo_position"
            display={"inline-block"}
            onClick={() => navigate("/")}
          >
            <img src="./images/digidonation-logo.svg" alt="" className="logo" />
          </Link>
          <div className="su_details">
            <Grid container spacing={3}>
              <Grid xs={12}>
                <h2>
                  Sign<span>In</span>
                </h2>
              </Grid>
              <Grid xs={12}>
                <InputTextField
                  class_name="inputField "
                  title={"User ID*"}
                  value={formValue.userID.value}
                  attrName={["userID", "value", formValue, setFormValue]}
                  value_update={fieldsUpdateState}
                  warn_status={formValue.userID.warning}
                  error_messg={""}
                />
              </Grid>
              <Grid xs={12}>
                <InputTextField
                  class_name="inputField "
                  title={"Password*"}
                  value={formValue.password.value}
                  attrName={["password", "value", formValue, setFormValue]}
                  value_update={fieldsUpdateState}
                  warn_status={formValue.password.warning}
                  error_messg={""}
                />
              </Grid>
              <Grid xs={12} textAlign={"right"}>
                <p className="acc_p">
                  <Link onClick={() => navigate("/forgot-password")}>Forgot Password?</Link>
                </p>
              </Grid>
              <Grid xs={12}>
                <Button variant="contained" className="donate_sec_btn">
                  Sign In
                </Button>
              </Grid>
              <Grid xs={12} textAlign={"center"}>
                <p className="acc_p">
                  You have Account?{" "}
                  <Link onClick={() => navigate("/sign-up")}>Sign Up</Link>
                </p>
              </Grid>
              <Grid xs={12} textAlign={"center"}>
                <p className="or_p">
                  <span>OR</span>
                </p>
              </Grid>

              <Grid xs={12} className="d_btns">
                <Link className="d_register google">Google</Link>
                <Link className="d_register fb">Facebook</Link>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid xs={6} className="right-part">
          <div className="img-container">
            <img src="./images/signup_img.png" alt="" />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Signin;
