import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button } from "@mui/material";
import "./../exploreCampaigns/exploreCampaignsPage.scss";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { Link } from "react-router-dom"
import { getData } from "../../api/apiController";
import { calculateReadingTime } from "../../helper/helpingFuntions";
const Blogs = () => {
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(false)
  const [allBlogs, setAllBlogs] = useState([]);
  const [allBlogCategories, setAllBlogCategories] = useState([]);
  const [activecategoryIndex, setactivecategoryIndex] = useState(0);
  const [categoryId, setCategoryId] = useState("");
  const [loader, setloader] = useState(true);

  useEffect(() => {
    fetchAllBlogs();
    fetchAllCategory()
  }, [categoryId, page]);

  const fetchAllBlogs = async () => {
    try {
      const result = await getData(`blogs?campaign_category_id=${categoryId}&page=${page}`);
      if (result?.status === 200) {
        setloader(false)
        if(categoryId){
          setAllBlogs(result?.data?.data)
        } else {
          let newArr = [...allBlogs]
          setAllBlogs([...newArr, ...result?.data?.data]);
        }
        setNextPage(result?.data?.next_page)
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const fetchAllCategory = async () => {
    try {
      const result = await getData(`campaign_categories/blogs_categories`);
      if (result?.status === 200) {
        result.data &&
          result?.data?.data?.unshift({
            name: "ALL",
            id: "ALL",
          });
        setAllBlogCategories(result?.data?.data);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  // Get Campaign bby category
  const handleCallByCategory = (category_id) => {
    setCategoryId(category_id);
    setPage(1)
  };

  return (
    <Box className="exploreCampaignWrapper">
      <div className="as-banner-sec">
        <img src="./images/Blog.jpg" alt="" />
      </div>

      <div className="filterSection mb-7">
        <Grid container spacing={3} className="row">
          <Grid xs={12} className="mainFilters mb-0">
            {allBlogCategories &&
              allBlogCategories?.length > 0 &&
              allBlogCategories?.map((item, index) => (
                <span
                  className={index === activecategoryIndex ? "radioBox activecategory" : "radioBox"}
                  onClick={() => {
                    handleCallByCategory(item?.id === "ALL" ? "" : item?.id);
                    setactivecategoryIndex(index)
                  }}
                  key={item?.id}
                >
                  <label htmlFor={item?.name}>
                    {item?.name}
                    <input type="radio" id={item?.name} name="mainFilters" />
                  </label>
                </span>
              ))}
          </Grid>
        </Grid>
      </div>

      {loader ?
        <div className="loader">
          <div class="loading"></div>
        </div>:
      <Box>
        <Grid container spacing={3} className="row">
          <Grid xs={12}>
            {allBlogs?.map((data, index) => (
              <Link to={`/blog-detail/${data.id}`} >
                <Box className={`blog_box ${index % 2 === 0 ? "" : "evenbox"}`}>
                  <Box className="blog_detail" position={"relative"}>
                    <h3>{data.title}</h3>
                    <p className="sub_desc">{data.description}</p>
                    <Box
                      display={"flex"}
                      gap={"16px"}
                    >
                      <p className="publish_p">
                        <CalendarMonthIcon /> 26 jan, 2023
                      </p>
                      <p className="publish_p">
                        <WatchLaterIcon /> {calculateReadingTime (data.description)} Min read
                      </p>
                    </Box>
                  </Box>
                  <Box className="blog_img">
                    <img src={data.banner} alt="" />
                  </Box>
                </Box>
              </Link>
            ))}
          </Grid>
          <Grid>
          
            {nextPage && <Button className="yellowBtn" onClick={() => setPage((prev) => prev + 1)}>See More</Button>}
          </Grid>
        </Grid>
      </Box>
      }
    </Box>
  );
};

export default Blogs;
