import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link } from "@mui/material";
import "./../exploreCampaigns/exploreCampaignsPage.scss";
import "./../../index.scss";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Carousell from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const AboutUs = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1150 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1149, min: 464 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const awards = [
    { img_url: "./images/dummy_award_img1.png" },
    { img_url: "./images/dummy_award_img2.png" },
    { img_url: "./images/dummy_award_img3.png" },
    { img_url: "./images/dummy_award_img1.png" },
    { img_url: "./images/dummy_award_img2.png" },
    { img_url: "./images/dummy_award_img3.png" },
  ];
  return (
    <Box className="exploreCampaignWrapper">
      <div className="header">
        <Grid container spacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} sm={4}>
            <h1>
              <span> About</span>
              <br />
              Us
            </h1>
          </Grid>
          <Grid xs={12} sm={8}>
            <p className="sub_desc" style={{ textAlign: "justify" }}>
              We've brought about a transformation in the crowdfunding landscape
              through our unique platform, which enables individuals from across
              the globe to contribute to essential items required by non-profit
              organizations. By prioritizing trust, transparency, and
              compassion, we're committed to making a positive difference in the
              world by uniting individuals to make a meaningful and enduring
              difference.
            </p>
          </Grid>
        </Grid>
      </div>

      <div className="as-banner-sec" style={{ marginBottom: "80px" }}>
        <img src="./images/aboutusnew.jpg" alt="" />
      </div>

      <div className="journey-sec mt-10">
        <Grid container spacing={3} className="row mb-12">
          <Grid xs={12} sm={4}>
            <h2>
              <span> Our</span> Journey
            </h2>
          </Grid>
          <Grid xs={12} sm={8} textAlign="justify">
            <p className="sub_desc">
              At DigiDonation, our journey began with a simple idea: to unite
              people's generosity for a greater purpose. Since then, we've grown
              into a thriving community of givers, NGOs, and changemakers. Our
              path is marked by milestones of impact, and together, we're still
              writing the story of positive change. Join us at DigiDonation in
              shaping a more compassionate world.
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="row">
          <Grid xs={12}>
            <img src="./images/journey.png" alt="" className="journey_banner" />
          </Grid>
        </Grid>
      </div>

      <div className="unique-sec">
        <Grid container spacing={3} className="row">
          <Grid xs={12} sm={12} md={6} lg={6}>
            <h2>
              What make us<span> Unique</span>
            </h2>
            <p className="sub_desc" style={{ textAlign: "justify" }}>
              At DigiDonation, your security and transparency are paramount.
              We've taken rigorous steps to protect your data and transactions
              while ensuring complete clarity in how your donations are
              utilized. Trust in DigiDonation for secure and transparent giving.
            </p>
            <ul className="unique-points">
              <li>
                Security and Clarity You <span>Can Trust</span>
              </li>
              <p>
                At DigiDonation, your security and transparency are paramount.
                We've taken rigorous steps to protect your data and transactions
                while ensuring complete clarity in how your donations are
                utilized. Trust in DigiDonation for secure and transparent
                giving.
              </p>
              <li>
                Stay Informed with <span>regular updates</span>
              </li>
              <p>
                At DigiDonation, we go above and beyond to keep you
                well-informed. With our platform, you'll receive timely,
                comprehensive updates on the impact your donations are making.
                We believe in transparency, and that means you'll see the
                difference you're creating in real time. Join DigiDonation for a
                giving experience that keeps you closely connected to the causes
                you support.
              </p>
              <li>
                Caring for You with <span>DigiDonation</span>
              </li>
              <p>
                At DigiDonation, we're dedicated to your well-being. Our
                commitment to your safety and satisfaction shines through every
                interaction. With our user-friendly platform and attentive
                support, you'll experience a level of care that sets us apart.
                Join DigiDonation and let us show you how much we care.
              </p>
            </ul>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            textAlign={"right"}
            className="img-section"
          >
            <img src="./images/usec_img1.png" alt="" className=" img1 mb-6" />
            <img src="./images/usec_img2.png" alt="" className=" img2" />
          </Grid>
        </Grid>
      </div>

      <div className="our-team">
        <Grid container spacing={3} className="row">
          <Grid xs={12} textAlign={"center"} className="mb-6">
            <h2>
              Our Dedicated<span> Team</span>
            </h2>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="row">
          <Grid xs={6} md={3}>
            <Box className="tm_box">
              <img src="./images/imageceo1.jpg" alt="" />
              <h6>John Doe</h6>
              <p>Founder</p>
            </Box>
          </Grid>
          <Grid xs={6} md={3}>
            <Box className="tm_box">
              <img src="./images/imageceo2.jpg" alt="" />
              <h6>John Doe</h6>
              <p>Founder</p>
            </Box>
          </Grid>
          <Grid xs={6} md={3}>
            <Box className="tm_box">
              <img src="./images/imageceo3.jpg" alt="" />
              <h6>John Doe</h6>
              <p>Founder</p>
            </Box>
          </Grid>
          <Grid xs={6} md={3}>
            <Box className="tm_box">
              <img src="./images/imageceo4.jpg" alt="" />
              <h6>John Doe</h6>
              <p>Founder</p>
            </Box>
          </Grid>
        </Grid>
      </div>

      <div className="awards-sec">
        <Grid container spacing={3} className="row">
          <Grid xs={12} sm={6} md={6} lg={6}>
            <h2>
              Recognitions and<span> Milestones</span>
            </h2>
          </Grid>
          <Grid xs={12} sm={6} md={6} lg={6}>
            <p className="sub_desc" style={{ textAlign: "justify" }}>
              Our achievements go beyond awards; they are measured by the
              countless lives we've touched and the enduring impact we've had on
              communities around the world. With your continued support, we look
              forward to achieving even greater milestones in our mission to
              make the world a better place. Thank you for being a part of our
              journey.
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="row">
          <Grid xs={12}>
            <Carousell
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
            >
              {awards.map((data, index) => (
                <div key={index}>
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      <div className="awardBox">
                        <img src={data.img_url} alt="" />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              ))}
            </Carousell>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default AboutUs;
