import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, Tooltip } from "@mui/material";
import "./Signup.scss";
import { Navigate, useNavigate } from "react-router-dom";
import InputTextField from "./../../components/FieldTypes/TextField/TextField"
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [emailId, setEmailId] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [newPwd, setNewPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [showemailField, setShowemailField] = useState(true);
  const [showotpFields, setShowotpFields] = useState(false);
  const [showResetPwdFields, setShowResetPwdFields] = useState(false);

  const fieldsUpdateState = (attrName, value) => {
    attrName(value);
  };
  const handleOtpChange = (index, value) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = value;
    setOtp(updatedOtp);
  };
  return (
    <Box className="signupWrapper">
      <Grid container className="row">
        <Grid xs={6} className="left-part">
          <Link
            className="logo_position"
            display={"inline-block"}
            onClick={() => navigate("/")}
          >
            <img src="./images/digidonation-logo.svg" alt="" className="logo" />
          </Link>
          <div className="su_details">
            {showemailField && (
              <>
                <Grid container spacing={3}>
                  <Grid xs={12} className="mb-4">
                    <h2>
                      Forgot <span>Password</span>
                    </h2>
                    <p>
                      Enter Your Email for the verification Process, A
                      four-digit number will be sent to that address.
                    </p>
                  </Grid>
                  <Grid xs={12}>
                    <InputTextField
                      class_name="inputField "
                      title={"Enter Your Email ID*"}
                      value={emailId}
                      attrName={setEmailId}
                      value_update={fieldsUpdateState}
                      warn_status={false}
                      error_messg={""}
                    />
                  </Grid>

                  <Grid xs={12}>
                    <Button
                      variant="contained"
                      className="donate_sec_btn"
                      onClick={() => {
                        setShowotpFields(true);
                        setShowemailField(false);
                      }}
                    >
                      Continue
                    </Button>
                  </Grid>
                  <Grid xs={12} textAlign={"center"}>
                    <p className="back_p">
                      <Link onClick={()=>navigate('/sign-in')}>Back</Link>
                    </p>
                  </Grid>
                </Grid>
              </>
            )}
            {showotpFields && (
              <>
                <Grid container spacing={3}>
                  <Grid xs={12} className="mb-4">
                    <h2>
                      Enter 4 Digit <span>Code</span>
                    </h2>
                    <p>Enter 4 digit code that you have received.</p>
                  </Grid>

                  <Grid xs={12}>
                    <div className="otpContainer">
                      {otp.map((value, index) => (
                        <InputTextField
                          key={index}
                          class_name="inputField otpField"
                          title={''}
                          value={value}
                          attrName={(val) => handleOtpChange(index, val)}
                          value_update={fieldsUpdateState}
                          warn_status={false}
                          error_messg={""}
                          placeholder ={'_'}
                        />
                      ))}
                    </div>
                  </Grid>

                  <Grid xs={12}>
                    <Button
                      variant="contained"
                      className="donate_sec_btn"
                      onClick={() => {
                        setShowotpFields(false);
                        setShowResetPwdFields(true);
                      }}
                    >
                      Continue
                    </Button>
                  </Grid>
                  <Grid xs={12} textAlign={"center"}>
                    <p className="back_p">
                      <Link onClick={() => {
                        setShowotpFields(false);
                        setShowemailField(true);
                      }}>Back</Link>
                    </p>
                  </Grid>
                </Grid>
              </>
            )}
            {showResetPwdFields && (
              <>
                <Grid container spacing={3}>
                  <Grid xs={12} className="mb-4">
                    <h2>
                      Reset <span>Password</span>
                    </h2>
                    <p>Enter Your new password</p>
                  </Grid>

                  <Grid xs={12}>
                    <InputTextField
                      class_name="inputField "
                      title={"Enter New Password*"}
                      value={newPwd}
                      attrName={setNewPwd}
                      value_update={fieldsUpdateState}
                      warn_status={false}
                      error_messg={""}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <InputTextField
                      class_name="inputField "
                      title={"Confirm Password*"}
                      value={confirmPwd}
                      attrName={setConfirmPwd}
                      value_update={fieldsUpdateState}
                      warn_status={false}
                      error_messg={""}
                    />
                  </Grid>

                  <Grid xs={12}>
                    <Button
                      variant="contained"
                      className="donate_sec_btn"
                      onClick={() => navigate("/sign-in")}
                    >
                      Submit
                    </Button>
                  </Grid>
                  <Grid xs={12} textAlign={"center"}>
                  <p className="back_p">
                      <Link onClick={() => {
                        setShowotpFields(true);
                        setShowResetPwdFields(false);
                      }}>Back</Link>
                    </p>
                  </Grid>
                </Grid>
              </>
            )}
          </div>
        </Grid>
        <Grid xs={6} className="right-part">
          <div className="img-container">
            <img src="./images/signup_img.png" alt="" />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgotPassword;
