import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link } from "@mui/material";
import "./exploreCampaignsPage.scss";
import "./../../index.scss";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import MultiSelect from "../../components/multiSelect/multiSelect";
import MedicalCampaign from "../../components/medicalCampaign/medicalCampaign";
import { campaignPropsArray } from "../../dataJson/dataJson";
import { getData } from "../../api/apiController";
import CommonCard from "../../components/commanCards/commonApiCard";

const ExploreCampaignsPage = () => {
  const [inputValue, setInputValue] = useState("");
  console.log("inputValueinputValue",inputValue)
  const [allCategories, setAllCategories] = useState([]);
  const [searchParams, setSearchParams] = useState("")
  console.log("searchParams", searchParams)
  const [categoryId, setCategoryId] = useState("");
  const [activecategoryIndex, setactivecategoryIndex] = useState(0);
  const [page, setPage] = useState(1)
  useEffect(() => {
    fetchAllCategories();
  }, []);

  useEffect(() =>{
    const getData = setTimeout(() => {
      setSearchParams(inputValue)
    }, 2000)

    return () => clearTimeout(getData)
  },[inputValue])

  // Get all categories
  const fetchAllCategories = async () => {
    try {
      const result = await getData(`campaign_categories`);
      if (result?.status === 200) {
        result.data &&
          result?.data?.data?.unshift({
            name: "ALL",
            id: "ALL",
          });
        setAllCategories(result?.data?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // Get Campaign bby category
  const handleCallByCategory = (category_id) => {
    setCategoryId(category_id);
  };


  return (
    <Box className="exploreCampaignWrapper">
      <div className="header">
        <Grid container spacing={3} className="row">
          <Grid xs={12} sm={6} md={6} lg={5}>
            <h1>
              <span> Explore</span>
              <br />
              Campaigns
            </h1>
          </Grid>
          <Grid xs={12} sm={6} md={6} lg={7}>
            <p className="sub_desc">
              At DigiDonation, our campaigns are the driving force behind
              creating positive change and making a real impact in the lives of
              individuals and communities. Explore our diverse range of
              campaigns, each addressing critical social issues, and find the
              one that resonates with your passion for creating a better world.
            </p>
          </Grid>
        </Grid>
      </div>

      <div>
        <Grid container spacing={3} className="row">
          <Grid xs={12}>
            <img
              src="./images/Desktop - 4.png"
              alt=""
              className="img-fluid"
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </div>

      <div className="filterSection">
        <Grid container spacing={3} className="row">
          <Grid
            xs={12}
            display={"flex"}
            alignItems={"center"}
            className="filter-inner"
          >
            <div className="input-box">
              <span>
                <SearchOutlinedIcon />
              </span>
              <input
                className="inputfield"
                type="text"
                value={inputValue}
                placeholder="Search by campaign/NGO"
                onChange={(e) => setInputValue(e.target.value)}
              />
            </div>
            {/* <MultiSelect /> */}
          </Grid>
          <Grid xs={12} className="mainFilters">
            {allCategories &&
              allCategories?.length > 0 &&
              allCategories?.map((item, index) => (
                <span
                  className={
                    index === activecategoryIndex
                      ? "radioBox activecategory"
                      : "radioBox"
                  }
                  onClick={() => {
                    handleCallByCategory(item?.id === "ALL" ? "" : item?.id);
                    setactivecategoryIndex(index);
                    setPage(1);
                  }}
                  key={item?.id}
                >
                  <label htmlFor={item?.name}>
                    {item?.name}
                    <input type="radio" id={item?.name} name="mainFilters" />
                  </label>
                </span>
              ))}
          </Grid>
        </Grid>
      </div>

      <div>
        <CommonCard
          endpoint={`campaigns?campaign_category_id=${categoryId}&is_reccuring=false&search=${searchParams}`}
          searchId={categoryId}
          page={page}
          setPage={setPage}
          inputValue={searchParams}
        />
      </div>
    </Box>
  );
};

export default ExploreCampaignsPage;
