import { TextField } from "@mui/material";
import "./../FieldTypes.scss";

const InputTextField = ({
  title = "",
  value = "",
  attrName = "",
  value_update = () => {},
  warn_status = false,
  info_status = false,
  class_name = "inputField",
  error_messg = "Enter valid value",
  type,
  required,
  pattern,
  maxLimit,
  placeholder,
}) => {
  const onChangeText = (updatedValue) => {
    if (maxLimit && updatedValue.length > maxLimit) {
      return;
    } else {
      value_update(attrName, updatedValue);
    }
  };

  return (
    <div className={class_name}>
      <TextField
        fullWidth
        inputProps={{ pattern: pattern, maxLength: maxLimit }}
        label={title}
        variant="outlined"
        value={value}
        onChange={(e) => {
          const inputValue = e.target.value;
          if (type === "number") {
            // Use a regular expression to remove non-numeric characters
            const numericValue = inputValue.replace(/[^0-9]/g, "");
            onChangeText(numericValue);
          } else {
            onChangeText(inputValue);
          }
        }}
        required={required}
        placeholder={placeholder}
      />

      {warn_status == true ? (
        <span className="error">{error_messg}</span>
      ) : null}
    </div>
  );
};

export default InputTextField;
