import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link } from "@mui/material";
import InputTextField from "../../components/FieldTypes/TextField/TextField";
import { FormControl } from '@mui/material';
import { postData } from '../../api/apiController'
import toast from 'react-hot-toast';
import { validateEmail, validateMobileNumber, validateUrl } from "../../shared/validationSchema";

const RequestCampaign = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [formValue, setFormValue] = useState({
        name: { value: "", warning: false },
        title: { value: "", warning: false },
        description: { value: "", warning: false },
        email: { value: "", warning: false },
        phone: { value: "", warning: false },
        orgName: { value: "", warning: false },
        orgWebsite: { value: "", warning: false },
        fbLink: { value: "", warning: false },
        groupName: { value: "", warning: false },
        groupEmail: { value: "", warning: false },
        groupPhone: { value: "", warning: false },
        groupFbLink: { value: "", warning: false },
    });

    const [activeLink, setActiveLink] = useState("ngo");

    const fieldsUpdateState = (attrName, value) => {
        setFormValue((prev) => ({
            ...prev,
            [attrName[0]]: { value: value, warning: false }
        }))
    };

    // On Tab change
    const handleTab = (tab, active) => {
        setActiveTab(active)
        setActiveLink(tab)
        setFormValue((prev) => ({
            ...prev,
            orgName: { value: "", warning: false },
            orgWebsite: { value: "", warning: false },
        }));
    }
    // Submit Request Campaign
    const handleSubmitRequest = async (e) => {
        e.preventDefault()
      
        if (formValue.name.value === "" || !validateEmail(formValue.email.value) || !validateMobileNumber(formValue.phone.value) || formValue.description.value === "" || formValue.title.value === "") {
            if(activeLink === "group" && formValue.orgName.value == ""){
                return toast.error("Enter Valid Values")             
            }
            return toast.error("Enter Valid Values")
        }

        try {
            let body = {}
            body.email = formValue.email.value
            body.full_name = formValue.name.value
            body.phone_number = formValue.phone.value
            body.organization_name = formValue.orgName.value
            body.organization_website_url = formValue?.orgWebsite.value
            body.facebook_page_url = formValue.fbLink.value
            body.title = formValue.title.value
            body.description = formValue.description.value
            body.campaign_type = activeTab
            const result = await postData('/campaigns', body)
            if (result.status == 200) {
                toast.success("Your Request for Campagin is submit")
                setFormValue({
                    name: { value: "", warning: false },
                    title: { value: "", warning: false },
                    description: { value: "", warning: false },
                    email: { value: "", warning: false },
                    phone: { value: "", warning: false },
                    orgName: { value: "", warning: false },
                    orgWebsite: { value: "", warning: false },
                    fbLink: { value: "", warning: false },
                    groupName: { value: "", warning: false },
                    groupEmail: { value: "", warning: false },
                    groupPhone: { value: "", warning: false },
                    groupFbLink: { value: "", warning: false },
                })
            }
        } catch (error) {
            console.log("error")
        }
    }


    return (
        <>
            <Box className="homePageWrapper">
                {/* <Navbar /> */}

                <div className="request-header">
                    <Grid container spacing={3} className="row">
                        <Grid xs={12} md={4} paddingTop={"6px"} paddingBottom={0}>
                            <img src="./images/request-header-img.svg" alt="" />
                        </Grid>
                        <Grid xs={12} md={8} paddingTop={"6px"} paddingBottom={0}>
                            <div className="tabsSection">
                                {/* Render buttons */}
                                <div>
                                    <button className={`nav-link ${activeLink === "ngo" ? "active" : ""
                                        }`}
                                        onClick={() => handleTab('ngo', 0)} style={{ "cursor": "pointer" }}>NGO</button>
                                    <button className={`nav-link ${activeLink === "group" ? "active" : ""
                                        }`} onClick={() => handleTab('group', 1)} style={{ "cursor": "pointer" }}>Individual/Group</button>
                                </div>


                                {/* Render active tab content */}
                                {/*{activeTab === 0 ? ( */}
                                <form onSubmit={(e) => handleSubmitRequest(e)} >
                                    <Grid container spacing={3} className="row" paddingLeft={0}>
                                        <Grid xs={12} paddingLeft={0} paddingRight={0} ><p>Fill if you are an NGO or Social Group</p></Grid>
                                        <Grid xs={12} paddingLeft={0} paddingRight={0}>
                                            <InputTextField
                                                class_name="inputField "
                                                title={"Full Name"}
                                                value={formValue.name.value}
                                                attrName={["name", "value", formValue, setFormValue]}
                                                value_update={fieldsUpdateState}
                                                warn_status={formValue.name.warning}
                                                error_messg={""}
                                                required={true}
                                            />
                                        </Grid>

                                        <Grid xs={12} paddingLeft={0} paddingRight={0}>
                                            <InputTextField
                                                class_name="inputField"
                                                title={"Title"}
                                                value={formValue.title.value}
                                                attrName={["title", "value", formValue, setFormValue]}
                                                value_update={fieldsUpdateState}
                                                warn_status={formValue.title.warning}
                                                error_messg={""}
                                                required={true}
                                            />
                                        </Grid>

                                        <Grid xs={6} paddingLeft={0}>
                                            <InputTextField
                                                class_name="inputField"
                                                title={"Email"}
                                                value={formValue.email.value}
                                                attrName={["email", "value", formValue, setFormValue]}
                                                value_update={fieldsUpdateState}
                                                warn_status={formValue.email.warning}
                                                error_messg={""}
                                                type='email'
                                                required={true}
                                            />
                                        </Grid>

                                        <Grid xs={6} paddingRight={0}>
                                            <InputTextField
                                                class_name="inputField"
                                                title={"Phone Number"}
                                                value={formValue.phone.value}
                                                attrName={["phone", "value", formValue, setFormValue]}
                                                value_update={fieldsUpdateState}
                                                warn_status={formValue.phone.warning}
                                                error_messg={""}
                                                required={true}
                                                type='number'
                                                pattern="^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$"
                                                maxLimit={10}
                                            />
                                        </Grid>

                                        {activeTab === 1 ?
                                            <Grid container xs spacing={3} className="row" paddingLeft={0}>
                                                <Grid xs={6} paddingLeft={0}>
                                                    <InputTextField
                                                        class_name="inputField"
                                                        title={"Organization Name"}
                                                        value={formValue.orgName.value}
                                                        attrName={["orgName", "value", formValue, setFormValue]}
                                                        value_update={fieldsUpdateState}
                                                        warn_status={formValue.orgName.warning}
                                                        error_messg={""}
                                                        required={true}
                                                    />
                                                </Grid>

                                                <Grid xs={6} paddingRight={0}>
                                                    <InputTextField
                                                        class_name="inputField"
                                                        title={"Organization Website"}
                                                        value={formValue.orgWebsite.value}
                                                        attrName={[
                                                            "orgWebsite",
                                                            "value",
                                                            formValue,
                                                            setFormValue,
                                                        ]}
                                                        value_update={fieldsUpdateState}
                                                        warn_status={formValue.orgWebsite.warning}
                                                        error_messg={""}
                                                    />
                                                </Grid>
                                            </Grid>
                                            :
                                            null
                                        }

                                        <Grid xs={12} paddingRight={0} paddingLeft={0}>
                                            <InputTextField
                                                class_name="inputField"
                                                title={"Facebook Page Link"}
                                                value={formValue.fbLink.value}
                                                attrName={[
                                                    "fbLink",
                                                    "value",
                                                    formValue,
                                                    setFormValue,
                                                ]}
                                                value_update={fieldsUpdateState}
                                                warn_status={formValue.fbLink.warning}
                                                error_messg={""}
                                            />
                                        </Grid>

                                        <Grid xs={12} paddingRight={0} paddingLeft={0}>
                                            <InputTextField
                                                class_name="inputField"
                                                title={"Description"}
                                                value={formValue.description.value}
                                                attrName={[
                                                    "description",
                                                    "value",
                                                    formValue,
                                                    setFormValue,
                                                ]}
                                                value_update={fieldsUpdateState}
                                                warn_status={formValue.description.warning}
                                                error_messg={""}
                                                type='textArea'
                                                required={true}
                                            />
                                            {/*<textarea></textarea>*/}
                                        </Grid>


                                        <Button type='submit' variant="contained" className="donate_sec_btn mt-3">
                                            Request Campaign
                                        </Button>

                                    </Grid>
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                </div>

                {/* <Footer /> */}
            </Box>
        </>
    )
}
export default RequestCampaign;

