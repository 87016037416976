import React, { useEffect, useState } from "react";
import { getData } from "../../api/apiController";
import Grid from "@mui/material/Unstable_Grid2";
import MedicalCampaign from "../medicalCampaign/medicalCampaign";
import { useNavigate } from "react-router-dom";

const MedicalCampaignsCards = ({SethideSection}) => {
  const [medicalCampaigns, setMedicalCampaigns] = useState([]);
  const [loader, setloader] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    fetchMedicalCampaigns();
  }, []);

  const fetchMedicalCampaigns = async () => {
    try {
      const result = await getData(
        `campaigns?campaign_category_id=5&is_recurring=false`
      );
      if (result === undefined || result?.data?.data.length === 0) {
        SethideSection(true);
      }
      if (result.status === 200 ) {
        setMedicalCampaigns(result?.data?.data?.splice(0, 3));
        setloader(false);
      }
    } catch (error) {
      setMedicalCampaigns([]);
    }
  };

  const handleCampaingDetail = (id) => {
    navigate(`/explore-campaigns/${id}`);
  };

  return (
    <>
      {loader && (
        <div className="loader">
          <div class="loading"></div>
        </div>
      )}
      {!loader && (
        <Grid container spacing={3} className="row" justifyContent={"center"}>
          {medicalCampaigns.length === 0 ? (
            <div>No records To display</div>
          ) : (
            medicalCampaigns.map((data, index) => (
              <Grid
                xs={12}
                sm={6}
                lg={4}
                onClick={() => handleCampaingDetail(data?.id)}
                key={data?.id}
              >
                <MedicalCampaign
                  imgSource={data.banner_image}
                  campaignHeading={data.title.split(" ")[0]}
                  campaignHeadingSpan={data.title.slice(
                    data.title.split(" ")[0]?.length
                  )}
                  campDesc={data.short_description}
                  postedbyavatar={data.postedbyavatar}
                  postedBy={data.organization_name}
                  percentage={data.percentage}
                  goalAmt={data.fund_required}
                  raisedAmt={data.total_collection}
                  backersCount={data.bakers_count}
                  days={data.days_left}
                />
              </Grid>
            ))
          )}
        </Grid>
      )}
    </>
  );
};
export default MedicalCampaignsCards;
