import React from "react";
import "./../medicalCampaign/medicalCampaign.scss";
import { Button, Link } from "@mui/material";
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import PersonIcon from '@mui/icons-material/Person';
import "./../../index.scss"
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useNavigate } from "react-router-dom";

const DonateMonthlyProgram = ({ percentage,imgSource,campaignHeading,campaignHeadingSpan,campDesc,}) => {
  const navigate = useNavigate();

  return (
    <div className="campaignBox">
      <div className="campaign_imgdiv">
        <img src={imgSource} alt="" />
      </div>

      <div className="innerBox">
        <h5>
          {campaignHeading} <span>{campaignHeadingSpan}</span>
        </h5>
        <p className="mb-4">
          {campDesc}
        </p>
        <div className="donateCta_div">
          <div className="social-links">
            <Link><FacebookRoundedIcon />
            </Link><Link><TwitterIcon />
            </Link><Link><InstagramIcon/></Link>
           </div>
          <Button className="yellow_btn" >Donate Monthly</Button>
        </div>
      </div>

      {/* <div className="cta">
        <Link className="yellow_btn"></Link>
      </div> */}
    </div>
  );
};

export default DonateMonthlyProgram;