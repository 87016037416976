import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./../FieldTypes.scss"



const defaultProps = {
  title: "",
  value: "",
  attrName: "",
  warn_status: false,
  value_update: () => {},
  dropdown_data: [],
  error_messg: "",
  info_status: false,
  info_message: "This is dummy text for select dropdown.",
  sx: "",
};

const SelectDropdown = ({
  title,
  value,
  value_update,
  attrName,
  dropdown_data,
  warn_status,
  class_name,
  error_messg,
  info_status,
  info_message,
  sx,
}) => {
  console.log("---opopopo", value)
  return (
  
    <div className={class_name} style={{ ...sx }}>
      <FormControl fullWidth>
        <InputLabel>{title}</InputLabel>
        <Select
          value={value}
          label={title}
          onChange={(e) => {
            value_update(attrName, e.target.value)
            
            }}>
          {dropdown_data.map((data) => (
            <MenuItem value={data.value == 0 && value === 0 ? 0 : data.value || data.id}>{data.label || data.title}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {info_status === true ? (
        <Tooltip title={info_message === undefined ? "" : info_message}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}
      {warn_status == true ? (
        <span className="error">{error_messg}</span>
      ) : null}
    </div>
  );
};

SelectDropdown.defaultProps = defaultProps;

export default SelectDropdown;
