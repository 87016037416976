// **  data for frontend medical cover component   **//


export const campaignPropsArray = [
  {
    imgSource: "./images/campaign_img1.svg",
    campaignHeading: "New",
    campaignHeadingSpan: "Sustainability",
    campDesc:
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections",
    postedbyavatar: "./images/campaign_creator.png",
    postedBy: "By john hammer",
    percentage: 70,
    goalAmt: 1340,
    raisedAmt: 1340,
    days: 5,
    backersCount: 246,
  },
  {
    imgSource: "./images/campaign_img2.svg",
    campaignHeading: "Free",
    campaignHeadingSpan: "Volunteering",
    campDesc:
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections",
    postedbyavatar: "./images/campaign_creator.png",
    postedBy: "By john hammer",
    percentage: 40,
    goalAmt: 1340,
    raisedAmt: 1340,
    days: 5,
    backersCount: 246,
  },
  {
    imgSource: "./images/campaign_img1.svg",
    campaignHeading: "New",
    campaignHeadingSpan: "Sustainability",
    campDesc:
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections",
    postedbyavatar: "./images/campaign_creator.png",
    postedBy: "By john hammer",
    percentage: 10,
    goalAmt: 1340,
    raisedAmt: 1340,
    days: 5,
    backersCount: 246,
  },
  {
    imgSource: "./images/campaign_img1.svg",
    campaignHeading: "New",
    campaignHeadingSpan: "Sustainability",
    campDesc:
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections",
    postedbyavatar: "./images/campaign_creator.png",
    postedBy: "By john hammer",
    percentage: 70,
    goalAmt: 1340,
    raisedAmt: 1340,
    days: 5,
    backersCount: 246,
  },
  {
    imgSource: "./images/campaign_img2.svg",
    campaignHeading: "Free",
    campaignHeadingSpan: "Volunteering",
    campDesc:
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections",
    postedbyavatar: "./images/campaign_creator.png",
    postedBy: "By john hammer",
    percentage: 40,
    goalAmt: 1340,
    raisedAmt: 1340,
    days: 5,
    backersCount: 246,
  },
  {
    imgSource: "./images/campaign_img1.svg",
    campaignHeading: "New",
    campaignHeadingSpan: "Sustainability",
    campDesc:
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections",
    postedbyavatar: "./images/campaign_creator.png",
    postedBy: "By john hammer",
    percentage: 10,
    goalAmt: 1340,
    raisedAmt: 1340,
    days: 5,
    backersCount: 246,
  },
  {
    imgSource: "./images/campaign_img1.svg",
    campaignHeading: "New",
    campaignHeadingSpan: "Sustainability",
    campDesc:
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections",
    postedbyavatar: "./images/campaign_creator.png",
    postedBy: "By john hammer",
    percentage: 70,
    goalAmt: 1340,
    raisedAmt: 1340,
    days: 5,
    backersCount: 246,
  },
  {
    imgSource: "./images/campaign_img2.svg",
    campaignHeading: "Free",
    campaignHeadingSpan: "Volunteering",
    campDesc:
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections",
    postedbyavatar: "./images/campaign_creator.png",
    postedBy: "By john hammer",
    percentage: 40,
    goalAmt: 1340,
    raisedAmt: 1340,
    days: 5,
    backersCount: 246,
  },
  {
    imgSource: "./images/campaign_img1.svg",
    campaignHeading: "New",
    campaignHeadingSpan: "Sustainability",
    campDesc:
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections",
    postedbyavatar: "./images/campaign_creator.png",
    postedBy: "By john hammer",
    percentage: 10,
    goalAmt: 1340,
    raisedAmt: 1340,
    days: 5,
    backersCount: 246,
  },
];

// common dropdown data 
export const dropdown_data = [
  { label: "Save India’s Starys", value: "Save India’s Starys" },
  { label: "Save India’s Starys", value: "Save India’s Starys" },
  { label: "Save India’s Starys", value: "Save India’s Starys" },
];



export const whyChooseArray = [
{
  img_url: "./images/wc_icon1.svg",
  box_heading: "Create Lasting Change",
  box_desc:
    "Empower lasting change with your monthly contributions, providing a brighter future for thousands.",
},
{
  img_url: "./images/wc_icon2.svg",
  box_heading: "Transparent and Effortless",
  box_desc:
    "Experience the ease of automatic monthly donations, where your chosen amount goes directly to impactful causes.",
},
{
  img_url: "./images/wc_icon3.svg",
  box_heading: "Stay Informed",
  box_desc:
    "Receive regular updates on the tangible difference you're making through your ongoing support.",
},
{
  img_url: "./images/wc_icon4.svg",
  box_heading: "Tax Benefits",
  box_desc:
    "Gain the advantage of an 80G certificate with each donation, allowing you to save on taxes while making a difference.",
},
{
  img_url: "./images/wc_icon5.svg",
  box_heading: "Spread Joy Every Month",
  box_desc:
    "Commence each month on a positive note, knowing that your contributions are bringing smiles and transforming lives.",
},
{
  img_url: "./images/wc_icon1.svg",
  box_heading: "Unlock Rewards",
  box_desc:
    "Unlock the opportunity to win exciting rewards each time you contribute, adding an extra layer of fulfillment to your giving.",
},
];


// export const date_data = [
  
//   { label: "01", value: "01" },
//   { label: "01", value: "01" },
//   { label: "01", value: "01" },
// ];

export const date_data = () => {

  let dateArr = new Array()
  for(let i = 1; i <= 28; i++){
    dateArr.push({ label: i, value: i },)
  }
  return dateArr
}
