import { Button, Checkbox } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import SelectDropdown from "../../components/FieldTypes/SelectDropdown/SelectDropdown";
import InputTextField from "../../components/FieldTypes/TextField/TextField";
import { validateEmail, validateMobileNumber } from "../../shared/validationSchema";
import { getData, postData } from "../../api/apiController";
import { useLocation } from "react-router-dom";
import { handlePayment, handlePaymentRazorPay } from "../../shared/paymentgateway/paymentIntentRazorpay";
import { formatIndianRupee } from "../../helper/helpingFuntions";
import { handlePaymentInstaMojo } from "../../shared/paymentgateway/paymentInstamojo";
import PaymentFailure from "../../components/Popups/PaymentFailure";

function DonateOnce() {
    const location = useLocation("");
    const [paymentFailedStatus,setPaymentFailedStatus] = useState(false)
    const [compDonationValue_data, setCompDonationValueData] = useState([]);
    const nationality_data = [
        { label: "I'm An Indian National", value: "I'm An Indian National" },
        {
            label: "I'm Not An Indian National",
            value: "I'm Not An Indian National",
        },
    ];
    const [donationProducts, setDonationProducts] = useState(
        location?.state?.donation_products
    );
    const [donation, setDonation] = useState({
        amount:
            location?.state?.amount?.unitPayment +
            location?.state?.amount?.enterPayment,
        campaign_request_id: location?.state?.campaign_request_id,
    });
    const [donateOnceFormValue, setDonateOnceFormValue] = useState({
        cause: "",
        donationValue: "",
        date: "",
        name: "",
        email: "",
        phone: "",
        nationality: "",
        anonymous: false,
        commsionAmount: 0,
        gateWay: "",
    });
    useEffect(() => {
        const updateDonationValues = () => {
            const totalAmountCheckout = donation.amount;

            // Initialize an empty array to store the dropdown options
            const updatedData = [];

            // Loop from 16% down to 2% and add options to the updatedData array
            for (let percentage = 16; percentage >= 0; percentage -= 2) {
                const value = Math.round(totalAmountCheckout * (percentage / 100));
                updatedData.push({
                    value: value,
                    label: `${percentage}% (₹ ${value})`,
                });
              
            }
            setCompDonationValueData(updatedData);
        };

        updateDonationValues();

        getActiveGateway()
    }, []);

    const getActiveGateway = async ()=>{
        try{
            const result = await getData("/donations/active_gateway")
            console.log("rer",result)
            setDonateOnceFormValue((prev)=>({
                ...prev,
                gateWay: result?.data?.data?.name
            }))
        }catch (error){
            console.log("errr",error)
        }
    } 

    // update the form state on the onChange
    const fieldsUpdateState = (attrName, value) => {
        console.log("value", value, attrName);
        
        setDonateOnceFormValue((prev) => ({
            ...prev,
            [attrName[0]]: value === undefined ? 0 : value,
        }));
    };
    console.log("---",donateOnceFormValue)
    // function for  payment api with validate the data
    const formSubmission = async () => {
        if (
            donation.amount === 0 ||
            donateOnceFormValue.name === "" ||
            !validateEmail(donateOnceFormValue.email) ||
            !validateMobileNumber(donateOnceFormValue.phone) ||
            donateOnceFormValue.nationality === ""
        ) {
            return toast.error("Please fill all Details")
        } else {
            try {
                let body = {
                    donation_products: donationProducts,
                    donar: {
                        full_name: donateOnceFormValue?.name,
                        email: donateOnceFormValue?.email,
                        phone_number: donateOnceFormValue?.phone,
                        nationality: donateOnceFormValue?.nationality,
                        anonymous: donateOnceFormValue?.anonymous,
                    },
                    donation: {
                        is_recurring: false,
                        recurring_date: donateOnceFormValue?.date?.value,
                        campaign_request_id: donation.campaign_request_id,
                        commission_amount: donateOnceFormValue.commsionAmount,
                        total_amount: donation.amount,
                    },
                    gateway: donateOnceFormValue?.gateWay
                };

                const result = await postData("/donations", body);
                console.log("result", result);
                if (result.status == 200 && donateOnceFormValue.gateWay === "InstaMojo") {
                    handlePaymentInstaMojo(result?.data?.order_id)
                }else if(result.status == 200 && donateOnceFormValue.gateWay === "Razorpay"){
                    let paymentData = {
                      donar: {
                        full_name: donateOnceFormValue?.name,
                        email: donateOnceFormValue?.email,
                        phone_number: donateOnceFormValue?.phone,
                      },
                    };
                     handlePaymentRazorPay(paymentData,
                      result?.data?.order_id
                    );
                }else if(result.status === 404){
                  toast.error(result.data.message)
                  setPaymentFailedStatus(true)
                } 
            } catch (error) {
                console.log("error", error);
            }
        }
    };
    return (
        <><Grid xs={6}>
            <PaymentFailure open={paymentFailedStatus} setOpen={setPaymentFailedStatus} />
            <SelectDropdown
                class_name="inputField orngtxt mb-3"
                title="Support Us"
                value={donateOnceFormValue.commsionAmount}
                attrName={["commsionAmount"]}
                value_update={fieldsUpdateState}
                dropdown_data={compDonationValue_data}
                warn_status={false}
                error_message={"select "}
                info_status={true}
                info_message={
                    "By supporting DigiDonation, you are aiding us in extending our reach to more campaigns like this and expanding our influence, and it is totally optional."
                }
                required={true}
            />
            
        </Grid>
            <Grid xs={6}>
                <InputTextField
                    class_name="inputField orngtxt mb-3"
                    title={"Total Amount"}
                    value={`₹ ${formatIndianRupee(donation.amount)}`}
                    attrName={["donationValue"]}
                    value_update={fieldsUpdateState}
                    warn_status={false}
                    error_messg={""}
                    required={true}
                />
            </Grid>
            <Grid xs={12}>
                <p className="fw-bold">Personal Details</p>
            </Grid>
            <Grid xs={6}>
                <InputTextField
                    class_name="inputField orngtxt mb-3"
                    title={"Full Name"}
                    value={donateOnceFormValue?.name}
                    attrName={["name"]}
                    value_update={fieldsUpdateState}
                    warn_status={false}
                    error_messg={""}
                    required={true}
                />
            </Grid>
            <Grid xs={6}>
                <InputTextField
                    type={"number"}
                    class_name="inputField orngtxt mb-3"
                    title={"Mobile"}
                    value={donateOnceFormValue.phone}
                    attrName={["phone"]}
                    value_update={fieldsUpdateState}
                    warn_status={false}
                    error_messg={""}
                    required={true}
                    maxLimit={10}
                />
            </Grid>
            <Grid xs={6}>
                <InputTextField
                    class_name="inputField orngtxt mb-3"
                    title={"Email"}
                    value={donateOnceFormValue.email}
                    attrName={["email"]}
                    value_update={fieldsUpdateState}
                    warn_status={false}
                    error_messg={""}
                    required={true}
                />
            </Grid>
            <Grid xs={6}>
                <SelectDropdown
                    class_name="inputField orngtxt mb-7"
                    title="Nationality"
                    value={donateOnceFormValue.nationality}
                    attrName={["nationality"]}
                    value_update={fieldsUpdateState}
                    dropdown_data={nationality_data}
                    warn_status={false}
                    error_message={"select "}
                    required={true}
                />
            </Grid>
            <Grid xs={12}>
                <FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={donateOnceFormValue.anonymous}
                                onChange={(e) => {
                                    setDonateOnceFormValue((prev) => ({
                                        ...prev,
                                        anonymous: e.target.checked,
                                    }));
                                }}
                            />
                        }
                        value="anonymous"
                        label="Make my donation anonymous"
                    />
                    {/* <FormControlLabel
                      control={<Checkbox />}
                      value="transactionupdates"
                      label="Transaction Updates on WhatsApp"
                    /> */}
                </FormControl>
            </Grid>
            <Grid xs={12}>
                <Button
                    variant="contained"
                    className="donate_sec_btn mt-3"
                    onClick={formSubmission}
                >
                    Continue To Pay
                </Button>
            </Grid>
        </>
    )
}
export default DonateOnce