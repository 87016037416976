import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import "./../Popups/ModalPopup.scss";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  Input,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

const PaymentSuccess = ({ open, setOpen }) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box className="modalWrapper">
        <Box className="modalContent">
          <Button className="closePopup" onClick={() => setOpen(false)} />
          <Grid container spacing={0} alignItems="center">
            <Grid xs={12}>
              <img src="./images/popup_bg1.png" alt="" />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <div className="content-sec success pt-8">
                <h2 className="mb-4 green">Awesome</h2>
                <p className="mb-4">
                  Thank you for your contribution! You've made a positive
                  impact.
                </p>
                <Button className="yellowBtn" onClick={() => setOpen(false)}>
                  Okay
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default PaymentSuccess;
