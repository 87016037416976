import React, { useState } from "react";
import InputTextField from "../FieldTypes/TextField/TextField";
import { Button } from "@mui/material";
import { validateEmail } from "../../shared/validationSchema";
import { toast } from "react-hot-toast";
import { postData } from "../../api/apiController";

const ReferChangeMaker = () => {
    const [formValue, setFormValue] = useState({
        name: "",
        email: "",
    });

    const fieldsUpdateState = (attrName, value) => {
        setFormValue((prev) => ({
            ...formValue,
            [attrName[0]]: value
        }))
    };

    // Submit Post Request
    const handleSubmit = async() => {
        if (formValue.name === "" || !validateEmail(formValue.email)) {
            toast.error("Enter Valid Details")
            return
        } else {
            try {
                const result = await postData(`change_makers?name=${formValue?.name}&email=${formValue?.email}`)
                if(result.status === 200){
                    toast.success(result?.data?.message)
                    setFormValue({
                        name: "",
                        email: "",
                    })
                }
            } catch (error) {
                console.log("error", error)
            }
        }
    }




    return (
        <>
            <div className="border-box mt-6">
                <h4>Refer A Changemaker</h4>
                <p className="mb-4">
                    Do you know someone who is doing similar work and needs
                    support ?
                </p>
                <InputTextField
                    class_name="inputField mb-5"
                    title={"Name*"}
                    value={formValue.name}
                    attrName={["name", "value", formValue, setFormValue]}
                    value_update={fieldsUpdateState}
                    // warn_status={formValue.name.warning}
                    error_messg={""}
                />
                <InputTextField
                    class_name="inputField mb-5"
                    title={"Email*"}
                    value={formValue.email}
                    attrName={["email", "value", formValue, setFormValue]}
                    value_update={fieldsUpdateState}
                    // warn_status={formValue.email.warning}
                    error_messg={""}
                />
                <Button variant="contained" className="yellow_btn mb-4" onClick={handleSubmit}>
                    Submit
                </Button>
            </div>
        </>
    )

}
export default ReferChangeMaker;