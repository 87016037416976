import axios from "axios";
import { instance } from "./apiConfig";


export const getData = async (endpoint) => {
    try {
        const result = await instance.get(endpoint)
        return result;
    } catch (error) {
    }
}

export const postData = async (endpoint, data) => {
    try {
      const result =  await instance.post(endpoint, data).then((res) => { return res }).catch((error) => {
            return error.response
        })
        return result
    } catch (error) {
    }
}