export const handlePaymentRazorPay = (paymentData, order_id, amount) => {
  
  const { full_name, email, phone_number } = paymentData?.donar
    var options = {
      "key": "rzp_test_VQOxwDFbvAxZmK", // Enter the Key ID generated from the Dashboard
     // "amount": amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": full_name || "Anonymous Donor",
      "description": "Test Transaction",
      "order_id": order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "callback_url": getApiUrl(),
      "prefill": {
          "name": full_name,
          "email": email,
          "contact": phone_number
      },
      "notes": {
          "address": "Razorpay Corporate Office"
      },
      "theme": {
          "color": "#3399cc"
      }
    };
    
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  }




  // get the app running enivorment
  export const getApiUrl = () =>{
    
    switch (process.env.NODE_ENV) {
      case 'development':
        return 'https://dashboard.digidonation.com/api/v1/donations/payment_success'
      case 'production':
        return 'https://dashboard.digidonation.com/api/v1/donations/payment_success'
      default:
        break;
    }
  }