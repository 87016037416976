import React from "react";
import "./medicalCampaign.scss";
import { Avatar, Button, Link } from "@mui/material";
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import PersonIcon from '@mui/icons-material/Person';
import "./../../index.scss"
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { formatIndianRupee } from "../../helper/helpingFuntions";

const MedicalCampaign = ({ percentage,imgSource,campaignHeading,campaignHeadingSpan,campDesc,postedBy,goalAmt,raisedAmt,postedbyavatar, days, backersCount, facebookUrl }) => {  
  return (
    <div className="campaignBox">
      <div className="campaign_imgdiv">
        <img src={imgSource} alt="" />
      </div>

      <div className="innerBox">
        <h5>
          {campaignHeading} <span>{campaignHeadingSpan}</span>
        </h5>
        <p className="mb-2">
          {campDesc}
        </p>
        <div className="created_by">
        <Avatar src="/broken-image.jpg" />
            {/* <img src={postedbyavatar} alt="" /> */}
          <h6>{postedBy}</h6>
        </div>
        <div className="progress-bar">
        <div className="progress" style={{ width: `${(raisedAmt / Number(goalAmt)) * 100}%` }}></div>
        </div>
        <div className="target-div">
   
          <p>
            Raised: <span>{"₹ "+ raisedAmt?.toLocaleString('en-IN')}</span>
          </p>
          <p>
            Goal: <span>{"₹ "+ goalAmt?.toLocaleString('en-IN')}</span>
          </p>
        </div>
        <div className="grey_div">
          <p><WatchLaterOutlinedIcon /><span className="mx-2">{days}</span> Days Left</p>
          <p><PersonIcon /><span className="mx-2">{backersCount === undefined ? 0 : backersCount }</span>Backers</p>
        </div>
        <div className="donateCta_div">
          <div className="social-links">
            <Link to={facebookUrl} ><FacebookRoundedIcon />
            </Link><Link><TwitterIcon />
            </Link><Link><InstagramIcon/></Link>
           </div>
          <Button className="yellow_btn">Donate</Button>
        </div>
      </div>

      {/* <div className="cta">
        <Link className="yellow_btn"></Link>
      </div> */}
    </div>
  );
};

export default MedicalCampaign;