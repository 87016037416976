import React, { createContext, useContext, useState } from "react";

export const GlobalContext = createContext()

export const GlobalContextProvider = ({ children }) =>{
    
    const [test,setTest] = useState('test')
    return(
        <>
        <GlobalContext.Provider 
            value={{ test }}
        >
            {children}
        </GlobalContext.Provider>
        </>
    )
}

