import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link } from "@mui/material";
import { getData } from "../../api/apiController";
import { useNavigate } from "react-router-dom";

const DailyUpdates = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [allBlogs, setAllBlogs] = useState([]);

  useEffect(() => {
    fetchAllBlogs();
  }, [page]);

  const fetchAllBlogs = async () => {
    try {
      const result = await getData(`blogs?page=${page}`);
      if (result?.status === 200) {
        // Slice the array to get only the first three items
        const firstThreeBlogs = result?.data?.data.slice(0, 3);
        setAllBlogs(firstThreeBlogs);
      }
    } catch (error) {
      console.log("err", error);
    }
  };
  

  return (
    <>
      <div className="updates_sec">
        <Grid container spacing={3} className="row">
          <Grid xs={12} textAlign={"center"}>
            <h2>
              Daily <span>updates</span>
            </h2>
            <p className="sub_desc mb-10">
              A Window into the Heart of Our Community. Discover Inspiring
              Stories, Latest News, and Progress of our Initiatives. Stay
              Connected,
              <br /> Stay Informed, and Join Us in the Journey of Creating
              Meaningful Change Every Day.
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="row" justifyContent={"center"}>
          {allBlogs &&
            allBlogs.length > 0 &&
            allBlogs.map((item) => (
              <Grid xs={12} sm={6} lg={4} key={item?.id}>
             
                <div className="update_div">
                <div className="cta" onClick={() => navigate(`/blog-detail/${item.id}`)}>
                    <Link className="yellow_btn"></Link>
                  </div>
                  <img src={item?.banner} alt="" />
                  <h6 style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item?.title}</h6>
                  <p style={{ display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item?.description}</p>
                </div>
              </Grid>
            ))}
        </Grid>
      </div>
    </>
  );
};
export default DailyUpdates;
