import { Button, Checkbox } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Unstable_Grid2";
import { React, useState, useEffect } from "react";
import SelectDropdown from "../../components/FieldTypes/SelectDropdown/SelectDropdown";
import InputTextField from "../../components/FieldTypes/TextField/TextField";
import { useLocation } from "react-router-dom";
import { getData, postData } from "../../api/apiController";
import { handlePayment, handlePaymentRazorPay } from "../../shared/paymentgateway/paymentIntentRazorpay";
import { validateEmail, validateMobileNumber } from "../../shared/validationSchema";
import { toast } from "react-hot-toast";
import { date_data } from "../../dataJson/dataJson";
import { formatIndianRupee } from "../../helper/helpingFuntions";
import { handlePaymentInstaMojo } from "../../shared/paymentgateway/paymentInstamojo";
import PaymentFailure from "../../components/Popups/PaymentFailure";

function DonateMonthly() {
    const location = useLocation("");
    const [paymentFailedStatus,setPaymentFailedStatus] = useState(false)
    // main state for the form
    const [formValue, setFormValue] = useState({
        amount: location?.state === undefined ? 500 : location?.state?.donateMonthly?.enter_amount || 500,
        campaginId: location?.state?.donateMonthly?.dropDown,
        fullName: "",
        mobileNumber: "",
        email: "",
        nationality: "",
        commsionAmount: 0,
        prefferedDate: "",
        anonymous: false,
        gateway: "",
    })
    const [compDonationValue_data, setCompDonationValueData] = useState([]);
    const [getAllCampaignList, setAllCampaignsList] = useState([])
    const nationality_data = [
        { label: "I'm An Indian National", value: "I'm An Indian National" },
        { label: "I'm Not An Indian National", value: "I'm Not An Indian National" },
    ];

    console.log("formValue", formValue)

    useEffect(() => {
        handleGetCampaignList()
        getActiveGateway()
    }, [])


    useEffect(() => {
        const updateDonationValues = () => {
            // Calculate totalAmountCheckout based on location state or formValue.amount
            const totalAmountCheckout = location?.state?.donateMonthly?.enter_amount || formValue.amount || 500;

            // Initialize an empty array to store the dropdown options
            const updatedData = [];

            // Loop from 16% down to 0% and add options to the updatedData array
            for (let percentage = 16; percentage >= 0; percentage -= 2) {
                const value = Math.round(totalAmountCheckout * (percentage / 100));
                updatedData.push({
                    value: value,
                    label: `${percentage}% (₹ ${value})`,
                });
               
            }
            setCompDonationValueData(updatedData);
        };

        updateDonationValues();
    }, [formValue.amount]);

    const getActiveGateway = async ()=>{
        try{
            const result = await getData("/donations/active_gateway")
            setFormValue((prev)=>({
                ...prev,
                gateWay: result.data.data.name
            }))
        }catch (error){
            console.log("errr",error)
        }
    } 

    const handleGetCampaignList = async () => {
        try {
            const result = await getData('/campaigns/campaigns_listing')
            if (result.status === 200) setAllCampaignsList(JSON.parse(result?.data?.data))
        } catch (error) {
            console.log("error", error)
        }

    }

    // update the form state on the onChange
    const fieldsUpdateState = (attrName, value) => {
        setFormValue((prev) => ({
            ...prev,
            [attrName[0]]: value,
        }));
    };



    const formSubmission = async () => {
        console.log("form", formValue)
        if (!validateEmail(formValue.email) || !validateMobileNumber(formValue.mobileNumber) || formValue.nationality === "" || formValue.fullName === "" ||
            formValue.prefferedDate === "") {
            return toast.error("Fill all details")
        } else {
            //payload  send to the backend
            try {
                let body = {
                    donar: {
                        full_name: formValue?.fullName,
                        email: formValue?.email,
                        phone_number: formValue?.phone,
                        nationality: formValue?.nationality,
                        anonymous: formValue?.anonymous,
                    },
                    donation: {
                        is_recurring: true,
                        preffered_date: formValue?.prefferedDate,
                        campaign_request_id: formValue.campaginId,
                        commission_amount: formValue.commsionAmount,
                        total_amount: formValue.amount,
                    },
                    gateway: formValue?.gateWay
                };

                const result = await postData("/donations", body);
                if (result.status == 200 && formValue.gateWay === "InstaMojo") {
                    handlePaymentInstaMojo(result?.data?.order_id)
                }else if(result.status == 200 && formValue.gateWay === "Razorpay"){
                         let paymentData = {
                           donar: {
                             full_name: formValue?.name,
                             email: formValue?.email,
                             phone_number: formValue?.phone,
                           },
                         };
                          handlePaymentRazorPay(
                            paymentData,
                            result?.data?.order_id
                          );
                }else if(result.status === 404){
                    toast.error(result.data.message)
                    setPaymentFailedStatus(true)
                  } 
            } catch (error) {
                console.log("error", error);
            }
        }
    }

    return (
        <>
            <Grid xs={12}>
     <PaymentFailure open={paymentFailedStatus} setOpen={setPaymentFailedStatus} />
            </Grid>
            <>
                <Grid xs={6}>
                    <SelectDropdown
                        class_name="inputField orngtxt mb-3"
                        title="Support Us"
                        value={formValue.commsionAmount}
                        attrName={["commsionAmount"]}
                        value_update={fieldsUpdateState}
                        dropdown_data={compDonationValue_data}
                        warn_status={false}
                        error_message={"select "}
                        info_status={true}
                        info_message={
                            "By supporting DigiDonation, you are aiding us in extending our reach to more campaigns like this and expanding our influence, and it is totally optional."
                        }
                        required={true}
                    />
                </Grid>
                <Grid xs={6}>
                    <SelectDropdown
                        class_name="inputField mb-3"
                        title="Select a Cause"
                        value={formValue.campaginId}
                        attrName={["campaginId"]}
                        value_update={fieldsUpdateState}
                        dropdown_data={getAllCampaignList}
                        warn_status={false}
                        error_message={"select "}
                        required={true}
                    />
                </Grid>
                <Grid xs={6}>
                    <InputTextField
                        class_name="inputField orngtxt mb-3"
                        title={"Amount"}
                        value={formatIndianRupee(formValue.amount)}
                        attrName={["amount"]}
                        value_update={fieldsUpdateState}
                        warn_status={false}
                        error_messg={""}
                        required={true}
                        type="number"
                    />
                </Grid>
                <Grid xs={6}>
                    <SelectDropdown
                        class_name="inputField mb-3"
                        title="Prefferd Date"
                        value={formValue.prefferedDate}
                        attrName={["prefferedDate"]}
                        value_update={fieldsUpdateState}
                        dropdown_data={date_data()}
                        warn_status={false}
                        error_message={"select "}
                        required={true}
                    />
                </Grid>
            </>
            <Grid xs={12}>
                <p className="fw-bold">Personal Details</p>
            </Grid>
            <Grid xs={6}>
                <InputTextField
                    class_name="inputField orngtxt mb-3"
                    title={"Full Name"}
                    value={formValue?.fullName}
                    attrName={["fullName"]}
                    value_update={fieldsUpdateState}
                    warn_status={false}
                    error_messg={""}
                    required={true}
                />
            </Grid>
            <Grid xs={6}>
                <InputTextField
                    type={"number"}
                    class_name="inputField orngtxt mb-3"
                    title={"Mobile"}
                    value={formValue.mobileNumber}
                    attrName={["mobileNumber"]}
                    value_update={fieldsUpdateState}
                    warn_status={false}
                    error_messg={""}
                    required={true}
                    maxLimit={10}
                />
            </Grid>
            <Grid xs={6}>
                <InputTextField
                    class_name="inputField orngtxt mb-3"
                    title={"Email"}
                    value={formValue.email}
                    attrName={["email"]}
                    value_update={fieldsUpdateState}
                    warn_status={false}
                    error_messg={""}
                    required={true}
                />
            </Grid>
            <Grid xs={6}>
                <SelectDropdown
                    class_name="inputField orngtxt mb-7"
                    title="Nationality"
                    value={formValue.nationality}
                    attrName={["nationality"]}
                    value_update={fieldsUpdateState}
                    dropdown_data={nationality_data}
                    warn_status={false}
                    error_message={"select "}
                    required={true}
                />
            </Grid>
            <Grid xs={12}>
                <FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formValue.anonymous}
                                onChange={(e) => {
                                    setFormValue((prev) => ({
                                        ...prev,
                                        anonymous: e.target.checked,
                                    }));
                                }}
                            />
                        }
                        value="anonymous"
                        label="Make my donation anonymous"
                    />
                </FormControl>
            </Grid>
            <Grid xs={12}>
                <Button
                    variant="contained"
                    className="donate_sec_btn mt-3"
                    onClick={formSubmission}
                >
                    Continue To Pay
                </Button>
            </Grid>

        </>
    )
}
export default DonateMonthly