import React, { useEffect } from "react";

const TestWrap = ({ component }) => {
    // alert("TEST ROUTE")
 

    return (
        <>
            {component}
        </>
    )
}
export default TestWrap;