import * as Yup from 'yup'; // use yup library for the form validation globally used for the form valadition

//@@ validation for the monthly donate page 
//@@ with three paramter all are required
export const monthlyDonateSchema = Yup.object().shape({
  india_stray: Yup.string().required('Required'),
  text1: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  ruppees: Yup.string().required('Required'),
});

//  validate mobile number
export function validateMobileNumber(mobile_no) {
  if (mobile_no != undefined && mobile_no != null) {
    var mobile = mobile_no.toString().trim();
    if (
      mobile.toString().length == 10 &&
      parseInt(mobile.substring(0, 1)) >= 6
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}


  //validate  email 
  export function validateEmail(email) {
    var re = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z-])+\.)+([a-zA-Z]{2,4})+$/;
    return re.test(email);
  }

  // validate url
export function validateUrl(value) {
  var re =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  return re.test(value);
}