import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button } from "@mui/material";
import "./header.scss";
import "./../../index.scss";
import { NavLink } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

//drawer elements used
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import digiDonationLogo from "./../../images/digidonation-logo.svg";

const Navbar = (style) => {
  const [transparentBg, setTransparentBg] = useState(true);
  const [open, setState] = useState(false);

  //function that is being called every time the drawer should open or close, the keys tab and shift are excluded so the user can focus between the elements with the keys
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    //changes the function state according to the value of open
    setState(open);
  };

  return (
    <Box
      className={`Navbar ${transparentBg ? " " : "navbar-white"}`}
      style={style}
    >
      <Grid container spacing={3} className="row" alignItems={"center"}>
        <Grid xs={6} md>
          <NavLink to="/" display={"inline-block"}>
            <img src={digiDonationLogo} alt="" className="logo" />
          </NavLink>
        </Grid>
        <Grid xs={6} md={8}>
          <div className="nav-menu">
            <ul>
              <li>
                <NavLink to="/explore-campaigns" className={`nav-link`}>
                  Explore Campaigns
                </NavLink>
              </li>
              <li>
                <NavLink to="/donate-monthly" className={`nav-link`}>
                  Donate Monthly
                </NavLink>
              </li>
              <li>
                <NavLink to="/request-campaign" className={`nav-link `}>
                  Request a Campaign
                </NavLink>
              </li>
              <li>
                <NavLink to="/blogs" className={`nav-link `}>
                  Blogs
                </NavLink>
              </li>
              <li>
                <NavLink to="/faq" className={`nav-link `}>
                  Faq
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="hamburger_menu">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              className="btn"
            >
              <MenuIcon />
            </IconButton>

            {/* The outside of the drawer */}
            <Drawer
              //from which side the drawer slides in
              anchor="right"
              //if open is true --> drawer is shown
              open={open}
              //function that is called when the drawer should close
              onClose={toggleDrawer(false)}
              //function that is called when the drawer should open
              onOpen={toggleDrawer(true)}
              classes={{ root: "panel", paper: "drawer-inner" }}
            >
              {/* The inside of the drawer */}
              <Box
                sx={{
                  p: 2,
                  height: 1,
                  //    backgroundColor: "#dbc8ff",
                }}
                className="panel-inner"
              >
                {/* when clicking the icon it calls the function toggleDrawer and closes the drawer by setting the variable open to false */}
                <IconButton sx={{ mb: 2 }}>
                  <CloseIcon onClick={toggleDrawer(false)} />
                </IconButton>

                <Divider sx={{ mb: 2 }} />

                <Box sx={{ mb: 2 }}>
                  <ul>
                    <li>
                      <NavLink
                        onClick={toggleDrawer(false)}
                        to="/explore-campaigns"
                        className={`nav-NavLink `}
                      >
                        Explore Campaigns
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleDrawer(false)}
                        to="/donate-monthly"
                        className={`nav-NavLink`}
                      >
                        Donate Monthly
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleDrawer(false)}
                        to="/request-campaign"
                        className={`nav-NavLink `}
                      >
                        Request a Campaign
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleDrawer(false)}
                        to="/blogs"
                        className={`nav-NavLink `}
                      >
                        Blogs
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleDrawer(false)}
                        to="/faq"
                        className={`nav-NavLink `}
                      >
                        Faq
                      </NavLink>
                    </li>
                  </ul>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    bottom: "0",
                    left: "50%",
                    transform: "translate(-50%, 0)",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ m: 1, width: 0.5 }}
                    className="yellowBtn"
                  >
                    Register
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ m: 1, width: 0.5 }}
                    className="yellowBtn"
                  >
                    Login
                  </Button>
                </Box>
              </Box>
            </Drawer>
          </div>
        </Grid>
        <Grid md className="textRight mobile_d_none">
          {/* <Button className="yellowBtn" onClick={() =>navigate('/sign-up')}>Sign Up</Button> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Navbar;
