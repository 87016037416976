import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, Tooltip } from "@mui/material";
import "./../../index.scss";
import "../exploreCampaginDetail/exploreCampaginDetail.scss";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import PersonIcon from "@mui/icons-material/Person";
import InfoIcon from "@mui/icons-material/Info";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkIcon from "@mui/icons-material/Link";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import DevicesIcon from "@mui/icons-material/Devices";
import { useNavigate, useParams } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import InputTextField from "../../components/FieldTypes/TextField/TextField";
import { getData } from "../../api/apiController";
import { toast } from "react-hot-toast";
import gpayImg from "./../../images/gpay-logo.png";
import phonpeImg from "./../../images/phonepe-logo.png";
import paytmImg from "./../../images/paytm-logo.png";
import upiImg from "./../../images/upi-logo.png";
import rupeeSymbolImg from "./../../images/Indian-Rupee-symbol.svg";
import userImg from "./../../images/user.png";
import { formatIndianRupee, getUpdates } from "../../helper/helpingFuntions";
import ReferChangeMaker from "../../components/referChangemaker/referChangemaker";
import CampaignUpdates from "../../components/updates/campaignUpdates";
import DonarList from "../../components/donarList/donarList";

const DonateMonthlyCampaignDetailPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const missionTab = useRef(null);
  const ngoTab = useRef(null);
  const updateTab = useRef(null);
  const urlSearchParams = new URLSearchParams(window.location.search)
  const payment_id = urlSearchParams.get('payment_id');
  const payment_request_id = urlSearchParams.get('payment_request_id');
  const payment_status = urlSearchParams.get('payment_status');
  const scrollToSection = (elementRef) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [copySuccess, setCopySuccess] = useState(false);
  const [inputValue, setInputValue] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [activepaymentTypeLink, setActivePaymentTypeLink] = useState("upi");
  const [monthlyCampaignData, setMonthlyCampaignData] = useState({});
  const [loader, setloader] = useState(true);

  // Get Detail by Id
  useEffect(() => {
    getMonthlyDetail();
    if (payment_id && payment_request_id && payment_status) {
      paymentSuccess();
    }
  }, []);

  const paymentSuccess = async () => {
    try {
      const result = await getData(`donations/verify_instamojo_payment?payment_id=${payment_id}&payment_request_id=${payment_request_id}&payment_status=${payment_status}`)
      console.log("===", result)
      if (result.status == 200) {

        urlSearchParams.delete("payment_id")
        urlSearchParams.delete("payment_request_id")
        urlSearchParams.delete("payment_status")
        // Create a new URL without the query parameters
        const newURL = window.location.pathname

        // Update the URL without the query parameters
        window.history.replaceState({}, document.title, newURL);
      }
    } catch (error) {

    }
  }

  const getMonthlyDetail = async () => {
    try {
      const result = await getData(`campaigns/${id}`);
      if (result.status === 200) {
        setMonthlyCampaignData(result.data.data);
        setloader(false);

      }
    } catch (error) {
      console.log("error", error);
    }
  };
  // handle Payment Donation
  const handleMonthlyDonation = () => {
    if (inputValue < 10) {
      toast.error("Minimum Amount should be 10");
    } else {
      const donateData = {
        dropDown: id,
        enter_amount: inputValue,
      };
      navigate("/checkout", {
        state: {
          donateMonthly: donateData,
          isRecurring: true,
          from: "page"
        },
      });
    }
  };

  const handleFacebookShare = () => {
    // Create a new URL object
    const url = new URL(window.location.href);
    // Get the endpoint or path
    const endpoint = url.pathname; // This will give you "/explore-campaigns/24"

    const urlToShare = `https://www.digidonation.com${endpoint}`;

    // Check if the Facebook SDK is already loaded
    if (window.FB) {
      window.FB.ui({
        method: "share",
        href: urlToShare,
      });
    } else {
      // Load the Facebook SDK asynchronously
      loadFacebookSDK(() => {
        window.FB.ui({
          method: "share",
          href: urlToShare,
        });
      });
    }
  };
  const handleTwitterShare = () => {
    const tweetText = "Check out this donation campaign on our website!"; // Customize your tweet text
    // Create a new URL object
    const url = new URL(window.location.href);

    // Get the endpoint or path
    const endpoint = url.pathname; // This will give you "/explore-campaigns/24"

    const urlToShare = `https://www.digidonation.com${endpoint}`; // Replace with your actual campaign URL

    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText
    )}&url=${encodeURIComponent(urlToShare)}`;

    window.open(twitterShareUrl, "_blank");
  };

  const handleWhatsAppShare = () => {
    const textToShare = "Check out this donation campaign on our website: "; // Customize your share message
    // Create a new URL object
    const url = new URL(window.location.href);

    // Get the endpoint or path
    const endpoint = url.pathname; // This will give you "/explore-campaigns/24"

    const urlToShare = `https://www.digidonation.com${endpoint}`; // Replace with your actual campaign URL

    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      textToShare + urlToShare
    )}`;

    window.open(whatsappShareUrl, "_blank");
  };
  const handleCopyLink = () => {
    // Create a new URL object
    const url = new URL(window.location.href);

    // Get the endpoint or path
    const endpoint = url.pathname; // This will give you "/explore-campaigns/24"

    const campaignUrl = `https://www.digidonation.com${endpoint}`;
    navigator.clipboard.writeText(campaignUrl).then(() => {
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 3000); // Reset the copy success message after 3 seconds
    });
  };
  const loadFacebookSDK = (callback) => {
    // Load the Facebook SDK asynchronously
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "your-facebook-app-id", // Replace with your actual Facebook App ID
        autoLogAppEvents: true,
        xfbml: true,
        version: "v11.0", // Replace with the desired version
      });
      if (callback) {
        callback();
      }
    };

    // Load the Facebook SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };
  const handleInputChange = (e) => {
    let inputText = e.target.value;

    // Remove any non-numeric characters using a regular expression
    inputText = inputText.replace(/[^0-9]/g, '');

    setInputValue(inputText); // Update the state with the sanitized input
  };


  return (
    <>
      <Box className="homePageWrapper">
        <div className="campaignDetails-header">
          <Grid container rowSpacing={0} spacing={3} className="row">
            {loader ?
              <Grid xs={12} md={6} textAlign={"center"} paddingBottom={0}>
                <div className="loader">
                  <div class="loading"></div>
                </div></Grid> :
              <Grid xs={12} md={6} textAlign={"center"} paddingBottom={0}>
                {/* <img src="./images/campaign_img1.svg" alt="" /> */}
                <Carousel
                  className="carousel"
                  animation="slide"
                  duration={800}
                  NextIcon={<ArrowForward />}
                  PrevIcon={<ArrowBack />}
                // autoPlay={false}
                >
                  {monthlyCampaignData?.images?.map((item, i) => (
                    <img src={item} />
                  ))}
                </Carousel>
              </Grid>}
            <Grid xs={12} md={6} textAlign={"center"} paddingBottom={0}>
              <div className="right-sec">
                <h3>
                  <span>{monthlyCampaignData?.title?.split(" ")[0]}</span>
                  {monthlyCampaignData?.title?.slice(
                    monthlyCampaignData?.title?.split(" ")[0]?.length
                  )}
                </h3>

                <p className="sub_desc mb-4">
                  {monthlyCampaignData?.short_description}
                </p>

                <div className="grey_div">
                  <p>
                    <WatchLaterOutlinedIcon />
                    <span className="mx-2">
                      {monthlyCampaignData?.days_left}
                    </span>{" "}
                    Days Left
                  </p>
                  <p>
                    <PersonIcon />
                    <span className="mx-2">
                      {monthlyCampaignData?.bakers_count}
                    </span>{" "}
                    Backers
                  </p>
                </div>
                <div className="badge-sec">
                  <Tooltip title="Tax -benefits available" arrow>
                    <span className="badge tb">
                      Tax Benefits
                      <InfoIcon />
                    </span>
                  </Tooltip>
                  <Tooltip title="This campaign is verified" arrow>
                    <span className="badge verified">
                      Verified
                      <InfoIcon />
                    </span>
                  </Tooltip>
                  <span className="badge medical">
                    {monthlyCampaignData?.campaign_category?.name}
                  </span>
                </div>

                {/* <Button variant="contained" className="donate_sec_btn">
                Donate Monthly
              </Button> */}
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="main_section">
          <Grid container spacing={3} className="row">
            <Grid xs={12} md={7}>
              <div className="section-tags pb-4 pt-4">
                <ul>
                  <li>
                    <Link
                      className="nav-link"
                      onClick={() => {
                        scrollToSection(missionTab);
                      }}
                    >
                      Mission
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link"
                      onClick={() => {
                        scrollToSection(ngoTab);
                      }}
                    >
                      Associated NGOs
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link"
                      onClick={() => {
                        scrollToSection(updateTab);
                      }}
                    >
                      Updates
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="project-sec ">
                <h4 ref={missionTab}>Mission</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: monthlyCampaignData.web_content,
                  }}
                ></div>
                <p className="camp_desc_p">{monthlyCampaignData?.description}</p>


                <h4 className="mt-3" ref={ngoTab}>
                  Associated NGOs
                </h4>
                <div className="updates_imgs">
                  <img src={monthlyCampaignData?.banner_image} alt="" />
                </div>

                {/* <div>
                <h4 className="mt-3" ref={updateTab}>
                  Updates
                </h4>
                <p className="camp_desc_p">
                  <span>"13/07/2023"</span>{" "}
                </p>
                <div className="updates_imgs">
                  <ul>
                    {updates && updates?.images?.length > 0 && updates?.images?.map((item, index) => (
                      <li key={index}>
                        <img src={item} alt="updates" />
                      </li>
                    ))}
                  </ul>
                </div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: updates.description,
                  }}
                ></div>
              </div> */}

                <div ref={updateTab}>
                  <CampaignUpdates id={id} />
                </div>
              </div>
            </Grid>
            <Grid xs={12} md={5}>
              <div className="payment-sec">
                <h4>Spread the word</h4>
                <div className="social-links">
                  <Link>
                    <FacebookRoundedIcon
                      className="fb"
                      onClick={() => handleFacebookShare()}
                    />
                  </Link>
                  <Link>
                    <TwitterIcon
                      className="tw"
                      onClick={() => handleTwitterShare()}
                    />
                  </Link>
                  <Link>
                    <WhatsAppIcon
                      className="wp"
                      onClick={() => handleWhatsAppShare()}
                    />
                  </Link>
                  <Tooltip title="Copy-link">
                    <Link>
                      <LinkIcon
                        className="link"
                        onClick={() => handleCopyLink()}
                      />
                    </Link>
                  </Tooltip>
                </div>

                <div className="payment-box">
                  <h5>Payment</h5>
                  <div className="input-box">
                    <span>
                      <img src={rupeeSymbolImg} alt="" />
                    </span>
                    <input
                      className="inputfield"
                      type="text"
                      placeholder="0"
                      value={formatIndianRupee(inputValue)} // Display the formatted value
                      onChange={handleInputChange}
                    />
                  </div>
                  <h6>Donate Via:</h6>
                  <div className="tabsSection">
                    {/* Render buttons */}
                    <div>
                      <Link
                        className={`nav-link ${activepaymentTypeLink === "upi" ? "active" : ""}`}
                        onClick={() => {
                          setActiveTab(0);
                          setActivePaymentTypeLink("upi");
                        }}
                      >
                        UPI
                      </Link>
                      <Link
                        className={`nav-link ${activepaymentTypeLink === "cards" ? "active" : ""
                          }`}
                        onClick={() => {
                          setActiveTab(1);
                          setActivePaymentTypeLink("cards");
                        }}
                      >
                        Cards
                      </Link>
                    </div>

                    {/* Render active tab content */}
                    {activeTab === 0 ? (
                      <Grid container spacing={3} className="row">
                        <Grid xs={12} paddingLeft={0}>
                          <ul>
                            <li className="p_box_outer">
                              <Link>
                                <div className="p_box mb-1">
                                  <img src={phonpeImg} alt="" />
                                </div>
                                <p>PhonePe</p>
                              </Link>
                            </li>
                            <li className="p_box_outer">
                              <Link>
                                <div className="p_box mb-1">
                                  <img src={gpayImg} alt="" />
                                </div>
                                <p>Gpay</p>
                              </Link>
                            </li>
                            <li className="p_box_outer">
                              <Link>
                                <div className="p_box mb-1">
                                  <img src={paytmImg} alt="" />
                                </div>
                                <p>Paytm</p>
                              </Link>
                            </li>
                            <li className="p_box_outer">
                              <Link>
                                <div className="p_box mb-1">
                                  <img src={upiImg} alt="" />
                                </div>
                                <p>Bhim UPI</p>
                              </Link>
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={3} className="row">
                        <Grid xs={12} paddingLeft={0}>
                          <ul>
                            <li className="p_box_outer">
                              <Link>
                                <div className="p_box mb-1">
                                  <CreditCardIcon />
                                </div>
                                <p>Cr/Db Card</p>
                              </Link>
                            </li>
                            <li className="p_box_outer">
                              <Link>
                                <div className="p_box mb-1">
                                  <CardGiftcardIcon />
                                </div>
                                <p>Gift Card</p>
                              </Link>
                            </li>
                            <li className="p_box_outer">
                              <Link>
                                <div className="p_box mb-1">
                                  <DevicesIcon />
                                </div>
                                <p>Netbanking</p>
                              </Link>
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                  <Button
                    variant="contained"
                    className="donate_sec_btn mb-4"
                    onClick={handleMonthlyDonation}
                  >
                    Donate
                  </Button>
                </div>
                <DonarList id={id} />


                <ReferChangeMaker />

              </div>
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
};

export default DonateMonthlyCampaignDetailPage;
