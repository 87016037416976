import React, { useEffect, useState } from "react";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { getData } from "../../api/apiController";
import MedicalCampaign from "../medicalCampaign/medicalCampaign";
import { useNavigate } from "react-router-dom";
import DonateMonthlyProgram from "../donateMonthlyProgram/donateMonthlyProgram";

const CommonCard = (props) => {
  const {
    endpoint,
    searchId,
    donateMonthlyProgram,
    hideSeemore,
    page,
    setPage,
    inputValue,
    campaignLimit,
  } = props;
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [medicalCampaigns, setMedicalCampaigns] = useState([]);
  const [donateMonthlyCampaigns, setDonateMonthlyCampaigns] = useState([]);
  const [searchUpdate, setSearchUpdate] = useState();
  const [loader, setloader] = useState(true);

  const [nextPage, setNextPage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // GET Data request
  useEffect(() => {
    // debugger;
    const fetchAllCampaigns = async () => {
      let conatArr = [...allCampaigns];
      try {
        const result = await getData(`${endpoint}&page=${page}`);
        if (result.status === 200) {
          setloader(false);
          if (donateMonthlyProgram) {
            setDonateMonthlyCampaigns(result?.data?.data);
          } else {
            if (page > 1) {
              // setAllCampaigns((prev)=>[...prev,...result?.data?.data])
              setAllCampaigns(conatArr.concat(result?.data?.data));
            } else {
              setAllCampaigns(result?.data?.data);
            }
          }
          setNextPage(result?.data?.next_page);
        }
      } catch (error) {
        setAllCampaigns([]);
      }
    };
    fetchAllCampaigns();
  }, [searchId, page, inputValue]);



  //  Handle Route
  const handleCampaingDetail = (id) => {
    if (donateMonthlyProgram) {
      navigate(`/dm-campaign-detail/${id}`);
    } else {
      navigate(`/explore-campaigns/${id}`);
    }
  };

  return (
    <>
      {loader && (
        <div className="loader">
          <div class="loading"></div>
        </div>
      )}
      {!loader && (
        <>
          {!donateMonthlyProgram ? (
            <Grid
              container
              spacing={3}
              className="row"
              justifyContent={"center"}
            >
              {allCampaigns.length === 0 ? (
                <div>No records to display</div>
              ) : (
                allCampaigns
                  .slice(0, campaignLimit || allCampaigns.length)
                  .map((data, index) => (
                    <Grid
                      xs={12}
                      sm={6}
                      lg={4}
                      onClick={() => handleCampaingDetail(data?.id)}
                      key={data?.id}
                    >
                      <MedicalCampaign
                        imgSource={data.banner_image}
                        campaignHeading={data.title.split(" ")[0]}
                        campaignHeadingSpan={data.title.slice(
                          data.title.split(" ")[0]?.length
                        )}
                        campDesc={data.short_description}
                        postedbyavatar={data.postedbyavatar}
                        postedBy={data.organization_name}
                        percentage={data.percentage}
                        goalAmt={data.fund_required}
                        raisedAmt={data.total_collection}
                        days={data.days_left}
                        backersCount={data.bakers_count}
                      />
                    </Grid>
                  ))
              )}

              {!hideSeemore && nextPage && (
                <Button
                  className="yellowBtn mt-4"
                  onClick={() => setPage((prev) => prev + 1)}
                >
                  See More
                </Button>
              )}
            </Grid>
          ) : (
            <Grid
              container
              spacing={3}
              className="row"
              justifyContent={"center"}
            >
              {donateMonthlyCampaigns.length === 0 ? (
                <div>No records To display</div>
              ) : (
                donateMonthlyCampaigns.map((data, index) => (
                  <Grid
                    xs={12}
                    sm={6}
                    lg={4}
                    onClick={() => handleCampaingDetail(data?.id)}
                    key={data?.id}
                  >
                    <DonateMonthlyProgram
                      imgSource={data.images[0]}
                      campaignHeading={data.title.split(" ")[0]}
                      campaignHeadingSpan={data.title.slice(
                        data.title.split(" ")[0]?.length
                      )}
                      campDesc={data.short_description}
                    />
                  </Grid>
                ))
              )}
              {!hideSeemore && nextPage && (
                <Button
                  className="yellowBtn mt-4"
                  onClick={() => setPage((prev) => prev + 1)}
                >
                  See More
                </Button>
              )}
            </Grid>
          )}
        </>
      )}
    </>
  );
};
export default CommonCard;
