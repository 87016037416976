import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import { getData } from "../../api/apiController";
import SelectDropdown from "../../components/FieldTypes/SelectDropdown/SelectDropdown";
import InputTextField from "../../components/FieldTypes/TextField/TextField";
import CommonCard from "../../components/commanCards/commonApiCard";
import { whyChooseArray } from "../../dataJson/dataJson";
import "./../../index.scss";
import "./../homepage/homepage.scss";
import { formatIndianRupee } from "../../helper/helpingFuntions";

const DonateMonthlyPage = () => {
  const [formValue, setFormValue] = useState({
    dropDown: "",
    enter_amount: "",
  });
  const [getAllCampaignList, setAllCampaignsList] = useState([])
  console.log(getAllCampaignList)
  const navigate = useNavigate()
  const [page,setPage] = useState(1)
  //update formvalues
  const fieldsUpdateState = (attrName, value) => {
    setFormValue((prev) => ({
      ...prev,
      [attrName[0]]: value

    }))
  };

  useEffect(() => {
    handleGetCampaignList()
  }, [])

  const handleGetCampaignList = async () => {
    try {
      const result = await getData('/campaigns/campaigns_listing')
      if (result.status === 200) setAllCampaignsList(JSON.parse(result.data.data))
    } catch (error) {
      console.log("error", error)
    }
  }

  // Handle Navigate to checkout
  const handleNavigate = (e) => {
    e.preventDefault()
    if (formValue.dropDown === "" || formValue.selected_amount === "") {
      return toast.error("Enter All Value")
    } else {
      navigate('/checkout', {
        state: {
          donateMonthly: formValue,
          isRecurring: true,
          from: 'page'
        }
      })
    }
  }


  return (
    <Box className="homePageWrapper">
      <div className="dm-header">
        <Grid container spacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} md={6} textAlign={"center"} paddingBottom={0}>
            <img src="./images/dm-header-img.svg" alt="" />
          </Grid>
          <Grid xs={12} md={6} textAlign={"center"} paddingBottom={0}>
            <div className="right-sec">
              <h3>
                <span>One Click</span>
                <br />
                Lasting Impact
              </h3>
              <p className="sub_desc mb-10">
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical
              </p>
              <form onSubmit={handleNavigate}>
                <SelectDropdown
                  class_name="inputField mb-7"
                  title="Select Campaingn to Support..."
                  value={formValue.dropDown}
                  attrName={["dropDown"]}
                  value_update={fieldsUpdateState}
                  dropdown_data={getAllCampaignList}
                  warn_status={formValue.dropDown}
                  error_message={"select "}
                />
                <InputTextField
                  type="number"
                  class_name="inputField mb-7"
                  title={"Enter Amount"}
                  value={`₹ ${formatIndianRupee(formValue.enter_amount)}`}
                  attrName={["enter_amount"]}
                  value_update={fieldsUpdateState}
                  warn_status={formValue.enter_amount}
                  error_messg={""}
                  placeholder={"₹ 0"}
                />
                {/* <div className="shortbuttons">
                  <span className="radioBox ">
                    <input value={300} onChange={(e) => { setFormValue((prev) => ({ ...prev, enter_amount: e.target.value })) }} type="radio" id="1" name="mainFilters" />
                    <label htmlFor="1"><CurrencyRupeeIcon /> 300/Mon</label>
                  </span>
                  <span className="radioBox">
                    <input value={500} onChange={(e) => { setFormValue((prev) => ({ ...prev, enter_amount: e.target.value })) }} type="radio" id="2" name="mainFilters" />
                    <label htmlFor="2"><CurrencyRupeeIcon /> 500/Mon</label>
                  </span>
                  <span className="radioBox ">
                    <input value={1000} onChange={(e) => { setFormValue((prev) => ({ ...prev, enter_amount: e.target.value })) }} type="radio" id="3" name="mainFilters" />
                    <label htmlFor="3"><CurrencyRupeeIcon /> 1000/Mon</label>
                  </span>
                </div> */}
                <Button
                  type="submit"
                  variant="contained"
                  className="donate_sec_btn"
                >
                  Donate Monthly
                </Button>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="">
        <Grid container spacing={3} className="row">
          <Grid xs={12} textAlign={"center"}>
            <h2>
              Monthly Donation <span>Programs</span>
            </h2>
            <p className="sub_desc mb-10">
              Discover the power of our Monthly Donation Programs. By becoming a
              valued monthly donor, you provide sustained support that allows us
              to make a meaningful and lasting impact on our mission. Your
              ongoing contributions help us address critical needs, implement
              long-term projects, and create positive change month after month.
              
              <br />
             
            </p>
          </Grid>
        </Grid>

        <CommonCard
          endpoint={`campaigns?camapign_category_id=&is_reccuring=true`}
          donateMonthlyProgram
          page={page}
          setPage={setPage}
        />
      </div>

      <div className="how_can_dm">
        <Grid container spacing={3} className="row">
          <Grid xs={12} textAlign={"center"}>
            <h2>
              How can you <span>Donate Monthly</span>
            </h2>
            <p className="sub_desc mb-10">
              Explore the seamless process of setting up regular monthly
              donations and make a lasting impact on the causes you care about.
              Discover how you can conveniently contribute on a monthly basis to
              support initiatives that matter to you.
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="row">
          <Grid xs={6} md={3} textAlign={"center"}>
            <div className="dm_step_box arc">
              <img src="./images/dmbox_icon.svg" alt="" />
              <h4>Step 1</h4>
              <p>Select a campaign that interests you.</p>
            </div>
          </Grid>
          <Grid xs={6} md={3} textAlign={"center"}>
            <div className="dm_step_box arc2">
              <img src="./images/dmbox_icon.svg" alt="" />
              <h4>Step 2</h4>
              <p>Select the monthly donation amount of your preference.</p>
            </div>
          </Grid>
          <Grid xs={6} md={3} textAlign={"center"}>
            <div className="dm_step_box arc">
              <img src="./images/dmbox_icon.svg" alt="" />
              <h4>Step 3</h4>
              <p>Your monthly donations will be automatically deducted.</p>
            </div>
          </Grid>
          <Grid xs={6} md={3} textAlign={"center"}>
            <div className="dm_step_box">
              <img src="./images/dmbox_icon.svg" alt="" />
              <h4>Step 4</h4>
              <p>You'll receive regular updates regarding your donations.</p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="why_choose">
        <Grid container spacing={3} className="row">
          <Grid xs={12} textAlign={"center"}>
            <h2>
              Why to choose <span>Digidonation</span>
            </h2>
            <p className="sub_desc mb-10">
              Choosing Digidonation empowers you to make a significant and
              lasting impact every month. Our platform offers a transparent,
              convenient, and automated way to contribute to causes that matter
              to you.
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="row">
          {whyChooseArray.map((data) => (
            <Grid xs={6} md={4} textAlign={"center"}>
              <div className="why_choose_box">
                <img src={data.img_url} alt="" />
                <h4>{data.box_heading}</h4>
                <p>{data.box_desc}</p>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </Box>
  );
};

export default DonateMonthlyPage;
