import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/material";
import '../../pages/homepage/homepage.scss'
import "./../../index.scss";
import Navbar from "../../components/header/header";

const PrivacyPolicy = () => {
  return (
    <Box className="homePageWrapper">
      <Navbar style={{ color: "white" }} />

      <div className="contentwrapper">
        <Grid container spacing={3} className="row mt-5">
          <Grid xs={12} textAlign={"center"}>
            <h2>
              Privacy <span>Policy</span>
            </h2>
          </Grid>
        </Grid>

        <Grid container spacing={3} className="row">
          <Grid xs={12}>
            <p>
              At Nav Bharat Sewa Sanstha Services Private Limited (Nav Bharat
              Sewa Sanstha/Us/We/Our), we respect and uphold the fundamental
              right to privacy. As part of delivering our Services, we collect
              and process user data. We understand the importance of how
              information about You (You or Your) is used and shared, and we
              deeply value the trust You place in us to handle it responsibly.
              This document outlines the privacy policy for
              www.DigiDonations.com (DigiDonation). Our privacy policy (Privacy
              Policy) governs the use of DigiDonation and all associated
              Services, regardless of the method You use to access or interact
              with them, including through mobile devices. Please read this
              Privacy Policy in conjunction with our terms of use (accessible at
              Click Here) (Terms of Use). Any term not defined herein shall have
              the same meaning as in the Terms of Use.
            </p>
            <p>
              This Privacy Policy constitutes a legally binding agreement
              between You and Us. By accessing or using the Platform in any
              manner, You agree to be bound by the terms and conditions of this
              Privacy Policy. We want to bring to Your attention that Your data
              may be collected by Our affiliates outside of the Portal through
              in-person meetings, participation in conferences, and other
              forums, including forums and responses to promotional materials.
              As we aim to expand and improve Our offerings, data technologies
              may be integrated into Our Platform, using additional sources to
              collect data. Please be assured that We make every effort to
              secure the data collected to the best of Our ability.
            </p>
            <p>
              Before accessing Our Platform, We urge You to carefully read this
              Privacy Policy and the Terms of Use to fully understand how we
              operate. If for any reason You do not agree with or fully
              comprehend the Terms of Use and/or Our Privacy Policy, please
              refrain from using the Platform. By accessing the Platform, You
              provide Your unconditional consent and agreement to DigiDonation,
              as outlined under Section 43A and Section 72A of the Information
              Technology Act, 2000.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>Personal Information:-</h5>
            <p>
              As You access and/or use Our Platform, We may request certain
              information from You, which may include generic or personally
              identifiable data that can be used to contact or identify You
              (Personal Information). We strive to indicate which fields are
              required and which ones are optional wherever possible. Your
              Personal Information is used to further Our legitimate interests
              in delivering Our services. By using/accessing this Platform, You
              provide Your consent to the terms of information disclosure,
              including Personal Information, as per the provisions of the
              Privacy Policy.
            </p>
            <p>
              Nav Bharat Sewa Sanstha does not take responsibility for the
              genuineness or accuracy of information provided by any third party
              or other users of the Platform. While DigiDonation makes efforts
              to pre-screen and qualify Campaigns, such screening or
              qualification is not guaranteed to be error-free or exhaustive. It
              is not a substitute for Donors conducting independent
              investigations. Donors may have the opportunity to ask
              Beneficiaries questions about the Campaign and assess its
              viability. By making a donation, each Donor represents and
              warrants that they have conducted due diligence under the
              circumstances and do not solely rely on DigiDonation for the
              accuracy, completeness, or adequacy of information on the
              Platform. Donors should also note that DigiDonation does not
              warrant how Beneficiaries or any other person will utilize donated
              Materials. DigiDonation is not a party to any Campaign.
            </p>
            <p>
              Personal Information may encompass, but is not limited to, Your
              name, address, telephone number, email address, date of birth,
              gender, or other items enumerated in Rule 3 of Information
              Technology (Reasonable Practices and Procedures and Sensitive
              Personal Data of Information) Rules, 2011. We utilize Your
              information to facilitate Our Services, process payments,
              communicate with You, update Our records, and maintain Your
              transaction history with us. Additionally, we use this information
              to enhance the Platform, prevent or detect fraud or abuses, and
              enable third parties to perform technical, logistical, or other
              functions on Our behalf. To provide You with better insights into
              the type of information We collect, We have categorized the data
              We gather. Please note that this is an inclusive list:
            </p>
            <ul>
              <li>
                <span>1. Contact Information:</span> We collect and store any
                information You enter on the Portal or provide Us through other
                means. While some information is optional, Your general contact
                details such as name, photograph, address, telephone number,
                date of birth, gender, email address, financial information
                details, social media profile (if required), and contacts may be
                among the information We collect.
              </li>
              <li>
                <span>2. Personalized Information:</span> Depending on Your
                engagement with Us, particularly for Beneficiaries, We may
                request information related to the Campaign to be featured on
                the Platform. This might include details about Your medical
                records, a statement about the Campaign's purpose and
                objectives, and Your financial information. Additionally, this
                may involve photographs of You or other associated individuals
                and locations relevant to the Campaign. We may use this
                information to promote the Campaign and raise Materials for the
                Beneficiaries to achieve their objectives. This may include
                sharing such information on social media, third-party sites, and
                with service providers.
              </li>
              <li>
                <span>3. Transaction and Technical Information:</span> We
                receive and store various data whenever You interact with Us,
                whether through emails, visits to the Portal, or telephonic
                communication. This may include information about Your location,
                address, browser type, browser version, the pages You visit on
                Our Site, the time and date of Your visits, the time spent on
                those pages, and other statistics, including the internet
                protocol address of Your device and Your transaction history.
                This data may be used for internal analysis and to provide You
                with location-based or personalized services.
              </li>
              <li>
                <span>4. Third-Party Data:</span> We might receive information
                about You from other sources and add it to Our user database. By
                using or continuing to use the Platform, You agree to Our use of
                Your information (including sensitive personal information) in
                accordance with this Privacy Policy, as may be amended from time
                to time by DigiDonation in its sole discretion. You also agree
                and consent to us collecting, storing, processing, transferring,
                and sharing information (including sensitive personal
                information) related to You with third parties or service
                providers for the purposes as set out in this Privacy Policy.
              </li>
              <li>
                <span>5. Financial Data:</span> When You make a donation with
                Us, such transactions occur through the payment gateway channels
                on the Portal. We may retain Your preferred choice of payment,
                and with Your permission, store Your credit/debit card number
                (partially or the authorized number linked to Your payment
                account) and Your preferred unified payments interface for a
                more personalized experience. We also maintain complete details
                of the Trusts associated with us, including account numbers,
                SWIFT/IFSC Codes, and other financial information as required by
                applicable law.
              </li>
              <li>
                <span>6. Other Data:</span> Depending on Your interactions with
                Us, we may request other relevant information required to
                provide Our Services from time to time.
              </li>
            </ul>
            <p>
              In addition to the previously mentioned data, We also collect,
              use, and share aggregated data, such as statistical or demographic
              data, for internal purposes. This data helps us analyze and
              understand trends, improve Our services, and enhance the user
              experience. We may be required to share the aforementioned
              information with government authorities and agencies for purposes
              such as identity verification, prevention, detection,
              investigation of cyber incidents, prosecution, and punishment of
              offenses. By using the Platform, You agree and consent to
              DigiDonation disclosing Your information if required by applicable
              law.
            </p>
            <p>
              You acknowledge and agree that We use this information to provide
              the Services We offer. To do so, certain information may be
              displayed and shared on Our Platform for public viewing and to
              third parties, respectively. However, You have the option to
              request Us not to display Your information publicly by choosing to
              remain anonymous. You can exercise this option by contacting Us
              through the helpline number or email address provided or by
              contacting the grievance officer (details of whom are set forth in
              Paragraph 8 below). Please note that even if You choose to be
              anonymous and withhold the public display of Your information, We
              may still collect and use such information internally.
            </p>
            <p>
              It is important to note that We do not collect any special
              category of personal data, which includes sensitive information
              such as race, religion, ethnicity, religious or philosophical
              beliefs, sexual orientation, political opinions, trade union
              memberships, or information about criminal offenses or
              convictions.
            </p>
            <p>
              However, please be aware that if You choose not to provide Us with
              complete information or restrict the information to be displayed
              publicly, it might affect Your access to the Platform and Our
              Services. Some features or functionalities may not be available if
              certain information is not provided.
            </p>
            <p>
              As We value Your privacy, We take appropriate measures to protect
              Your data and handle it in accordance with applicable laws and
              regulations. If You have any concerns about the use or disclosure
              of Your information, You can contact Us through the provided
              contact details to address Your queries or raise any grievances.
              We encourage You to review the Platform to gain a better
              understanding of how such information is used.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5> Authorized Third Party Activities</h5>
            <p>
              DigiDonation serves as a platform that facilitates connections
              between Beneficiaries seeking support for their Campaigns and
              Donors who wish to contribute Materials to fulfill the
              Beneficiaries' requirements. It's important to note that Nav
              Bharat Sewa Sanstha provides links to external websites (Linked
              Websites) as a convenience, but it does not endorse or take
              responsibility for the content, products, or services offered on
              those Linked Websites. Your use of Linked Websites is entirely at
              your own risk, and any transactions or interactions with Linked
              Websites are subject to their respective terms and conditions.
            </p>
            <p>
              DigiDonation may display third-party advertisements, but this does
              not imply any endorsement or recommendation of the advertiser, its
              products, or services. Any interactions between you and these
              third parties are not the responsibility of Nav Bharat Sewa
              Sanstha, and the organization is released from any liability
              arising from such interactions.
            </p>
            <p>
              For the legitimate pursuit of its business interests, DigiDonation
              acquires, maintains, and processes Personal Information. These
              legitimate interests include establishing and maintaining
              relationships, servicing user needs, and for administrative
              purposes. Various contact management, analytics, and processing
              software and tools, including marketing through electronic means,
              may be used for these purposes.
            </p>
            <p>
              Personal data, including stored cookies, may be shared with
              service providers that offer web analytics, advertising, email
              distribution, and other relevant services to facilitate
              DigiDonation's operations. However, the use of such personal data
              is limited to the purpose of providing contracted services. In
              certain cases, Personal Information may be shared with the
              applicable Trust to fulfill its obligations under the law.
            </p>
            <p>
              DigiDonation may also share Personal Information with its group
              companies for internal analytics and business functions. In such
              cases, appropriate safeguards are put in place in accordance with
              applicable law and in line with the privacy policy.
            </p>
            <p>
              Personal Information may be processed for entering into or
              performing contracts, and in some instances, based on the data
              subject's consent for specific purposes.
            </p>
            <p>
              There may be unaffiliated third parties providing content,
              functionality, and services on DigiDonation's web and social media
              sites. These third parties collect and process personal data
              through sophisticated technologies like cookies and web beacons.
              However, Nav Bharat Sewa Sanstha does not control the privacy and
              business practices of these third parties, and users may manage
              certain activities through Internet browser and app controls.
            </p>
            <p>
              It is crucial to review the privacy practices and policies of
              third parties, especially when interacting with them on
              DigiDonation's platform. Nav Bharat Sewa Sanstha emphasizes that
              users have control over some of these activities through relevant
              internet browser and app settings.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>Links to Third-Party Website</h5>
            <p>
              DigiDonation may provide links to third-party advertisements,
              third-party websites, or other electronic communication services
              that are operated by third parties and are not under the control
              or affiliation of DigiDonation. It's important to note that any
              transmission or communication you receive from these third-party
              websites is beyond the responsibility of DigiDonation.
            </p>
            <p>
              DigiDonation does not make any representations regarding the
              privacy practices, policies, or terms of use of these third-party
              websites, and it does not control or guarantee the accuracy,
              integrity, or quality of the information and materials available
              on such websites. The inclusion or exclusion of these links does
              not imply any endorsement by DigiDonation of the third-party
              websites or their providers.
            </p>
            <p>
              Any information you provide to these third-party websites will be
              governed by their respective privacy policies, and it is advisable
              to review their privacy policies before using their websites.
            </p>
            <p>
              In summary, DigiDonation is not responsible for the activities or
              content of third-party websites, and your interactions with such
              websites are solely at your own risk.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>Cookies</h5>
            <p>
              When you use the DigiDonation Platform, cookies are used to
              enhance your experience and provide a more personalized service.
              Cookies are small pieces of information that are sent to your
              computer or mobile device when you visit a website or use a mobile
              application. They store and, in some cases, track information
              about your usage of the Platform. These files contain a small
              amount of data, often including an anonymous unique identifier.
            </p>
            <p>
              Cookies are sent to your browser from the DigiDonation web server
              and stored on your computer's hard drive. They are utilized to
              recognize your browser, allowing for a more personalized
              experience. This personalization includes displaying personalized
              advertisements on other websites and retaining items in your Cart
              between visits, making your interaction with DigiDonation more
              convenient.
            </p>
            <p>
              It's important to note that while some cookies are set by
              DigiDonation, others may be set by third parties providing
              services on behalf of DigiDonation.
            </p>
            <p>
              Cookies serve various purposes, such as collecting information and
              allowing you to enter your password less frequently during a
              session. You have the option to configure your browser settings to
              refuse all cookies or to receive notifications when a cookie is
              being sent. However, if you choose not to accept cookies, you may
              not be able to use certain portions of the DigiDonation Platform
              effectively. If you use a shared computer and leave cookies turned
              on, be sure to sign off when you finish using the Platform to
              ensure your privacy and security.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>Data Retention</h5>
            <p>
              Regarding data retention, Nav Bharat Sewa Sanstha (DigiDonation)
              will retain your personal data only for as long as it is necessary
              to fulfill the purposes for which the data was collected. This
              includes purposes related to legal, accounting, or reporting
              requirements.
            </p>
            <p>
              The determination of the appropriate retention period for personal
              data takes into consideration several factors, such as the amount,
              nature, and sensitivity of the personal data. Additionally,
              potential risks of unauthorized use or disclosure of the data, the
              specific purposes for processing the personal data, and whether
              those purposes can be achieved through alternative means are also
              considered. Furthermore, applicable legal requirements are taken
              into account.
            </p>
            <p>
              In some cases, DigiDonation may anonymize your personal data,
              making it no longer identifiable or associated with you. When this
              anonymization occurs, the data may be used for research or
              statistical purposes indefinitely without further notice to you.
            </p>
            <p>
              Overall, DigiDonation is committed to responsibly managing and
              retaining your personal data in accordance with applicable laws
              and regulations, as well as best practices for data protection and
              privacy.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>Right to unsubscribe</h5>
            <p>
              As a user, you have the right to unsubscribe from receiving
              updates and promotional communications from DigiDonation. If you
              no longer wish to receive updates or promotional emails, you can
              choose to update your preferences or send a request to opt-out to
              info@DigiDonations.com.
            </p>
            <p>
              Please note that even if you choose to unsubscribe from updates,
              your use of the Platform will still be governed by the Terms of
              Use and Privacy Policy. Opting out of updates does not exempt you
              from the terms and conditions outlined in these documents.
            </p>
            <p>
              If you prefer not to accept new cookies or want to manage how your
              browser handles cookies, you can adjust the settings through the
              Help menu in your browser. Most browsers allow you to prevent your
              browser from accepting new cookies, receive notifications when you
              receive a new cookie, or disable cookies altogether. You can also
              manage similar data used by browser add-ons, such as Flash
              cookies, by changing the settings or visiting the manufacturer's
              website.
            </p>
            <p>
              However, please be aware that disabling cookies may limit your
              access to certain features and functionality on the DigiDonation
              platform. Some essential features may rely on cookies, and
              disabling them could impact your overall user experience.
              Therefore, it is recommended to leave cookies turned on if you
              want to take full advantage of the platform's capabilities.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>Persons allowed to Use DigiDonation</h5>
            <p>
              The use of DigiDonation is limited to Indian passport holders who
              are legally capable of entering into a binding contract as per the
              Indian Contract Act, 1872. If you are under the age of 18 years (a
              minor), you may use DigiDonation only with the involvement and
              consent of a parent or legal guardian. We do not knowingly collect
              or store personal data of children unless permitted by law or
              provided to us in cases where a child is a beneficiary of a
              campaign.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>Grievances</h5>
            <p>
              DigiDonation is committed to addressing the concerns of
              donor-users promptly. If a donor has any grievances or complaints,
              they may report their concerns to the grievance officer. The
              grievance officer will strive to resolve the grievances within one
              month from the date of receiving such a complaint.
            </p>
            <p>Please find below the details of the grievance officer:</p>
            <p>
              Name: Neeraj Jain,Virender Singh Bose
              <br />
              Address: 73 Vijay Block, 1st Floor Opposite Pillar No. 50 Laxmi
              Nagar Metro, New Delhi-92
              <br />
              Mobile: 9899900300
              <br />
              Email: digidonation@gmail.com
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>Changes to This Privacy Policy</h5>
            <p>
              We reserve the right to update or modify our Privacy Policy at any
              time, and it is advisable to check this Privacy Policy
              periodically for any changes. By continuing to use the Service
              after we post any modifications to the Privacy Policy on this
              page, you acknowledge the modifications and consent to abide and
              be bound by the updated Privacy Policy.
            </p>
            <p>
              If we make any material changes to this Privacy Policy, we will
              notify you through the email address you have provided us, or by
              placing a prominent notice on the Platform.
            </p>
          </Grid>
        </Grid>
      </div>

      {/* <Footer /> */}
    </Box>
  );
};

export default PrivacyPolicy;
