import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";
import { getData } from "../api/apiController";

export function formatIndianRupee(amount) {
    // Convert the amount to a string
    const amountStr = amount?.toString();
  
    // Split the string into integer and decimal parts
    const [integerPart, decimalPart] = amountStr?.split('.');
  
    // Add commas to the integer part
    const formattedIntegerPart = integerPart?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    // Combine the formatted integer part and decimal part (if present)
    const formattedAmount = decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;
  
    return `${formattedAmount}`;
  }


export function calculateReadingTime(text, wordsPerMinute = 200) {
  const wordCount = text.split(/\s+/).length; // Split text into words
  const readingTimeMinutes = Math.ceil(wordCount / wordsPerMinute);
  return readingTimeMinutes;
}
export function capitalizeFirstLetter(str) {
  // Check if the input is a valid string
  if (typeof str !== "string" || str.length === 0) {
    return str;
  }

  // Capitalize the first letter and concatenate with the rest of the string
  return str.charAt(0).toUpperCase() + str.slice(1);
}


  export const useBackListener = (callback) => {
    const navigator = useContext(UNSAFE_NavigationContext).navigator;
  
    useEffect(() => {
      const listener = ({ location, action }) => {
        console.log("listener", { location, action });
        if (action === "POP") {
          callback({ location, action });
        }
      };
  
      const unlisten = navigator.listen(listener);
      return unlisten;
    }, [callback, navigator]);
  };
  