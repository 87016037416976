import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button } from "@mui/material";
import "./footer.scss";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
import digiDonationLogo from "./../../images/digidonation-logo.svg";
import { validateEmail } from "../../shared/validationSchema";
import { toast } from "react-hot-toast";
import { postData } from "../../api/apiController";

const Footer = () => {
  const [inputValue, setInputValue] = useState("");

  const onSubmitEmail = () => {
    if (!validateEmail(inputValue)) {
      toast.error("Invalid Email");
    } else {
      let name = inputValue.split("@");
      try {
        const result = postData(
          `change_makers?name=${name[0]}&email=${inputValue}`
        );
        setInputValue("");
        toast.success("You subscribed Successfully");
      } catch (error) {}
    }
  };
  return (
    <Box className="footer">
      <Grid container spacing={3} className="row">
        <Grid xs={12} md={3}>
          <div className="logobox">
            <img src={digiDonationLogo} alt="" className="logo" />
          </div>

          <p className="info_p_small mt-3 mb-8">
            we are on a mission to make a positive impact on the lives of
            individuals and communities in need. With your unwavering support,
            we can continue to empower those facing adversity, provide essential
            resources, and foster sustainable development. Together, we create a
            ripple effect of change, transforming lives and building a brighter
            future for all.
          </p>
          <div className="social-links">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
              to={
                "https://www.facebook.com/profile.php?id=61550000582945&mibextid=ZbWKwL"
              }
            >
              <FacebookRoundedIcon />
            </Link>
            <Link className="social-link">
              <TwitterIcon />
            </Link>
            <Link
              className="social-link"
              to={"https://www.instagram.com/digidonation/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </Link>
          </div>
        </Grid>
        <Grid xs={6} md={3}>
          <ul className="menus">
            <li>
              <Link className="menuItems" to={"/about-us"}>
                About Us
              </Link>
            </li>
            <li>
              <Link className="menuItems" to={"/request-campaign"}>
                Request a Campaign
              </Link>
            </li>
            <li>
              <Link className="menuItems" to={"/explore-campaigns"}>
                Explore Campaigns
              </Link>
            </li>
            <li>
              <Link className="menuItems" to={"/donate-monthly"}>
                Donate Monthly
              </Link>
            </li>
            {/* <li>
              <Link className="menuItems">Contact Us</Link>
            </li> */}
          </ul>
        </Grid>
        <Grid xs={6} md={3}>
          <ul className="menus">
            <li>
              <Link className="menuItems" to={"/terms-of-use"}>
                Terms Of Use
              </Link>
            </li>
            <li>
              <Link className="menuItems" to={"/privacy-policy"}>
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link className="menuItems" to={"/faq"}>
                FAQs
              </Link>
            </li>
            <li>
              <Link className="menuItems" to={"/blogs"}>
                Blogs
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid xs={12} md={3}>
          <p className="heading_p">Head Office, India</p>
          <p className="info_p mb-5">
            73 Vijay Block, 1st Floor Opposite Pillar No. 50 Laxmi Nagar Metro,
            <br /> New Delhi-92
          </p>
          <p className="info_p mb-5">
            <span>Contact us at:</span> 9350470337
          </p>
          <p className="info_p mb-5">
            <span>Email:</span>
            <a href="mailto:support@digidonation.com">
              {" "}
              support@digidonation.com
            </a>{" "}
          </p>
          <div className="emailbox">
            <div className="emailtextfield">
              <input
                className="inputfield"
                type="text"
                value={inputValue}
                placeholder="Enter your mail"
                onChange={(e) => setInputValue(e.target.value)}
              />
            </div>
            <Link className="greenBtn" onClick={onSubmitEmail}></Link>
          </div>
        </Grid>
        {/* <Grid xs={12}>
          <>
            <Grid container>
              <Grid xs>
                <img src="./images/map1.svg" alt="" />
              </Grid>
              <Grid xs>
                <img src="./images/map2.svg" alt="" />
              </Grid>
              <Grid xs>
                <img src="./images/map3.svg" alt="" />
              </Grid>
            </Grid>
            <Grid container className="mt-5">
              <Grid xs textAlign={'center'}>
                <p>LOREM IPSUM</p>
              </Grid>
              <Grid xs textAlign={'center'}>
                <p>LOREM IPSUM</p>
              </Grid>
              <Grid xs textAlign={'center'}>
                <p>LOREM IPSUM</p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12}>
                <div className="emailbox">
                  <div className="emailtextfield">
                    <input
                      className="inputfield"
                      type="text"
                      value={inputValue}
                      placeholder="Enter your mail"
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                  </div>
                  <Link className="greenBtn"></Link>
                </div>
              </Grid>
            </Grid>
          </>
        </Grid> */}
      </Grid>
      <div className="bottom-strip">
        <Grid container spacing={3} className="row">
          <Grid xs={12}>
            <p>
              © copyright {new Date().getFullYear()} DigiDonation | All rights
              reserved.
            </p>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default Footer;
