import React, { useEffect, useState } from "react";
import { getData } from "../../api/apiController";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Button,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Modal,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./../Popups/ModalPopup.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ArrowBack, ArrowForward } from "@material-ui/icons";

const CampaignUpdates = ({ id }) => {
  const [updates, setUpdates] = useState({
    images: [],
  });
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = React.useState(0);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [updateimgs, setUpdateimgs] = React.useState([]);
  
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 300 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  useEffect(() => {
    getUpdates();
  }, []);
  console.log("sd",updateimgs,updates)
  // Get Updates
  const getUpdates = async () => {
    try {
      const result = await getData(`updates?campaign_request_id=${id}`);
      console.log("--", result);
      if (result.status === 200) {
        setUpdates(result?.data?.data);
      }
    } catch (error) {
      return error;
    }
  };
  

  const handleImageClick = (imageIndex,updateimgs) => {
    // Clone the updateimgs array
    const updatedImages = [...updateimgs];

    // Remove the clicked image from its current position
    const clickedImage = updatedImages.splice(imageIndex, 1)[0];

    // Add the clicked image as the first element
    updatedImages.unshift(clickedImage);

    // Update the state with the new order
    setUpdateimgs(updatedImages);

    // Open the image slider
    setOpen(true);
  };
  return (
    <>
      <div>
        <h4 className="mt-3">Updates</h4>
        {updates && updates.length > 0 &&
          updates?.map((update, index) => (
            <Box marginBottom={"20px"}>       
              <Accordion
                key={index}
                expanded={expanded === index}
                onChange={handleChange(index)}
                sx={{ background: "#e1e1e1" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${index}-content`}
                  id={`${index}-header`}
                >
                  <Typography>
                    <p className="camp_desc_p mb-0">
                      <span className="viewall_link">13/07/2023</span>{" "}
                    </p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="updates_imgs">
                      <ul>
                        {update?.images?.map((image, index) => (
                          <li key={index}>
                            <Link onClick={() => handleImageClick(index,update?.images)}>
                              <img src={image} alt="updates" />
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div
                      dangerouslySetInnerHTML={{
                        __html: update.description,
                      }}
                    ></div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box className="modalWrapper">
          <Box className="modalContent smWidth">
            <Button
              className="closePopup close2"
              onClick={() => setOpen(false)}
            />
            <Grid container spacing={0} alignItems="center">
              <Grid xs={12}>
                <div className="carousel-part">
                  <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    infinite={true}
                    autoPlay={false}
                    autoPlaySpeed={3000} // Adjust the speed as needed (in milliseconds)
                  >
                    {updateimgs.map((item, i) => (
                      <img src={item} />
                    ))}
                  </Carousel>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default CampaignUpdates;
