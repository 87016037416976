import StarIcon from "@mui/icons-material/Star";
import { Box, Button, Link, Rating } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import Carousell from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { getData } from "../../api/apiController";
import CommonCard from "../../components/commanCards/commonApiCard";
import MedicalCampaignsCards from "../../components/commanCards/medicalCampaigns";
import DailyUpdates from "../../components/dailyUpdates/dailyUpdates";
import "./../../index.scss";
import "./homepage.scss";

const Homepage = () => {
  const [inputValue, setInputValue] = useState("");
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [medicalCampaign, setMedicalCampaign] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [largebanner, setlargebanner] = useState("");
  const [smallbanner, setsmallbanner] = useState("");
  const [activecategoryIndex, setactivecategoryIndex] = useState(0);
  const [hideSection, SethideSection] = useState(false);

  const navigate = useNavigate();
  console.log("hideSection", hideSection);

  const testimonialsresponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 2,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 300 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const testimonials = [
    {
      testimonial_publisher_img: "./images/nobody.jpg",
      testimonial_publisher_name: "Varun Yadav",
      rating_value: 4,
      testimonial_desc: `Digidonation has been a true game-changer in the fight against hunger. Their online platform makes it incredibly easy to support their mission. I've had the privilege of donating, and it's heartwarming to see how efficiently they use funds to provide meals to those in need. Keep up the fantastic work, Digidonation!`,
    },
    {
      testimonial_publisher_img: "./images/nobody.jpg",
      testimonial_publisher_name: "Harish Khurana",
      rating_value: 5,
      testimonial_desc: `I stumbled upon Digidonation while searching for ways to make a difference. What they're doing to help feed hungry people is truly inspiring. Their website is user-friendly, making it easy for anyone to contribute to their cause. I'm impressed by their transparency and commitment to making a positive change in the world.`,
    },
    {
      testimonial_publisher_img: "./images/nobody.jpg",
      testimonial_publisher_name: "Ayush Maheshwari",
      rating_value: 4,
      testimonial_desc: `Digidonation is a lifeline for so many. Their digital approach to addressing hunger is both innovative and effective. I've witnessed firsthand the impact they've had in our community, and it's nothing short of remarkable. Proud to be a supporter!`,
    },
    {
      testimonial_publisher_img: "./images/nobody.jpg",
      testimonial_publisher_name: "Neeraj Gupta",
      rating_value: 5,
      testimonial_desc: `Digidonation has redefined the way we address hunger. Their online platform is a testament to the power of technology in making a difference. I've seen how efficiently they utilize donations to provide meals and support to those who need it most. It's a privilege to support such a worthy cause.`,
    },
  ];

  // GET Data request
  useEffect(() => {
    fetchAllCategories();
    fetchAllBannner();
    getCampaignById(5);
  }, []);

  // Get all campaigns
  const fetchAllCampaigns = async (categoryId) => {
    try {
      const result = await getData(
        `campaigns?camapign_category_id=${
          categoryId ? categoryId : ""
        }&is_recurring=false`
      );
      if (result.status === 200) {
        const allCampaigns = result?.data?.data;
        const firstSixCampaigns = allCampaigns.slice(0, 6);
        
        setAllCampaigns(firstSixCampaigns);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const fetchAllBannner = async () => {
    try {
      const result = await getData(`dynamic_assets/largebanner`);
      const result1 = await getData(`dynamic_assets/smallbanner`);
      if (result.status === 200) {
        const largeBanner = result?.data?.data.banner;
        const largeBanner1 = result1?.data?.data.banner;
        setlargebanner(largeBanner);
        setsmallbanner(largeBanner1);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  // Get Campaign by iD
  const getCampaignById = async (id) => {
    try {
      const result = await getData(
        `campaigns/?campaign_category_id=${id}&is_recurring=false`
      );
      const firstThreeCampaigns = result?.data?.data?.slice(0, 3);
      if (firstThreeCampaigns.length > 0) {
        setMedicalCampaign(firstThreeCampaigns);
      } else {
        // Handle the case when no campaigns are available for the specified category ID
        setMedicalCampaign([]);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  //  Handle Route
  const handleCampaingDetail = (id) => {
    navigate(`/explore-campaigns/${id}`);
  };

  // Get all categories
  const fetchAllCategories = async () => {
    try {
      const result = await getData(`campaign_categories`);
      if (result?.status === 200) {
        result.data &&
          result?.data?.data?.unshift({
            name: "ALL",
            id: "ALL",
          });
        setAllCategories(result?.data?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // Get Campaign bby category
  const handleCallByCategory = (category_id) => {
    console.log("cat Id", category_id);
    fetchAllCampaigns(category_id);
    setCategoryId(category_id);
  };

  return (
    <Box className="homePageWrapper">
      {/* <Navbar style={{color:'white'}}/> */}

      <div className="topheader-sec">
        <Grid container spacing={3} rowSpacing={0} className="">
          <Grid xs={12} className="" padding={0}>
            {/* <div className="th_inner-sec">
              <div className="headText">
                <h1>
                  We <span>Rising</span>
                  <br />
                  by lifting others...
                </h1>
                <p className="sub_desc hd" style={{ textAlign: "justify" }}>
                  Our mission is to make a positive impact in the lives of those
          effect of change. Through our dedicated efforts, we strive to
                          less fortunate, empowering communities, and creating a ripple
                  lift others out of adversity, bringing hope, dignity, and
                  opportunities to those in need.
                </p>
              </div>
            </div>

            <div className="arcbox">
              <div className="pg_tag1">Donate to NGOs</div>
              <div className="pg_tag2">Verified NGOs</div>
              <div className="pg_tag3">Timely Updates</div>
            </div> */}
            <Link href="/explore-campaigns/1">
              <img src={largebanner} alt="" className="header_banner" />
              {/* for mobile view */}
              <img src={smallbanner} alt="" className="header_banner_mb" />
            </Link>
          </Grid>
          {/* <Grid xs={12} padding={0}>
            <img
              src="./images/header_slider_image1.svg"
              alt=""
              className="homepage_banner_mobile.svg"
            />
          </Grid> */}
        </Grid>
      </div>

      <div className="donation_calc">
        <Grid container spacing={3} className="row">
          <Grid xs={12} lg={4}>
            <div className="donation_collection">
              <img src="./images/donation_icon.svg" alt="" />
              <div className="detail-sec">
                <h3>
                  <CountUp end={200} duration={5} /> Cr +
                </h3>
                <p>DONATION RECEIVED</p>
              </div>
            </div>
          </Grid>
          <Grid xs={12} lg={4}>
            <div className="donation_collection">
              <img src="./images/donation_icon3.svg" alt="" />
              <div className="detail-sec">
                <h3>
                  <CountUp end={7000} duration={5} /> +
                </h3>
                <p>REGISTERED NGO</p>
              </div>
            </div>
          </Grid>
          <Grid xs={12} lg={4}>
            <div className="donation_collection">
              <img src="./images/donation_icon33.svg" alt="" />
              <div className="detail-sec">
                <h3>
                  <CountUp end={90000} duration={5} /> +
                </h3>
                <p>Nourished individuals</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div>
        {/* <Grid container spacing={3} className="row">
          <Grid xs={12} textAlign={"center"}>
            <div className="loader">
              <div class="loading"></div>
            </div>
          </Grid>
        </Grid> */}
      </div>

      <div className="campaign_sec">
        {!hideSection && (
          <Grid container spacing={3} className="row">
            <Grid xs={12} textAlign={"center"}>
              <h2>
                Medical <span>Emergencies</span>
              </h2>
              {/* <Link href="https://www.instamojo.com/@digidonation">INSTAMOJO</Link> */}
              <p className="sub_desc mb-10">
                Every Moment Counts When Lives Hang In The Balance, And
                Together, We Can Make A Difference. Your Donations
                <br /> Enable Us To Offer Immediate Medical Assistance To Those
                Caught In Life-Threatening Situations.
              </p>
            </Grid>
            <Grid xs={12} textAlign={"right"}>
              <Link
                className="viewall_link"
                onClick={() => {
                  navigate("/explore-campaigns");
                }}
              >
                View All{" "}
              </Link>
            </Grid>
            <Grid xs={12} paddingLeft={0} paddingRight={0}>
              {/* Medical Campaigns */}
              <MedicalCampaignsCards SethideSection={SethideSection} />
            </Grid>
          </Grid>
        )}
        <div className="filterSection mt-12">
          <Grid container spacing={3} className="row">
            <Grid xs={12} textAlign={"center"}>
              <h2>
                All <span>categories</span>
              </h2>
              <p className="sub_desc mb-10">
                Transforming Lives Through Giving. Your support fuels campaigns
                that bring hope and essentials to those in need.
                <br />
                Together, we make a meaningful impact on education, healthcare,
                and well-being.
              </p>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="row">
            <Grid xs={12} className="mainFilters" textAlign={"center"}>
              {allCategories &&
                allCategories?.length > 0 &&
                allCategories?.map((item, index) => (
                  <span
                    className={
                      index === activecategoryIndex
                        ? "radioBox activecategory"
                        : "radioBox"
                    }
                    onClick={() => {
                      handleCallByCategory(item?.id === "ALL" ? "" : item?.id);
                      setactivecategoryIndex(index);
                    }}
                    key={item?.id}
                  >
                    <label htmlFor={item?.name}>
                      {item?.name}
                      <input type="radio" id={item?.name} name="mainFilters" />
                    </label>
                  </span>
                ))}
            </Grid>
            <Grid xs={12} textAlign={"right"} marginBottom={"12px"}>
              <Link
                className="viewall_link"
                onClick={() => {
                  navigate("/explore-campaigns");
                }}
              >
                View All
              </Link>
            </Grid>
          </Grid>
        </div>

        <CommonCard
          endpoint={`campaigns?camapign_category_id=${categoryId}&is_reccuring=false`}
          searchId={categoryId}
          hideSeemore
          campaignLimit={6}
          page={1}
        />
      </div>

      <div className="support-sec">
        <Grid container spacing={3} className="row">
          <Grid xs={12} textAlign={"center"}>
            <h2>
              Support <span>Us</span>
            </h2>
            <p className="sub_desc mb-10">
              Be the Change, Support Our Mission. Your generosity powers our
              efforts to create a better world.
              <br /> Join hands with us to drive positive impact and inspire
              lasting change. Together, we can make a difference.
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="row">
          <Grid xs={12} md={7}>
            <img
              src="./images/support_sec_img1.svg"
              alt=""
              className="img-fluid"
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid xs={12} md={5} height={"auto"}>
            <div className="supportus_box">
              <h5>
                Help the<span> Community</span>
              </h5>
              <p>
                Catalyze Change, Support Now.Your help transforms lives and
                strengthens communities.Your Contribution Creates Lasting
                Impact!{" "}
              </p>
              <div className="input-box">
                <span>
                  <img src="./images/Indian-Rupee-symbol.svg" alt="" />
                </span>
                <input
                  className="inputfield"
                  type="text"
                  value={inputValue}
                  placeholder="Enter Amount for donation"
                  onChange={(e) => (
                    setInputValue(e.target.value),
                    localStorage.setItem("totalamountcheckout", e.target.value)
                  )}
                />
              </div>
              <Button
                variant="contained"
                className="donate_sec_btn"
                disabled={inputValue ? false : true}
                onClick={() => {
                  navigate("/checkout", {
                    state: {
                      from: "page",
                    },
                  });
                }}
              >
                Donate
              </Button>
            </div>
          </Grid>
          <Grid xs={12} sm={4}>
            <img
              src="./images/support_sec_img2.svg"
              alt=""
              className="img-fluid"
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid xs={12} sm={4}>
            <div className="supportus_box">
              <h5>
                Now we Need <span>your help</span>
              </h5>
              <p>
                Be a Beacon of Hope in Our Community and Embrace the Opportunity
                to Make a Difference. Your Support Drives Meaningful
                Transformation.
              </p>
            </div>
          </Grid>
          <Grid xs={12} sm={4}>
            <img
              src="./images/support_sec_img3.svg"
              alt=""
              className="img-fluid"
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className="row"></Grid>
      </div>

      {/* <div className="updates_sec">
        <Grid container spacing={3} className="row">
          
        </Grid>
      </div> */}

      <DailyUpdates />

      <div className="testimonials_sec">
        <Grid container spacing={3} className="row">
          <Grid xs={12} textAlign={"center"}>
            <h2>Testimonials</h2>
            <p className="sub_desc mb-10">
              Explore Heartwarming Testimonials: Discover What People Are Saying
              <br /> About Digidonation's Impact on Feeding Hungry Communities
            </p>
          </Grid>
          {/* <Grid xs={12} textAlign={"center"}>
            <Carousel
              className="carousel"
              animation="slide"
              duration={800}
              NextIcon={<ArrowForward />}
              PrevIcon={<ArrowBack />}
              autoPlay={false}
            >
              {testimonialCarousel.map((item, i) => (
                <Grid container spacing={3}>
                  <Grid md={1}></Grid>
                  <Grid xs={12} md={4} className="leftSec ">
                    <div className="profile m-xs-auto">
                      <div className="profile_bg"></div>
                      <div className="profile_tags">
                        <h6>Rohit</h6>
                        <p>SomeCompany LLC.</p>
                      </div>
                      <img src={item.img_url} />
                    </div>
                  </Grid>
                  <Grid xs={12} md={6} className="rightSec">
                    <p>
                      The service was amazing.I never had to wait for my food.I
                      never had to wait for my food.
                    </p>
                  </Grid>
                  <Grid xs={1}></Grid>
                </Grid>
              ))}
            </Carousel>
          </Grid> */}
          <Grid xs={12}>
            <Box className="carousel-part">
              <Carousell
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={testimonialsresponsive}
                infinite={true}
                autoPlay={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                autoPlaySpeed={3000}
              >
                {testimonials.map((data) => (
                  <Box className="testimonial_box">
                    <Box className="image_section">
                      <img src={data.testimonial_publisher_img} alt="" />
                      <h6>{data.testimonial_publisher_name}</h6>
                    </Box>
                    <Rating
                      name="hover-feedback"
                      value={data.rating_value}
                      readOnly
                      emptyIcon={
                        <StarIcon
                          style={{ color: "#d3d3d3" }}
                          fontSize="inherit"
                        />
                      }
                    />
                    <p>{data.testimonial_desc}</p>
                  </Box>
                ))}
              </Carousell>
            </Box>
          </Grid>
        </Grid>
      </div>

      {/* <Footer /> */}
    </Box>
  );
};

export default Homepage;
