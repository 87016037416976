import React, {useState, useEffect} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Button,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import "./../../index.scss";
import "./../homepage/homepage.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function FaqPage() {
  const itemsDonar = [
    {
      title: "What is Digidonation?",
      content:
        "Digidonation is a distinctive online donation platform designed to facilitate direct contributions to specific items required by NGOs. We've partnered with over 1400 NGOs across India, helping raise more than 150+ crores worth of essential products. Our platform has garnered the support of over 5 lakh individual donors who are dedicated to their chosen charitable causes. As a Digidonation contributor, you'll enjoy full transparency: upon donating towards specific items, you'll receive regular updates directly from the campaign organizer.",
      id: "panel1",
    },
    {
      title: "Why Can You Trust Digidonation?",
      content:
        "Digidonation stands as a unique platform that introduces an enhanced level of trust and transparency to your contributions towards social organizations. Unlike traditional monetary donations, we ensure direct delivery of products to these organizations. Our platform prioritizes transparency through distinctive features designed to guarantee your peace of mind.",
      id: "panel4",
    },
    {
      title:
        "How can I Claim Tax Exemption for my Contributions on Digidonation?",
      content:
        "Upon the successful completion of your donation, you will discover the 'GET 80G' option on the payment confirmation page. Kindly provide your PAN number, complete address, and name as per PAN, then submit the details. Your 80G certificate will be both downloadable and sent to you via email.Additionally, you have the option to retrieve a consolidated 80G receipt in the 'My Donations' section. Simply choose the required year for the receipt and click 'Download'. This certificate can then be utilized to claim tax benefits while filing your Income Tax Return (ITR) in India.",
      id: "panel2",
    },

    {
      title: "Why is a Tip Amount Collected?",
      content:
        "Occasionally, product prices may fluctuate throughout a campaign. The tips we collect serve the purpose of accommodating these price changes and ensuring the successful delivery of goods to NGOs.A tip is a voluntary contribution towards our platform, allowing donors to offer any amount they wish as a token of support.",
      id: "panel3",
    },
    {
      title: "When Will My Donated Products Be Delivered?",
      content:
        "All products acquired during a campaign will be delivered at the campaign's conclusion. However, if an organization makes a request, we can also arrange for the products to be delivered before the campaign's end, providing added flexibility based on specific needs.",
      id: "panel5",
    },
    {
      title: "Is It Possible to Donate Second-Hand/Used Products?",
      content:
        "Digidonation exclusively focuses on collecting new and durable products. We curate and list only high-quality items for various campaigns on our platform. However, there are alternate channels through which second-hand products can be contributed to NGOs.",
      id: "panel10",
    },
    {
      title:
        "Is it Possible to Unsubscribe from Digidonation's Monthly Giving Plans?",
      content:
        "Certainly, you have the option to unsubscribe from any of our monthly giving plans whenever you desire. To proceed, simply get in touch with our customer support team through email or WhatsApp. Submit a request for unsubscribing, and we'll process it promptly. Within 3-4 days, you'll be successfully unsubscribed.",
      id: "panel7",
    },
    {
      title: "Is Offline Donation Possible by Visiting the NGO?",
      content:
        "At Digidonation, we prioritize need-based donations by specifying the required quantities of products for each organization at a given time. While we appreciate offline donations, kindly note that we are unable to track these contributions. This might lead to NGOs receiving products they've already obtained through Digidonation. We recommend donating products through our platform and considering visiting the organization after the items have been successfully delivered.",
      id: "panel8",
    },
    {
      title: "Who Ensures the Quality of Listed Products?",
      content:
        "At Digidonation, we provide organizations with diverse quality and price options for their campaigns. The responsibility of selecting and listing products lies with the respective organizations. They choose the products that align with their campaign's requirements and preferences.",
      id: "panel9",
    },
    {
      title: "Is It Possible to Receive a Donation Refund?",
      content:
        "After making a donation to a campaign on Digidonation, the contribution becomes non-refundable. However, if there is an accidental duplicate charge, you will receive a refund for the duplicated amount. For additional inquiries, feel free to contact us at info@digidonation.com.",
      id: "panel6",
    },

    {
      title: "What Sets Digidonation Apart from Other Platforms?",
      content:
        "Digidonation distinguishes itself by offering a commission-free model, unlike other platforms that typically charge commissions exceeding 10-15%. We provide a cost-free experience for both Donors and NGOs. Moreover, our platform revolutionizes traditional monetary donations by facilitating direct provision of needed products to NGOs. This guarantees that your contribution reaches those in need. The added tracking service and regular updates further enhance the platform's uniqueness.",
      id: "panel11",
    },
  ];

  const itemsNgo = [
    {
      title: "How to Initiate a Campaign on Digidonation?",
      content: `
        To initiate a campaign on Digidonation, kindly complete this form - [Click Here](https://digidonation.com/). 
        Our team will carefully assess your submission, and if there's potential for collaboration, we'll contact you within a week for further details. 
        Please understand that due to the volume of requests we receive, we might not be able to respond to every submission. We appreciate your understanding and cooperation.`,
      id: "panel1",
    },
    {
      title: "What Types of Products Can Be Featured on Digidonation?",
      content:
        "Digidonation accommodates a diverse range of products on our platform, spanning from stationery and groceries to medical equipment and daily essentials. To gain a clearer perspective on the products we facilitate, you can explore completed campaigns on our website. Additionally, upon receiving an email from you regarding your campaign launch, we'll share a comprehensive catalogue. It's important to note that 70% of an NGO's funds are typically allocated to procuring various products—these are precisely the types of items that Digidonation can help raise for your cause.",
      id: "panel4",
    },
    {
      title:
        "What's the Duration of a Campaign?",
      content:
        "Campaigns on Digidonation do not have a set time limit. However, we recommend running a campaign for approximately 30-45 days to maximize its effectiveness. Generally, contributors are more inclined to support campaigns with immediate needs rather than those associated with long-term projects.",
      id: "panel2",
    },

    {
      title: "How Can I Monitor Donor Contributions to Our Organization?",
      content:
        "Each time a donation is made to your campaign, it will be promptly displayed on your campaign page. Furthermore, we will provide you with admin details that enable you to track your donor's information. This facilitates your ability to express gratitude and acknowledge their invaluable contributions.",
      id: "panel3",
    },
    {
      title: "Is There a Limit to the Number of Products I Can Raise?",
      content:
        "At Digidonation, there are no restrictions on the quantity of products you can raise. We recognize that organizations often require substantial supplies to effectively operate. However, we advise initiating a campaign with a pragmatic goal. Donors typically prefer contributing to campaigns that are closer to reaching their targets, enhancing the likelihood of successful support.",
      id: "panel5",
    },
    {
      title: "When Can We Expect the Delivery of Products?",
      content:
        "The products obtained through a campaign will be delivered upon the campaign's conclusion. Even if the campaign doesn't reach 100% of its target, you will still receive the products acquired by the campaign's end.",
      id: "panel10",
    },
    {
      title:
        "Will Digidonation Provide Campaign Assistance?",
      content:
        "At Digidonation, we are dedicated to aiding campaigners in achieving their targets within the specified timeframe. To facilitate this, we assign a campaign manager to guide you through every stage of the campaign. This experienced manager will share proven techniques and strategies to effectively promote your campaign and yield favorable outcomes.",
      id: "panel7",
    },
    {
      title: "Is There a Cost to Launch a Campaign with Digidonation?",
      content:
        "Creating a campaign on Digidonation is absolutely free for both organizations and donors. Our ability to offer these services without charge is made possible by the collaboration of sellers, logistics companies, and various partners who support our mission.",
      id: "panel8",
    },
    {
      title: "Who Will Be My Contact at Digidonation? Will I Have a Designated Relationship Manager?",
      content:
        "Certainly, a dedicated campaign manager will be assigned to guide you throughout the entire process. You're encouraged to freely reach out to your campaign manager for any assistance you may require. They will serve as your main point of contact, ensuring your campaign's success and addressing any inquiries or needs you might have.",
      id: "panel9",
    },
    {
      title: "What Sets Digidonation Apart for Donors?",
      content:
        "Digidonation stands out by enabling NGOs to request products instead of monetary donations, ensuring that each donor gets the utmost value for every rupee they invest in a product. This level of transparency regarding donations appeals to donors, setting us apart from other crowdfunding platforms. Our unique approach of tracking donations and providing regular updates from NGOs also contributes to our appeal. Numerous NGOs have witnessed a remarkable 250% increase in their donor base thanks to Digidonation, making us an enticing choice for donors seeking impactful giving experiences.",
      id: "panel6",
    },
  ];
  const [expanded, setExpanded] = React.useState();
  const [activeLink, setActiveLink] = useState("donor");
  const [activeTab, setActiveTab] = useState(0);
  const [animation, setAnimation] = useState("");

  const handleTabChange = (tabId, link) => {
    setActiveTab(tabId);
    setActiveLink(link);
    setAnimation("");
  
    setTimeout(() => {
      setAnimation("slideDown");
    }, 10);
  };


  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  useEffect(() => {
    // This will run whenever `activeTab` changes
    if (activeTab !== null) {
      setAnimation("");
      setTimeout(() => {
        setAnimation("slideDown");
      }, 10);
    }
    setExpanded(activeTab === "ngo" ?itemsNgo[0].id: itemsDonar[0].id )
  }, [activeTab]);
  return (
    <Box className="homePageWrapper">

      <div className="faq-header">
        <img src="./images/faq.jpg" alt="" />
      </div>

      <Box className="faq-section">
        <Grid container spacing={3} className="row">
          <Grid xs={12}>
            <h2>FAQ</h2>
          </Grid>
          <Grid xs={12}>
            <div className="tabsSection">
              {/* Render buttons */}
              <div className="mb-7">
                <Link
                  className={`nav-link ${activeLink === "donor" ? "active" : ""}`}
                  onClick={() => handleTabChange(0, "donor")}
                >
                  DONORS
                </Link>
                <Link
                  className={`nav-link ${
                    activeLink === "ngo" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange(1, "ngo")}
                >
                  NGOs
                </Link>
              </div>

              {/* Render active tab content */}
              {activeTab === 0 ? (
                <Grid container spacing={3} className={`row ${animation}`} paddingLeft={0}>
                  <Grid xs={12} paddingLeft={0} paddingRight={0}>
                    <div className="faq-ques">
                      {itemsDonar?.map((item) => (
                        <Accordion
                          key={item.id}
                          expanded={expanded === item.id}
                          onChange={handleChange(item.id)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${item.id}-content`}
                            id={`${item.id}-header`}
                          >
                            <Typography>{item.title}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>{item.content}</Typography>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={3} className={`row ${animation}`} paddingLeft={0}>
                  <Grid xs={12} paddingLeft={0} paddingRight={0}>
                    <div className="faq-ques">
                      {itemsNgo?.map((item) => (
                        <Accordion
                          key={item.id}
                          expanded={expanded === item.id}
                          onChange={handleChange(item.id)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${item.id}-content`}
                            id={`${item.id}-header`}
                          >
                            <Typography>{item.title}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>{item.content}</Typography>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
      </Box>

    </Box>
  );
}

export default FaqPage;
