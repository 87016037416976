import react, { useEffect, useState } from "react"
import { getData } from "../../api/apiController";
import { Alert, Avatar, Button } from "@mui/material";
import { capitalizeFirstLetter } from "../../helper/helpingFuntions";
const DonarList = ({id}) =>{
    const [donarData, setDonarData] = useState([]);
    const [page,setPage] = useState(1)
    // Call api by id
    useEffect(() => {
        fetchDonar();
    }, []);
    const fetchDonar = async () => {
        try {
          const result = await getData(`donations/${id}/latest_donations`);      
          // Sort the data array by the 'created_at' property in descending order (latest first)
          const sortedData = result?.data?.data.sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return dateB - dateA;
          });
          setDonarData(sortedData);
        } catch (error) {
          console.log("error", error);
        }
      };
      
    return (
      <>
        <div className="border-box mt-6">
          <h4>Donors</h4>
          {donarData && donarData.length > 0 ? (
            <ul className="donar_list mb-5">
              {donarData.map((element) => (
                <li key={element.id}>
                  <div className="img_box">
                    <Avatar
                      style={{
                        width: "51px",
                        height: "51px",
                        borderRadius: "50%",
                      }}
                      src="/broken-image.jpg"
                    />
                  </div>
                  <div className="user_detail">
                    <h6>{capitalizeFirstLetter(element?.name)}</h6>
                    <p className="amt">{element?.total_amount}</p>
                    <p className="timedate">
                      {new Date(element?.created_at).toLocaleString()}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <Alert className=" mt-4 mb-4" severity="warning">
              No donors available at the moment. Be the first donor!
            </Alert>
          )}
          {/* <div className="text-center">
      <Button variant="contained" className="yellow_btn mb-4" onClick={()=>setPage((prev)=>prev+1)}>
        View More
      </Button>
    </div> */}
        </div>
      </>
    );
}
export default DonarList