import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, Tooltip } from "@mui/material";
import "./Signup.scss";
import { useNavigate } from "react-router-dom";
import InputTextField from "./../../components/FieldTypes/TextField/TextField"
const Signup = () => {
  const navigate = useNavigate();
  const [showSignUpFields, setShowSignUpFields] = useState(true);
  const [formValue, setFormValue] = useState({
    name: { value: "", warning: false },
    email: { value: "", warning: false },
    phone: { value: "", warning: false },
    password: { value: "", warning: false },
    confirmPwd: { value: "", warning: false },
    userID: { value: "", warning: false },
    password: { value: "", warning: false },
  });
  const fieldsUpdateState = (attrName, value) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };
  return (
    <Box className="signupWrapper">
      <Grid container className="row">
        <Grid xs={6} className="left-part">
          <Link
            className="logo_position"
            display={"inline-block"}
            onClick={() => navigate("/")}
          >
            <img src="./images/digidonation-logo.svg" alt="" className="logo" />
          </Link>
          <div className="su_details">
            <Grid container spacing={3}>
            <Grid xs={12}>
                    <h2>
                      Sign<span>Up</span>
                    </h2>
                  </Grid>
                  <Grid xs={12}>
                    <InputTextField
                      class_name="inputField "
                      title={"Full Name*"}
                      value={formValue.name.value}
                      attrName={["name", "value", formValue, setFormValue]}
                      value_update={fieldsUpdateState}
                      warn_status={formValue.name.warning}
                      error_messg={""}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <InputTextField
                      class_name="inputField "
                      title={"Email*"}
                      value={formValue.email.value}
                      attrName={["email", "value", formValue, setFormValue]}
                      value_update={fieldsUpdateState}
                      warn_status={formValue.email.warning}
                      error_messg={""}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <InputTextField
                      class_name="inputField "
                      title={"Phone Number*"}
                      value={formValue.phone.value}
                      attrName={["phone", "value", formValue, setFormValue]}
                      value_update={fieldsUpdateState}
                      warn_status={formValue.phone.warning}
                      error_messg={""}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <InputTextField
                      class_name="inputField "
                      title={"Password*"}
                      value={formValue.password.value}
                      attrName={["password", "value", formValue, setFormValue]}
                      value_update={fieldsUpdateState}
                      warn_status={formValue.password.warning}
                      error_messg={""}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <InputTextField
                      class_name="inputField"
                      title={"Confirm Password"}
                      value={formValue.confirmPwd.value}
                      attrName={[
                        "confirmPwd",
                        "value",
                        formValue,
                        setFormValue,
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={formValue.confirmPwd.warning}
                      error_messg={""}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <Button variant="contained" className="donate_sec_btn">
                      Sign Up
                    </Button>
                  </Grid>
                  <Grid xs={12} textAlign={"center"}>
                    <p className="acc_p">
                      You have Account?{" "}
                      <Link onClick={() => navigate('/sign-in')}>
                        Sign In
                      </Link>
                    </p>
                  </Grid>
                  <Grid xs={12} textAlign={"center"}>
                    <p className="or_p">
                      <span>OR</span>
                    </p>
                  </Grid>

              <Grid xs={12} className="d_btns">
                <Link className="d_register google">Google</Link>
                <Link className="d_register fb">Facebook</Link>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid xs={6} className="right-part">
          <div className="img-container">
            <img src="./images/signup_img.png" alt="" />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Signup;
