import { Alert, Box, Button, Link, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState, useRef } from "react";
import "./../../index.scss";
import "./exploreCampaginDetail.scss";
// import "./../HomePage/Homepage.scss";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DevicesIcon from "@mui/icons-material/Devices";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InfoIcon from "@mui/icons-material/Info";
import LinkIcon from "@mui/icons-material/Link";
import PersonIcon from "@mui/icons-material/Person";
import TwitterIcon from "@mui/icons-material/Twitter";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InputTextField from "../../components/FieldTypes/TextField/TextField";
import { useParams } from "react-router-dom";
import { getData } from "../../api/apiController";
import { useNavigate } from "react-router-dom";
import gpayImg from "./../../images/gpay-logo.png";
import phonpeImg from "./../../images/phonepe-logo.png";
import paytmImg from "./../../images/paytm-logo.png";
import upiImg from "./../../images/upi-logo.png";
import rupeeSymbolImg from "./../../images/Indian-Rupee-symbol.svg";
import userImg from "./../../images/user.png";
import { formatIndianRupee, getUpdates } from "../../helper/helpingFuntions";
import ReferChangeMaker from "../../components/referChangemaker/referChangemaker";
import CampaignUpdates from "../../components/updates/campaignUpdates";
import DonarList from "../../components/donarList/donarList";
import { toast } from "react-hot-toast";
import PaymentSuccess from "../../components/Popups/PaymentSuccess";

const ExploreCampaginDetail = () => {
  const [paymentTotal, setPaymentTotal] = useState({
    unitPayment: 0,
    enterPayment: 0,
  });
  const [copySuccess, setCopySuccess] = useState(false);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  let isToastVisible = false;
  const urlSearchParams = new URLSearchParams(window.location.search)
  const payment_id = urlSearchParams.get('payment_id');
  const payment_request_id = urlSearchParams.get('payment_request_id');
  const payment_status = urlSearchParams.get('payment_status');
  const [campaginDetail, setCampaginDetail] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [productArray, setProductArray] = useState([]);
  const [activepaymentTypeLink, setActivePaymentTypeLink] = useState("upi");
  const [loader, setloader] = useState(true);
  // Use Params
  const { id } = useParams();
  const navigate = useNavigate();
  // for scrolling
  const productTab = useRef(null);
  const projectTab = useRef(null);
  const updateTab = useRef(null);

  const scrollToSection = (elementRef) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Call api by id
  useEffect(() => {
    fetchDataById();
    if (payment_id && payment_request_id && payment_status) {
      paymentSuccess();
    }
  }, []);
  console.log("productArray", productArray);

  const paymentSuccess = async () => {
    setShowPaymentSuccess(true)
    try {
      const result = await getData(`donations/verify_instamojo_payment?payment_id=${payment_id}&payment_request_id=${payment_request_id}&payment_status=${payment_status}`)
      console.log("===", result)
      if (result.status == 200) {

        urlSearchParams.delete("payment_id")
        urlSearchParams.delete("payment_request_id")
        urlSearchParams.delete("payment_status")
        // Create a new URL without the query parameters
        const newURL = window.location.pathname

        // Update the URL without the query parameters
        window.history.replaceState({}, document.title, newURL);
      }
    } catch (error) {

    }
  }

  const fetchDataById = async () => {
    try {
      const result = await getData(`campaigns/${id}`);
      const data = result?.data.camapign_products.map((element) => ({
        service: element.product_type,
        raisedFund: "",
        totalUnit: element.total_unit,
        enterFund: "",
        selectedFund: "",
        title: element.title,
        perUnit: element.unit_price,
        value: 0,
        addOnValue: 0,
        minusOnValue: 0,
        images: element.images.map((value) => value),
        campaign_product_id: element.id,
        raised_value: element.raised_value,
      }));
      setCampaginDetail(result.data.data);
      setProductArray(data);
      setloader(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  // get the  value by the enter the amount or selected the radio button
  const changeValue = (e, index, button) => {
    let dataArr = productArray;

    if (
      (button === "button" && e == "500") ||
      (button === "button" && e == "1000") ||
      (button === "button" && e == "1500")
    ) {
      dataArr[index].selectedFund = e;
      dataArr[index].enterFund = "";
    } else {
      const perUnit = dataArr[index].perUnit;
      const totalUnit = dataArr[index].totalUnit;
      const raisedValue = dataArr[index].raised_value;
      const enteredValue = Number(e);

      const maxAmount = perUnit * totalUnit - raisedValue;

      if (enteredValue > maxAmount) {
        dataArr[index].enterFund = maxAmount.toString();
      } else {
        dataArr[index].enterFund = enteredValue.toString();
      }
      dataArr[index].selectedFund = "";
    }
    setProductArray([...dataArr]);
    let totalPayment = 0;
    dataArr.forEach((element) => {
      let selectValue = Number(element.selectedFund);
      let enterValue = Number(element.enterFund);

      if (isNaN(selectValue) || isNaN(enterValue)) {
        return;
      } else {
        let calculatedFund = selectValue + enterValue;
        totalPayment += calculatedFund;
      }
    });

    setPaymentTotal((prev) => ({
      ...prev,
      enterPayment: totalPayment,
    }));
    setProductArray([...dataArr]);
  };

  // get addOn value as per unit
  const handleClick = (index) => {
    let data = productArray;
    data[index].value = data[index].value + parseInt(data[index].perUnit);
    data[index].addOnValue = data[index].addOnValue + 1;
    setProductArray([...data]);
    let sum = 0;
    data.forEach((element) => {
      let perUnitValue = element.value;
      if (perUnitValue === undefined) {
        return;
      } else {
        sum = sum + perUnitValue;
      }
    });
    setPaymentTotal((prev) => ({
      ...prev,
      unitPayment: sum,
    }));
  };

  // get minus on value as per the unit
  const handleMinusClick = (index) => {
    let data = productArray;
    data[index].value = data[index].value - data[index].perUnit;
    data[index].addOnValue = data[index].addOnValue - 1;
    setProductArray([...data]);
    let sum = 0;
    data.forEach((element) => {
      let perUnitValue = element.value;
      if (perUnitValue === undefined) {
        return;
      } else {
        sum += perUnitValue;
      }
    });
    setPaymentTotal((prev) => ({
      ...prev,
      unitPayment: sum,
    }));
  };

  const navigateFunction = () => {
    const dataSelectedArr = productArray
      .filter(
        (el) => el.selectedFund !== "" || el.enterFund !== "" || el.value !== 0
      )
      .map((el, index) => ({
        campaign_product_id: el.campaign_product_id,
        amount: el.value || el.enterFund || el.selectedFund,
        unit: el.addOnValue,
        product_type: el.service,
        quantity: el.addOnValue,
      }));

    navigate("/checkout", {
      state: {
        donation_products: dataSelectedArr,
        campaign_request_id: id,
        amount: paymentTotal,
        isRecurring: false,
        from: "page",
      },
    });
  };
  const handleFacebookShare = () => {
    // Create a new URL object
    const url = new URL(window.location.href);
    // Get the endpoint or path
    const endpoint = url.pathname; // This will give you "/explore-campaigns/24"

    const urlToShare = `https://www.digidonation.com${endpoint}`;

    // Check if the Facebook SDK is already loaded
    if (window.FB) {
      window.FB.ui({
        method: "share",
        href: urlToShare,
      });
    } else {
      // Load the Facebook SDK asynchronously
      loadFacebookSDK(() => {
        window.FB.ui({
          method: "share",
          href: urlToShare,
        });
      });
    }
  };
  const handleTwitterShare = () => {
    const tweetText = "Check out this donation campaign on our website!"; // Customize your tweet text
    // Create a new URL object
    const url = new URL(window.location.href);

    // Get the endpoint or path
    const endpoint = url.pathname; // This will give you "/explore-campaigns/24"

    const urlToShare = `https://www.digidonation.com${endpoint}`; // Replace with your actual campaign URL

    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText
    )}&url=${encodeURIComponent(urlToShare)}`;

    window.open(twitterShareUrl, "_blank");
  };

  const handleWhatsAppShare = () => {
    const textToShare = "Check out this donation campaign on our website: "; // Customize your share message
    // Create a new URL object
    const url = new URL(window.location.href);

    // Get the endpoint or path
    const endpoint = url.pathname; // This will give you "/explore-campaigns/24"

    const urlToShare = `https://www.digidonation.com${endpoint}`; // Replace with your actual campaign URL

    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      textToShare + urlToShare
    )}`;

    window.open(whatsappShareUrl, "_blank");
  };
  const handleCopyLink = () => {
    // Create a new URL object
    const url = new URL(window.location.href);

    // Get the endpoint or path
    const endpoint = url.pathname; // This will give you "/explore-campaigns/24"

    const campaignUrl = `https://www.digidonation.com${endpoint}`;
    navigator.clipboard.writeText(campaignUrl).then(() => {
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 3000); // Reset the copy success message after 3 seconds
    });
  };
  const loadFacebookSDK = (callback) => {
    // Load the Facebook SDK asynchronously
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "your-facebook-app-id", // Replace with your actual Facebook App ID
        autoLogAppEvents: true,
        xfbml: true,
        version: "v11.0", // Replace with the desired version
      });
      if (callback) {
        callback();
      }
    };

    // Load the Facebook SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };

  useEffect(() => {
    // Load Facebook SDK on component mount
    loadFacebookSDK();
  }, []);

  return (
    <>
      <Box className="homePageWrapper">
        <PaymentSuccess
          open={showPaymentSuccess}
          setOpen={setShowPaymentSuccess}
        />
        <div className="campaignDetails-header">
          <Grid container spacing={3} rowSpacing={0} className="row">
            {loader ? (
              <Grid xs={12} md={6} textAlign={"center"} paddingBottom={0}>
                <div className="loader">
                  <div class="loading"></div>
                </div>
              </Grid>
            ) : (
              <Grid xs={12} md={6} textAlign={"center"} paddingBottom={0}>
                <img src={campaginDetail.banner_image} alt="" />
              </Grid>
            )}
            <Grid xs={12} md={6} textAlign={"center"} paddingBottom={0}>
              <div className="right-sec">
                <h3>
                  <span>{campaginDetail?.title?.split(" ")[0]}</span>
                  {campaginDetail?.title?.slice(
                    campaginDetail?.title?.split(" ")[0]?.length
                  )}
                </h3>

                <p className="sub_desc mb-4">
                  {campaginDetail?.short_description}
                </p>

                <div className="progress-bar mt-8">
                  <div
                    className="progress"
                    style={{
                      width: `${
                        (Number(campaginDetail?.total_collection) /
                          Number(campaginDetail?.fund_required)) *
                        100
                      }%`,
                    }}
                  ></div>
                </div>
                <div className="target-div">
                  <p>
                    Raised:{" "}
                    <span>
                      {"₹ " +
                        formatIndianRupee(
                          Number(campaginDetail?.total_collection)
                        )}
                    </span>
                  </p>
                  <p>
                    Goal:{" "}
                    <span>
                      {"₹ " +
                        formatIndianRupee(
                          Number(campaginDetail?.fund_required)
                        )}
                    </span>
                  </p>
                </div>
                <div className="grey_div">
                  <p>
                    <WatchLaterOutlinedIcon />
                    <span className="mx-2">
                      {campaginDetail?.days_left}
                    </span>{" "}
                    Days Left
                  </p>
                  <p>
                    <PersonIcon />
                    <span className="mx-2">
                      {campaginDetail?.bakers_count}
                    </span>{" "}
                    Backers
                  </p>
                </div>
                <div className="badge-sec">
                  <Tooltip title="Tax -benefits available" arrow>
                    <span className="badge tb">
                      Tax Benefits
                      <InfoIcon />
                    </span>
                  </Tooltip>
                  <Tooltip title="This campaign is verified" arrow>
                    <span className="badge verified">
                      Verified
                      <InfoIcon />
                    </span>
                  </Tooltip>
                  <span className="badge medical">
                    {campaginDetail?.campaign_category?.name}
                  </span>
                </div>

                {/* <Button variant="contained" className="donate_sec_btn">
                Donate Monthly
              </Button> */}
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="main_section">
          <Grid container spacing={3} className="row">
            <Grid xs={12} md={7}>
              <div className="section-tags pb-4 pt-4">
                <ul>
                  <li>
                    <Link
                      className="nav-link"
                      onClick={() => {
                        scrollToSection(productTab);
                      }}
                    >
                      Products
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link"
                      onClick={() => {
                        scrollToSection(projectTab);
                      }}
                    >
                      Project
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link"
                      onClick={() => {
                        scrollToSection(updateTab);
                      }}
                    >
                      Updates
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="project-sec">
                <h4 className="mt-3" ref={productTab}>
                  Products
                </h4>

                {loader ? (
                  <div className="loader">
                    <div class="loading"></div>
                  </div>
                ) : (
                  <Grid container spacing={3}>
                    {productArray.map((data, index) =>
                      data.service === "service" ? (
                        <Grid xs={12} md={6}>
                          <div className="product_box">
                            <div className="text-center">
                              <img src={data.images[0]} alt="" />
                            </div>
                            <h5>{data.title}</h5>
                            <h6>
                              <span>
                                ₹ {formatIndianRupee(data.raised_value)}
                              </span>{" "}
                              raised out of ₹{" "}
                              {formatIndianRupee(data.totalUnit * data.perUnit)}
                            </h6>
                            <div className="progress-bar">
                              <div
                                className="progress"
                                style={{
                                  width: `${
                                    (Number(data.raised_value) /
                                      Number(data.totalUnit * data.perUnit)) *
                                    100
                                  }%`,
                                }}
                              ></div>
                            </div>
                            <p className="unit_amt mb-3">
                              ₹ {formatIndianRupee(data.perUnit)}/Unit
                            </p>

                            {/* <form onChange={(e) => testFunct(e.target.value)}>
                          <div className="amt-sec-btn mb-3">
                            <span className="radioBox1 ">
                              <input
                                // checked={data.selectedFund === "500"}
                                type="radio"
                                id={`rr-${index}`}
                                name={`amtFilters`}
                                // onClick={(e) => changeValue(e, index)}
                                value="500"
                              />
                              <label htmlFor={`rr-${index}`}>₹ 500</label>
                            </span>
                            <span className="radioBox1">
                              <input
                                // checked={data.selectedFund === "1000"}
                                type="radio"
                                id={`ee-${index}`}
                                name={`amtFilters`}
                                // onClick={(e) => changeValue(e, index)}
                                value="1000"
                              />
                              <label htmlFor={`ee-${index}`}>₹ 1000</label>
                            </span>
                            <span className="radioBox1">
                              <input
                                // checked={data.selectedFund === "1500"}
                                type="radio"
                                id={`ss-${index}`}
                                name={`amtFilters`}
                                // onClick={(e) => changeValue(e, index)}
                                value="1500"
                              />
                              <label htmlFor={`ss-${index}`}>₹ 1500</label>
                            </span>
                          </div>
                        </form> */}

                            <div className="amt-sec-btn mb-3">
                              <ul>
                                {data.perUnit * data.totalUnit -
                                  (data.raised_value +
                                    Number(
                                      data.selectedFund ? data.selectedFund : 0
                                    )) <
                                500 ? (
                                  ""
                                ) : (
                                  <span className="radioBox1 active">
                                    <li
                                      onClick={() =>
                                        changeValue("500", index, "button")
                                      }
                                    >
                                      500
                                    </li>
                                  </span>
                                )}
                                {data.perUnit * data.totalUnit -
                                  (data.raised_value +
                                    Number(
                                      data.selectedFund ? data.selectedFund : 0
                                    )) <
                                1000 ? (
                                  ""
                                ) : (
                                  <span className="radioBox1">
                                    <li
                                      onClick={() =>
                                        changeValue("1000", index, "button")
                                      }
                                    >
                                      1000
                                    </li>
                                  </span>
                                )}
                                {data.perUnit * data.totalUnit -
                                  (data.raised_value +
                                    Number(
                                      data.selectedFund ? data.selectedFund : 0
                                    )) <
                                1500 ? (
                                  ""
                                ) : (
                                  <span className="radioBox1">
                                    <li
                                      onClick={() =>
                                        changeValue("1500", index, "button")
                                      }
                                    >
                                      1500
                                    </li>
                                  </span>
                                )}
                              </ul>
                            </div>
                            {data.perUnit * data.totalUnit -
                              data.raised_value ===
                            0 ? (
                              <Alert severity="success">
                                Amount achieved ! you can't donate more amount
                                to {data.title}{" "}
                              </Alert>
                            ) : (
                              <div className="input-box">
                                <input
                                  className="inputfield"
                                  type="text"
                                  value={formatIndianRupee(data.enterFund)}
                                  placeholder="Enter Amount"
                                  onChange={(e) => {
                                    const numericValue = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    changeValue(numericValue, index);
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </Grid>
                      ) : (
                        <Grid xs={12} md={6}>
                          <div className="product_box">
                            <div className="text-center">
                              <img src={data.images[0]} alt="" />
                            </div>
                            <h5>{data.title}</h5>
                            <h6>
                              <span> {data.raised_value} </span> of{" "}
                              {data.totalUnit} Quantity Obtained
                            </h6>
                            <div className="progress-bar">
                              <div
                                className="progress"
                                style={{
                                  width: `${
                                    (Number(data.raised_value) /
                                      Number(data.totalUnit)) *
                                    100
                                  }%`,
                                }}
                              ></div>
                            </div>
                            <p className="unit_amt">₹ {data.perUnit}/Unit</p>

                            <div className="text-center">
                              {data.value === 0 &&
                              data.addOnValue + data.raised_value >=
                                data.totalUnit ? (
                                <Alert
                                  severity="success"
                                  style={{ fontSize: "12px" }}
                                >
                                  Goal reached! No more {data.title} needed.
                                </Alert>
                              ) : (
                                <button className="add-button">
                                  {data.value === 0 ? (
                                    <span onClick={() => handleClick(index)}>
                                      Add +
                                    </span>
                                  ) : (
                                    <>
                                      <span
                                        className="minus-icon pl-1 pr-1"
                                        onClick={() => handleMinusClick(index)}
                                      >
                                        -
                                      </span>

                                      <span className="ml-3 mr-3">
                                        {data.addOnValue}
                                      </span>
                                      {data.addOnValue + data.raised_value >=
                                      data.totalUnit ? (
                                        ""
                                      ) : (
                                        <span
                                          className="plus-icon pl-1 pr-1"
                                          onClick={() => handleClick(index)}
                                        >
                                          +
                                        </span>
                                      )}
                                    </>
                                  )}
                                </button>
                              )}
                            </div>
                          </div>
                        </Grid>
                      )
                    )}
                  </Grid>
                )}
                <h4 className="mt-3" ref={projectTab}>
                  Project
                </h4>
                {campaginDetail.youtube_video_url && 
                <iframe
                  width="100%"
                  height="500"
                  src={campaginDetail.youtube_video_url}
                  title="Help ALAI Give 350+ Abandoned And Injured Dogs A Better Life"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                  className="mb-4"
                ></iframe>}
                {campaginDetail.description && (
                  <p className="camp_desc_p">
                    {campaginDetail?.description.substring(
                      0,
                      campaginDetail?.description?.length / 2
                    )}
                  </p>
                )}
                {campaginDetail &&
                  campaginDetail?.images?.length > 0 &&
                  campaginDetail?.images.map((item) => (
                    <img
                      className="camp_desc_img"
                      src={item}
                      alt="campaign_image"
                    />
                  ))}

                {/* <h5 className="camp_desc_heading">Beneficiary NGO's</h5> */}
                <ol className="camp_desc_list">
                  {campaginDetail.description && (
                    <p className="camp_desc_p">
                      {campaginDetail?.description.substring(
                        campaginDetail?.description?.length / 2,
                        campaginDetail?.description.length
                      )}
                    </p>
                  )}
                </ol>
                {/* <img
                className="camp_desc_img"
                src="./images/campaign_img2.svg"
                alt="campaign_image"
              /> */}
                <div ref={updateTab}>
                  {" "}
                  <CampaignUpdates id={id} />
                </div>
              </div>
            </Grid>
            <Grid xs={12} md={5}>
              <div className="payment-sec">
                <h4>Spread the word</h4>
                <div className="social-links">
                  <Link>
                    <FacebookRoundedIcon
                      className="fb"
                      onClick={() => handleFacebookShare()}
                    />
                  </Link>
                  <Link>
                    <TwitterIcon
                      className="tw"
                      onClick={() => handleTwitterShare()}
                    />
                  </Link>
                  <Link>
                    <WhatsAppIcon
                      className="wp"
                      onClick={() => handleWhatsAppShare()}
                    />
                  </Link>
                  <Tooltip title="Copy-link">
                    <Link>
                      <LinkIcon
                        className="link"
                        onClick={() => handleCopyLink()}
                      />
                    </Link>
                  </Tooltip>
                </div>
                <div className="payment-box">
                  <h5>Payment</h5>
                  <div className="input-box">
                    <span>
                      <img src={rupeeSymbolImg} alt="" />
                    </span>
                    <input
                      className="inputfield"
                      type="text"
                      value={formatIndianRupee(
                        paymentTotal.enterPayment + paymentTotal.unitPayment
                      )}
                      onChange={() => {
                        if (!isToastVisible) {
                          toast.error("Please Add some product to Donate");
                          isToastVisible = true;

                          setTimeout(() => {
                            isToastVisible = false;
                          }, 3000);
                        }
                      }}
                      placeholder="0"
                    />
                  </div>
                  <h6>Donate Via:</h6>
                  <div className="tabsSection">
                    {/* Render buttons */}
                    <div>
                      <Link
                        className={`nav-link ${
                          activepaymentTypeLink === "upi" ? "active" : ""
                        }`}
                        onClick={() => {
                          setActiveTab(0);
                          setActivePaymentTypeLink("upi");
                        }}
                      >
                        UPI
                      </Link>
                      <Link
                        className={`nav-link ${
                          activepaymentTypeLink === "cards" ? "active" : ""
                        }`}
                        onClick={() => {
                          setActiveTab(1);
                          setActivePaymentTypeLink("cards");
                        }}
                      >
                        Cards
                      </Link>
                    </div>

                    {/* Render active tab content */}
                    {activeTab === 0 ? (
                      <Grid container spacing={3} className="row">
                        <Grid xs={12} paddingLeft={0}>
                          <ul>
                            <li className="p_box_outer">
                              <Link>
                                <div className="p_box mb-1">
                                  <img src={phonpeImg} alt="" />
                                </div>
                                <p>PhonePe</p>
                              </Link>
                            </li>
                            <li className="p_box_outer">
                              <Link>
                                <div className="p_box mb-1">
                                  <img src={gpayImg} alt="" />
                                </div>
                                <p>Gpay</p>
                              </Link>
                            </li>
                            <li className="p_box_outer">
                              <Link>
                                <div className="p_box mb-1">
                                  <img src={paytmImg} alt="" />
                                </div>
                                <p>Paytm</p>
                              </Link>
                            </li>
                            <li className="p_box_outer">
                              <Link>
                                <div className="p_box mb-1">
                                  <img src={upiImg} alt="" />
                                </div>
                                <p>Bhim UPI</p>
                              </Link>
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={3} className="row">
                        <Grid xs={12} paddingLeft={0}>
                          <ul>
                            <li className="p_box_outer">
                              <Link>
                                <div className="p_box mb-1">
                                  <CreditCardIcon />
                                </div>
                                <p>Cr/Db Card</p>
                              </Link>
                            </li>
                            <li className="p_box_outer">
                              <Link>
                                <div className="p_box mb-1">
                                  <CardGiftcardIcon />
                                </div>
                                <p>Gift Card</p>
                              </Link>
                            </li>
                            <li className="p_box_outer">
                              <Link>
                                <div className="p_box mb-1">
                                  <DevicesIcon />
                                </div>
                                <p>Netbanking</p>
                              </Link>
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                  <Button
                    variant="contained"
                    className="donate_sec_btn mb-4"
                    disabled={
                      paymentTotal.enterPayment + paymentTotal.unitPayment > 0
                        ? false
                        : true
                    }
                    onClick={navigateFunction}
                  >
                    Donate
                  </Button>
                </div>

                <DonarList id={id} />

                <ReferChangeMaker />
              </div>
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
};

export default ExploreCampaginDetail;
