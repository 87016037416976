import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/material";
import '../../pages/homepage/homepage.scss'
import "./../../index.scss";
import Navbar from "../../components/header/header";
import { Link } from "react-router-dom";

const TermsConditions = () => {
  return (
    <Box className="homePageWrapper">
      <Navbar style={{ color: "white" }} />

      <div className="contentwrapper">
        <Grid container spacing={3} className="row mt-5">
          <Grid xs={12} textAlign={"center"}>
            <h2>
              Terms <span>Of Use</span>
            </h2>
          </Grid>
        </Grid>

        <Grid container spacing={3} className="row">
          <Grid xs={12} textAlign={"center"}>
            <p>
              Welcome to www.digidonation.com <span>(DigiDonation)</span>.
              DigiDonation is owned and operated by{" "}
              <span>Nav Bharat Sewa Sanstha</span>, a non-profit organization
              incorporated under the laws of India. DigiDonation facilitates
              various services, including but not limited to:
            </p>
            <ul>
              <li>
                1. Allowing donors to make monetary contributions to support
                campaigns initiated by beneficiaries seeking donations-in-kind.
              </li>
              <li>
                2. Assisting trusts in raising donations from donors to support
                the aforementioned campaigns.
              </li>
              <li>
                3. Marketing and outreach for campaigns, facilitating donations
                from trusts.
              </li>
              <li>
                4. Procurement and delivery of selected materials for trusts to
                donate to the campaigns.
              </li>
              <li>
                5. Helping trusts identify beneficiaries who wish to run
                campaigns to raise donations-in-kind.
              </li>
            </ul>
            <p>
              By accessing and using DigiDonation, you agree to comply with and
              be bound by the following Terms of Use, which govern your use of
              the content, functionality, and services offered on or through
              DigiDonation. Please read these terms carefully before using our
              website. If you do not agree to these terms, please refrain from
              using DigiDonation.
            </p>
            <p>
              By accessing, browsing, and/or using{" "}
              <Link className="hyperlink" to={"/"}>
                {" "}
                www.digidonation.com{" "}
              </Link>
              (DigiDonation), you accept and agree to comply with and be bound
              by these terms and conditions (Terms of Use) and our privacy
              policy (Privacy Policy), which can be accessed at
              <Link className="hyperlink" to={"/privacy-policy"}>
                {" "}
                www.digidonation.com/privacy-policy
              </Link>
              . The Terms of Use and Privacy Policy govern the relationship
              between DigiDonation and Nav Bharat Sewa Sanstha. Please read
              these Terms of Use carefully before proceeding. Your use of
              DigiDonation indicates your willingness to abide by and be bound
              by these Terms of Use. If Nav Bharat Sewa Sanstha does not agree
              with the Terms of Use, please do not use DigiDonation. These Terms
              of Use (which may be updated at the sole discretion of
              DigiDonation without intimation) will be effective immediately
              upon Nav Bharat Sewa Sanstha's acceptance of the terms and
              conditions, with their use of DigiDonation being indicative of
              such acceptance. These Terms of Use shall be enforceable against
              Nav Bharat Sewa Sanstha in the same manner as any other written
              agreement.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>1. Definitions:</h5>
            <ul>
              <li>
                <span>Beneficiary(ies)</span>: Refers to all NGOs, trusts,
                societies, individuals, or persons running campaigns on
                DigiDonation to raise donations in kind.
              </li>
              <li>
                <span>Campaign(s)</span>: Encompasses various charitable
                campaigns on DigiDonation seeking support in kind for the
                beneficiaries. The campaigns are not for commercial purposes.
              </li>
              <li>
                <span>Donor(s):</span> Denotes the users of DigiDonation who
                wish to donate materials to support the beneficiaries'
                campaigns.
              </li>
              <li>
                <span>Platform or DigiDonation</span>: Represents
                www.digidonation.com, including its mobile and/or tablet
                applications, operated and managed by Nav Bharat Sewa Sanstha.
              </li>
              <li>
                <span>Materials</span>: Refers to specific items required by
                beneficiaries under their campaigns, selected by donors on
                DigiDonation. The materials will be purchased and donated by the
                trusts.
              </li>
              <li>
                <span>Nav Bharat Sewa Sanstha</span> or Us or We or Our:
                Represents Nav Bharat Sewa Sanstha, a nonprofit organization
                incorporated under the laws of India
              </li>
              <li>
                <span>Services:</span> Encompasses the facilitation of
                connections between beneficiaries, trusts, and donors by
                DigiDonation, allowing donors to contribute in-kind donations to
                support charitable objectives.
              </li>
              <li>
                <span>Trust(s)</span> shall mean the DigiDonation Foundation
                and/or such other charitable trust(s)/organisation(s) as may be
                on boarded from time to time by the Platform which are desirous
                of provision of Materials for the Beneficiaries for the
                Campaigns.
              </li>
            </ul>
          </Grid>
          <Grid xs={12}>
            <h5>2. Scope of Services:</h5>
            <p>
              DigiDonation replaces traditional monetary donations with product
              donations, promoting trust and transparency for donors. The
              platform enables beneficiaries to create and share campaigns to
              raise support for the materials they require. DigiDonation
              facilitates the connection between donors and trusts, enabling
              donors to direct their donations toward specific materials to be
              provided to beneficiaries as per their needs. Donations are made
              through the platform's payment channels and directed to the
              respective trusts aiming to supply the materials to beneficiaries.
            </p>
            <p>
              Donors have the liberty to choose the campaigns they wish to
              support and select the materials they wish to entrust to the trust
              for donation. The trust will then purchase the chosen materials
              and deliver them to the beneficiaries of the selected campaign. In
              the event that the selected materials become unavailable or
              unsuitable for the campaign due to unavoidable circumstances, the
              trust will purchase alternative materials of equivalent value to
              fulfill the donor's intent.
            </p>
            <p>
              DigiDonation performs a preliminary verification of the campaign
              objectives at its discretion. However, it's essential to
              understand that neither DigiDonation nor the trusts conduct
              detailed checks on the genuineness of the campaigns by the
              beneficiaries. Thus, DigiDonation and/or the trusts make no
              representation regarding the authenticity and accuracy of the
              beneficiaries' campaigns and assume no liability for any
              transactions between donors, trusts, and campaigns/beneficiaries.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>3. Services Description:</h5>
            <p>
              The Services offered by DigiDonation are subject to the
              limitations outlined in these Terms of Use and are governed by
              DigiDonation's Privacy Policy. Nav Bharat Sewa Sanstha reserves
              the right to discontinue or modify any or all aspects of the
              Services at its sole discretion without prior notice to any party.
            </p>
            <p>
              The Services provided aim to benefit the beneficiaries by
              facilitating easy online donations for donors. When a donor
              participates in a campaign, they become a backer for the campaign,
              and the relevant trusts and/or beneficiaries may be granted access
              to the donor's provided details. Donors have the option to remain
              anonymous if they choose not to disclose their identity to the
              beneficiary. Donors are advised to carefully consider the
              information they choose to share or make public, as Nav Bharat
              Sewa Sanstha is not responsible for such activities or the
              accuracy of shared information.
            </p>
            <p>
              Upon the delivery of materials to the beneficiaries by the trust,
              donors will receive electronic messages and email alerts as
              intimation. Nav Bharat Sewa Sanstha assumes no responsibility for
              the materials displayed on the platform.
            </p>
            <p>
              During the checkout process, donors are required to disclose
              whether they hold an Indian passport and provide passport details
              in the General Details section of the My Profile tab. Donations
              will only be enabled for applicable trusts registered under
              India's Foreign Contribution Regulation Act, 2010 (FCRA) if the
              donor is not an Indian passport holder.
            </p>
            <p>
              The donor represents and warrants that they will not attempt,
              directly or indirectly, to make any donations for prohibited
              purposes under the law, including those outlined in FCRA.
              Prohibited purposes include donations for the benefit of
              candidates for election, media personnel, government officials,
              political parties, organizations of political nature, companies
              involved in broadcasting news or current affairs programs, or any
              other purposes specified by the Central Government.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>4. Campaigns:</h5>
            <p>
              - Beneficiaries are legally obligated to provide accurate and
              truthful information regarding the details of their campaigns
              hosted on DigiDonation. Campaign descriptions must not be
              misleading and should clearly state the need and use of the
              materials being raised. DigiDonation reserves the right to delete
              a campaign if it contains false, misleading, or inaccurate
              information.
            </p>
            <p>
              - Beneficiaries must ensure that their campaigns and information
              do not infringe upon intellectual property, trade secrets, or
              other proprietary rights, or rights of publicity or privacy of
              third parties. Campaign listings should only include text
              descriptions, graphics, and pictures that describe the campaign
              and the materials required.
            </p>
            <p>
              - There are no fees or charges for beneficiaries to put up their
              campaigns on DigiDonation.
            </p>
            <p>
              - Beneficiaries are responsible for providing donors, applicable
              trusts, and DigiDonation with accurate progress reports and any
              other information required to track the utilization of donated
              materials. At DigiDonation's request, beneficiaries must furnish
              the necessary information to donors.
            </p>
            <p>
              - Campaigns on DigiDonation shall be exclusive to the platform,
              and beneficiaries shall not approach other platforms or entities
              to onboard their campaigns while the campaign is live on
              DigiDonation without prior written consent.
            </p>
            <p>
              - Beneficiaries shall not seek or attempt to make donations for
              any prohibited purposes under applicable laws, including those
              outlined in FCRA and the Prevention of Money Laundering Act, 2002.
            </p>
            <p>
              - DigiDonation and the trusts reserve the right to refuse delivery
              of selected materials and reverse payments if questionable
              transactions, violation of laws, or collusion between donors and
              beneficiaries is suspected.
            </p>
            <p>
              - In case of suspected credibility issues with donors and/or
              beneficiaries, DigiDonation may direct the trust to return
              payments and stop material delivery at its sole discretion.
            </p>
            <p>
              - Beneficiaries represent and warrant the accuracy and
              completeness of information provided in connection with their
              campaigns and agree to comply with all relevant laws.
            </p>
            <p>
              - Please note that certain individuals and entities, as per FCRA,
              cannot receive foreign contributions, and beneficiaries must
              comply with these regulations.
            </p>
            <p>
              - Beneficiaries are not permitted to use any content created by
              DigiDonation without written consent for other material donations
              or awareness purposes.
            </p>
            <p>
              - DigiDonation and/or the trusts have the right to terminate
              engagement with beneficiaries and their campaigns if there is
              evidence of wrong material utilization or absence of required
              regulatory approvals.
            </p>
            <p>
              - The cancellation, rejection, interruption, removal, or
              suspension of any campaign is at the sole discretion of
              DigiDonation and/or the trusts, and the trust may request
              DigiDonation to take such actions.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>5. Donations</h5>
            <p>
              - Donors can select the campaign of their choice and donate an
              amount to the trust, either in Indian Rupees or through the
              payment channels provided on the DigiDonation platform.
            </p>
            <p>
              - Donors have the freedom to choose the specific materials they
              wish the trust to provide to the campaigns they support. The trust
              will collect the donations and supply the materials selected by
              the respective donors, subject to the terms specified in the Terms
              of Use.
            </p>
            <p>
              - If a donation payment fails, the donor will be notified
              accordingly. Once a donation is made on the platform, no refunds
              will be processed.
            </p>
            <p>
              - DigiDonation uses the services of third-party payment service
              providers to process payments. Payment information and identifiers
              will be shared with such third parties according to their terms
              and conditions. Any currency conversion fees, bank charges, or
              transaction fees imposed by the donor's bank or payment
              intermediaries will be the responsibility of the donor.
            </p>
            <p>
              - The delivery of materials will occur either at the end of the
              campaign period or when the campaign reaches its stipulated goal,
              whichever comes first. However, the trusts may, at their sole
              discretion, supply the materials earlier upon the request of the
              campaign or if the need arises.
            </p>
            <p>
              - The trusts will directly ship and supply the materials to the
              beneficiaries identified by the donors. Once materials are
              delivered, the trusts and Nav Bharat Sewa Sanstha are under no
              contractual or other obligations to the donors or beneficiaries.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>6. Refunds</h5>
            <p>
              - Donations made to any campaign on DigiDonation are
              non-refundable. However, if the donation amount is debited more
              than once, the donor will receive a refund for the duplicate
              debit.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>7. Trusts</h5>
            <p>
              - Donors make their donations through designated payment channels
              on the platform. They select the campaign and materials of their
              choice. The relevant trust will purchase and supply the materials
              as indicated by the donor, and the trust will donate and deliver
              the materials to the beneficiaries.
            </p>
            <p>
              - The trust shall not alter the choice of the campaign selected by
              the donor. However, in unavoidable circumstances like supply
              shortages or fluctuations in demand, the trust may provide
              alternative materials and communicate this to the donor in good
              faith.
            </p>
            <p>
              - DigiDonation facilitates the connection between donors and
              relevant trusts so that donations can be directed towards
              materials required by beneficiaries. DigiDonation may charge fees
              to the trusts for the services provided, and donations made by
              donors will be subject to deduction of these fees
            </p>
            <p>
              - Trusts may be eligible to provide tax exemptions to donors as
              per Section 80G of the Income Tax Act, 1961, and applicable rules.
              Trusts are responsible for providing accurate information about
              their certification, and if certification is obtained unlawfully,
              DigiDonation reserves the right to terminate the trust's
              engagement and inform the authorities as required by law.
            </p>
            <p>
              - DigiDonation directs donation payments from donors to trusts
              based on the trusts' representations. However, DigiDonation does
              not independently verify the accuracy of these representations.
              Trusts aggregating donations from foreign donors must obtain the
              necessary registrations and licenses, including under FCRA, at
              their own cost and responsibility. If any registration or license
              is obtained unlawfully, DigiDonation may terminate the trust's
              engagement and inform the authorities as per the law. Trusts are
              required to promptly inform DigiDonation of any changes in their
              registration/license status in writing.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>8. Payment for Materials</h5>
            <p>
              - Donors' donation amounts shall be processed through the payment
              gateway channels and directed to the respective trusts.
            </p>
            <p>
              - The trust will use the donated amount to purchase the selected
              materials and supply them to the beneficiaries of the campaign.
            </p>
            <p>
              - Beneficiaries will send relevant photographs of the donations
              made to each donor as confirmation.
            </p>
            <p>
              - The costs for the materials and any additional charges will be
              based on the rates in effect at the time of the transaction.
              Material availability and pricing may vary geographically and over
              time.
            </p>
            <p>
              - DigiDonation reserves the right to include or remove materials
              and amend corresponding donation values as necessary from time to
              time.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>9. Beneficiary Information</h5>
            <p>
              - Beneficiaries must submit and share accurate details and
              necessary documents of their
              trust/society/NGOs/institutions/establishments/companies/organizations
              on DigiDonation for verification purposes or compliance with
              applicable laws. DigiDonation may authenticate these details as
              part of its checks. If found false or inaccurate, the trust is
              entitled to report to authorities and take necessary legal action.
              DigiDonation shall not be liable for any actions taken by the
              trusts in such cases and shall be indemnified from any losses
              arising due to incorrect/false details provided.
            </p>
            <p>
              - Beneficiaries must ensure that their information and campaigns
              do not infringe upon intellectual property, trade secrets, or
              rights of third parties. Campaign listings may only include text
              descriptions, graphics, and pictures that accurately describe the
              campaign and the use of funds raised. If any rewards or promotions
              are offered to donors, the beneficiary acknowledges the
              responsibility to keep products in stock and fulfill services.
            </p>
            <p>
              - DigiDonation and the trusts do not take responsibility for the
              genuineness or accuracy of the beneficiaries. DigiDonation does
              not charge any fees or commission from the beneficiaries.
            </p>
            <p>
              - While DigiDonation endeavors to pre-screen and qualify
              campaigns, this process is not error-free or exhaustive. Donors
              are encouraged to conduct their own due diligence and not solely
              rely on DigiDonation for campaign accuracy.
            </p>
            <p>
              - DigiDonation may afford donors the opportunity to ask
              beneficiaries questions regarding the campaign to test its
              soundness. By making a donation, each donor represents that they
              have conducted appropriate due diligence and not solely relied on
              DigiDonation for information accuracy.
            </p>
            <p>
              - Neither DigiDonation nor its employees represent or warrant that
              beneficiaries or any other person will use donated materials in
              the manner represented.
            </p>
            <p>
              - DigiDonation may promote some campaigns at its discretion but is
              not obligated to do so for all campaigns. DigiDonation is not
              liable for the success of campaigns promoted by the platform.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>10. Content Available</h5>
            <p>
              - You acknowledge that Nav Bharat Sewa Sanstha does not make any
              representations or warranties about the content available on
              DigiDonation , including campaigns, beneficiaries, donors,
              materials, data, and information. Nav Bharat Sewa Sanstha is not
              liable for any content, including infringing content, errors,
              omissions, or any loss or damage incurred from the use of such
              content accessed through or made available via DigiDonation .
            </p>
            <p>
              - The images of materials provided by Nav Bharat Sewa Sanstha are
              for representational purposes only, and actual products and
              packaging may vary from the preview images shown. The information
              and descriptions of materials on DigiDonation are general and may
              not include all terms, exclusions, and conditions applicable to
              the materials.
            </p>
            <p>
              - You are not allowed to modify, alter, tamper with, repair, or
              create derivative works of DigiDonation or any content and
              services. Reverse engineering, disassembling, or decompiling the
              platform to avoid fees or usage limits is prohibited. Removing,
              modifying, or obscuring any intellectual property or proprietary
              rights of Nav Bharat Sewa Sanstha and its licensors is not
              allowed.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>11. Content Supplied by You</h5>
            <p>
              - You represent, warrant, and agree that any content or details
              (User Content) shared by you with Nav Bharat Sewa Sanstha does not
              violate or infringe upon the rights of any third party, including
              copyright, trademark, privacy, publicity, or other personal or
              proprietary rights. It should not breach any obligations, such as
              confidentiality, and should not contain defamatory, libelous, or
              unlawful material.
            </p>
            <p>
              - All User Content will be solely your responsibility as the
              author/creator of such content. Nav Bharat Sewa Sanstha will not
              be liable for any content, including User Content, created or
              shared by you on DigiDonation .
            </p>
            <p>
              - By sharing User Content and details with Nav Bharat Sewa Sanstha
              , you grant the platform a personal, limited, revocable,
              non-transferable, and non-exclusive right to use the content and
              details, including trademarks, service marks, logos, etc., for
              providing the services. You also grant Nav Bharat Sewa Sanstha the
              right to display the User Content and details on DigiDonation and
              promote or distribute it for the access of beneficiaries and
              donors.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>12. Links to Third Party Sites</h5>
            <p>
              - DigiDonation may contain links to other websites owned and
              operated by beneficiaries or third parties not related to Nav
              Bharat Sewa Sanstha. Nav Bharat Sewa Sanstha is not responsible
              for the content of any Linked Websites or hyperlinks contained in
              them and makes no representation or warranty regarding the content
              of such third-party sites.
            </p>
            <p>
              - These links are provided for your convenience, and the inclusion
              of any link does not imply endorsement or recommendation of the
              Linked Website by Nav Bharat Sewa Sanstha. Your use of Linked
              Websites is subject to the terms and conditions of those sites.
            </p>
            <p>
              - DigiDonation may also contain third-party advertisements. The
              display of such advertisements does not imply endorsement or
              recommendation of the relevant advertiser, its products, or
              services by Nav Bharat Sewa Sanstha. Any interaction between you
              and the relevant third party is at your own risk, and Nav Bharat
              Sewa Sanstha shall not be liable for any issues arising from such
              interactions.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>13. Access to DigiDonation</h5>
            <p>
              - The Services offered by DigiDonation are not available to minors
              under the age of eighteen (18) for any reason whatsoever. Donors
              may be from across the world to transact on DigiDonation. However,
              donors not holding Indian passports can only transact on campaigns
              put up by trusts which have the necessary registration and
              licenses, including FCRA as required under applicable law.
            </p>
            <p>
              - Registration: You agree to (a) only provide accurate and current
              information about yourself, (b) maintain the security of Your
              passwords and identification, (c) promptly update the email
              address listed in connection with Your account to keep it accurate
              so that we can contact You, and (d) be fully responsible for all
              uses of Your account. You must not set up an account on behalf of
              another individual or entity unless You are duly authorized to do
              so.
            </p>
            <p>
              - Using the Services on DigiDonation does not grant You any
              permanent membership, shareholder status, or affiliation with Nav
              Bharat Sewa Sanstha for any purposes whatsoever, nor shall You
              have any of the rights of statutory members of Nav Bharat Sewa
              Sanstha.
            </p>
            <p>
              - We may notify You or request You to accept additional or special
              terms and conditions regarding Your access to certain features or
              services on DigiDonation. You agree to access or use such features
              and services only in accordance with such additional or special
              terms and conditions as if they were incorporated herein these
              Terms of Use.
            </p>
            <p>
              - As a guest, You can navigate and browse but cannot avail any
              Services without providing Us with basic details about Your name,
              e-mail ID, and phone number.
            </p>
            <p>
              - You agree that any information provided by You shall always be
              accurate, correct, and complete. If You use the Services, You
              shall be responsible for maintaining the confidentiality of Your
              Account and password, and You agree to accept responsibility for
              all activities that occur under Your Account. Nav Bharat Sewa
              Sanstha reserves the right to change or amend these Terms of Use
              from time to time without any prior notice to You. We also reserve
              the right to refuse registration of, or cancel, accounts which We
              deem inappropriate, including for breach of these Terms of Use.
            </p>
            <p>
              - We may cancel, reject, remove, or suspend any party, at our sole
              and absolute discretion, from access and/or use of DigiDonation in
              case of any breach of the Terms of Use. We shall not be liable to
              provide any explanation or reasoning subsequent to such action
              taken by Us.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>14. Prohibited Conduct</h5>
            <p>
              You shall not engage in any of the following prohibited conduct
              while using DigiDonation:
            </p>
            <ul>
              <li>
                - (a) Use DigiDonation for any illegal purpose or in violation
                of any local, state, national, or international laws.
              </li>
              <li>
                - (b) Violate or encourage others to violate any right of or
                obligation to a third party, including but not limited to
                infringing, misappropriating, or violating intellectual
                property, confidentiality, or privacy rights.
              </li>
            </ul>
            <p>
              DigiDonation reserves the right to remove comments or postings on
              the platform or any social media page or site or otherwise that
              violate any applicable laws or are defamatory in nature. We shall
              remove User Content or any other content which we have reason to
              believe:
            </p>
            <ul>
              <li>
                - (a) Violates any copyright, trademark, patent, or any
                intellectual property right, discloses a trade secret or
                confidential information in violation of a confidentiality or
                non-disclosure agreement.
              </li>
              <li>
                - (b) Pertains to unsolicited advertising and messages,
                promotional materials, junk mail, spam, etc.
              </li>
              <li>
                - (c) Is reasonably believed to be (without independent
                verification) fraudulent, unlawful or contains or promotes
                defamatory or illegal information and activities, images,
                materials, or descriptions.
              </li>
              <li>
                - (d) Defames, harasses, stalks, threatens, or otherwise
                violates the legal rights of others or is invasive of another’s
                privacy rights.
              </li>
              <li>
                - (e) Is vulgar, obscene, offensive, or contains pornography or
                nudity or is otherwise objectionable.
              </li>
              <li>
                - (f) Is racially or ethnically offensive and encourages any
                conduct that may constitute a criminal offense or that gives
                rise to civil liability.
              </li>
              <li>
                - (g) Sends or stores material containing software viruses,
                worms, or other harmful computer code, files, scripts, or
                programs.
              </li>
              <li>
                - (h) Attempts unauthorized access to or use of data, systems,
                or networks, including any attempt to probe, scan, or test the
                vulnerability of a system or network or to breach security or
                authentication measures without express authorization of the
                owner of the system or network.
              </li>
              <li>
                - (i) Attempts unauthorized attempt to monitor data or traffic
                on any network or system without express authorization of the
                owner of the system or network.
              </li>
              <li>
                - (j) Interferes with service to any user, host, or network,
                including, without limitation, mail bombing, flooding,
                deliberate attempts to overload a system, and broadcast attacks.
              </li>
              <li>- (k) Sends harassing or threatening transmissions.</li>
              <li>
                - (l) Probes for means of gaining unauthorized access to
                computers or networks.
              </li>
              <li>
                - (m) Restricts or inhibits any other party from using the
                service.
              </li>
              <li>
                - (n) Provides false information or misrepresents yourself.
              </li>
            </ul>
            <p>
              Any violation of these prohibitions may result in the suspension
              or termination of your access to DigiDonation and may also be
              reported to the relevant authorities for further action as
              required by law.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>15. DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h5>
            <p>
              DigiDonation does not endorse or act on behalf of any third party.
              In the event You engage the services of / interact with any third
              party, through DigiDonation, Nav Bharat Sewa Sanstha will not be
              liable to You for any act or omission by such third party in
              relation thereto.
            </p>
            <p>
              We hereby expressly disclaim all warranties and representations of
              any kind with respect to any and all content and features
              available on DigiDonation, including but not limited to (a)
              warranties as to merchantability or use for a particular purpose
              whether or not Nav Bharat Sewa Sanstha knows or has reason to know
              or has been advised of any such purpose; (b) warranties as to any
              results to be obtained from any use of the Content or information
              derived from use of DigiDonation; (c) any continued access or
              uptime; and (d) any time period within which downtime shall be
              rectified.
            </p>
            <p>
              Nav Bharat Sewa Sanstha shall have no liability for any losses,
              direct or indirect, in contract, tort, or otherwise, incurred in
              connection with the Content, use of the Platform or any Services,
              any third party claims, any damages incurred by any party that
              arises out of the use of Our Platform or Services, either directly
              or indirectly, including any virus attacks, service attacks and/or
              Trojan attacks, including but not limited to loss of revenue or
              profit or any other commercial or economic loss or for any
              decision made or action taken by any third party in reliance upon
              the Content.
            </p>
            <p>
              DigiDonation, all the materials and services, included on or
              otherwise made available to You through this Platform is provided
              by Nav Bharat Sewa Sanstha on an "as is" and "as available" basis
              without any representation or warranties, express or implied
              except otherwise specified in writing including without
              limitation, accuracy of content, non-infringement,
              non-interference, merchantability or fitness for a particular
              purpose or that the Services (including the Software) will be
              uninterrupted, timely or error-free. Without prejudice to the
              foregoing paragraph, Nav Bharat Sewa Sanstha does not warrant
              that:
            </p>
            <p>
              DigiDonation will be constantly available, or available at all;
            </p>
            <p>
              The information on DigiDonation or provided through the website is
              complete, true, accurate or not misleading; or
            </p>
            <p>
              The quality of any products, services, information, or other
              material that You obtain through DigiDonation will meet Your
              expectations.
            </p>
            <p>
              We use internally developed systems for providing You access to
              and facilitating Your use of DigiDonation. These systems may
              encounter technical or other limitations, and computer and
              communications hardware systems might experience interruptions.
              Further, We continually enhance and improve these systems in order
              to accommodate the level of use of DigiDonation. We may also add
              additional features and functionality to DigiDonation that might
              result in the need to develop or license additional technologies.
              Increased utilization of DigiDonation or providing new features or
              functionality may cause unanticipated system disruptions, slower
              response times, degradation in levels of customer service, and
              delays in reporting accurate financial information. You agree that
              We shall not be liable to You or to any third party claiming
              through You, for any such failures contemplated herein.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>16. INDEMNITY</h5>
            <p>
              You agree to indemnify and hold Nav Bharat Sewa Sanstha harmless
              from: (i) any actions, claims, demands, suits, damages, losses,
              penalties, interest, and other charges and expenses (including
              legal fees and other dispute resolution costs) made by any third
              party due to or arising out of Your use of DigiDonation, any
              violation of the terms of these Terms of Use, or any other
              agreement executed with Nav Bharat Sewa Sanstha or another user by
              You; (ii) any acts or deeds, including any non-compliance or
              violation of any applicable law, rules, regulations on Your part;
              (iii) fraud committed by You; (iv) Your infringement of any
              intellectual property or other right of any person or entity; (v)
              or as a result of any threatening, libelous, obscene, harassing,
              or offensive material posted/transmitted by You on DigiDonation.
              Your obligations under this Paragraph 16 shall survive the
              termination of these Terms of Use.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>17. FOREIGN JURISDICTIONS</h5>
            <p>
              Nav Bharat Sewa Sanstha makes no representation that the content
              contained on DigiDonation is appropriate or to be used or accessed
              outside of the Republic of India. You are expressly notified that
              many states and foreign countries all have their own regulations
              that must be observed in relation to Your use or access of
              DigiDonation. You must make Your own assessment regarding and are
              responsible for compliance with regulatory requirements as may be
              applicable to Your use and access of DigiDonation in such
              jurisdictions.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>18. PRIVACY AND CONFIDENTIALITY</h5>
            <p>
              Your use of the Platform is governed by Our privacy policy, which
              can be located at [https://www.DigiDonations.com/privacypolicy].
              Please read Our privacy policy to understand Our information
              collection and usage practices. You may obtain certain
              confidential information (Confidential Information). You
              acknowledge and agree to hold all Confidential Information in
              strict confidence. Title and all interests to all Confidential
              Information shall be vested in Us. Your obligations regarding
              Confidential Information will survive the termination of these
              Terms of Use. Upon such termination, You must stop forthwith using
              any Confidential Information to which You may have been exposed
              during Your use of DigiDonation.
            </p>
            <p>
              You agree that Your obligations under this Paragraph 18 are
              necessary and reasonable to protect Our business and expressly
              agree that monetary damages would be inadequate to compensate for
              any breach of any covenant or agreement set forth herein.
              Accordingly, You agree and acknowledge that any such violation or
              threatened violation will cause irreparable harm and injury to Nav
              Bharat Sewa Sanstha, and that, in addition to any other remedies
              that may be available, in law, equity, or otherwise, Nav Bharat
              Sewa Sanstha shall be entitled to obtain injunctive relief against
              the threatened breach of the terms of this Paragraph or the
              continuation of any such breach.
            </p>
            <p>
              The restrictions in this Paragraph 18 shall not apply to the
              disclosure of Confidential Information by either party if and to
              the extent the disclosure is: (a) required by the applicable law
              of any jurisdiction; (b) required by any applicable securities
              exchange, supervisory or regulatory or governmental body to which
              the relevant party is subject or submits, wherever situated,
              whether or not the requirement for disclosure has the force of
              law; or (c) made to employees and representatives on a
              need-to-know basis, provided that such persons are required to
              treat such information as confidential through written agreement
              in terms that are no less strict than this Paragraph. Provided
              that the restriction in this Paragraph 18 shall not apply to
              disclosures of Confidential Information made by Nav Bharat Sewa
              Sanstha to its shareholders, directors, managers, advisors,
              potential investors, and/or affiliates. Provided further that Nav
              Bharat Sewa Sanstha may disclose the Confidential Information to
              third-party vendors and customers or pursuant to partnering
              arrangements without the restriction of this Paragraph 18 provided
              that such persons are required to treat such information as
              confidential through written agreement in terms that are no less
              strict than this Paragraph.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>19. INTELLECTUAL PROPERTY</h5>
            <p>
              DigiDonation is operated by and is the sole property of Nav Bharat
              Sewa Sanstha. Any and all material on this Platform, including
              images, illustrations, audio and video clips, trade names,
              proprietary information and knowledge, technology, databases, or
              rights with respect thereto necessary for Our business as is now
              being operated are protected by copyrights, trademarks, and other
              intellectual property rights that are owned by Us or by other
              parties that have licensed such material to Us, or such parties
              having ownership of content uploaded on the Platform. You agree
              that You shall not, whether directly or indirectly, copy,
              reproduce, republish, post, upload, transmit, or distribute such
              material in any manner and through any media, including by way of
              e-mail or other electronic means, and You shall not assist any
              other person in doing so. All right, title, and interest in and to
              the DigiDonation (excluding content uploaded specifically by a
              user) is and will remain the exclusive property of Nav Bharat Sewa
              Sanstha and/or its licensors.
            </p>
            <p>
              You agree that these Terms of Use do not entitle You to any
              support, upgrades, updates, add-ons, patches, enhancements, or
              fixes for the DigiDonation (Updates). We may, however,
              occasionally provide automatic Updates to the DigiDonation at Our
              sole discretion (and without any advanced notification to You).
              Any such Updates shall become part of the services and subject to
              these Terms of Use.
            </p>
            <p>
              From time to time, we test various aspects of our DigiDonation,
              including Our website, user interfaces, service levels, plans,
              promotions, features, availability of content, delivery, and
              pricing, and We reserve the right to include You in or exclude You
              from these tests without notice.
            </p>
            <p>
              By submitting any content or material to DigiDonation, including
              names, logos, and/or any material that is protected through
              trademark, copyright, wordmark, etc. of Yourself or Your
              enterprise or Your startup, You hereby grant Nav Bharat Sewa
              Sanstha a worldwide, non-exclusive, royalty-free, perpetual,
              sublicensable, and transferable license to use, reproduce,
              distribute, prepare derivative works of, display, and perform such
              content in connection with the DigiDonation and Nav Bharat Sewa
              Sanstha's business, including without limitation for promoting and
              redistributing part or all of the DigiDonation (and derivative
              works thereof) in any media formats and through any media
              channels. In the event it does so, Nav Bharat Sewa Sanstha will
              seek to maintain the confidentiality of the content consistent
              with Your account classification, but once again cannot guarantee
              any such confidentiality. You also grant each user of Nav Bharat
              Sewa Sanstha a non-exclusive license to access Your content
              through the DigiDonation and to use, reproduce, distribute,
              prepare derivative works of, display, and perform such content as
              permitted through the functionality of the Platform and under
              these Terms of Use.
            </p>
            <p>
              Nav Bharat Sewa Sanstha shall have no obligation to monitor or
              enforce any intellectual property rights that may be associated
              with the content provided by You on DigiDonation.
            </p>
            <p>
              If You are a copyright owner or an agent thereof and believe that
              any content on DigiDonation infringes upon Your rights, You may
              contact digidonations@gmail.com and provide details requested in
              regards to such infringement.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>20. GOVERNING LAW AND DISPUTE RESOLUTION</h5>
            <p>
              These Terms of Use shall be governed by and construed in
              accordance with the laws of the Republic of India, and subject to
              the provisions of arbitration set out herein, the courts at
              Hyderabad, India shall have the sole and exclusive jurisdiction in
              relation to any disputes (defined below) arising out of or in
              connection with these Terms of Use or the use of DigiDonation,
              pursuant to the provisions of this Paragraph 19. There may exist a
              conflict of laws due to the varied geographical locations of the
              parties; however, the parties acknowledge and agree to refer any
              disputes only to the courts at Hyderabad, India and to follow the
              manner of resolution as provided in this paragraph.
            </p>
            <p>
              Any action, dispute, or difference arising under or relating to
              these Terms of Use (Dispute) shall, at the first instance, be
              resolved through good faith negotiations between the parties
              hereto, which negotiations shall begin promptly, within 15
              (fifteen) days after a party has delivered to the other party a
              written request for such consultation. If the parties are unable
              to resolve the dispute in question within 15 (fifteen) days of the
              commencement of negotiations, such Dispute shall be referred to
              and finally resolved by arbitration in accordance with the
              Arbitration and Conciliation Act, 1996, as amended from time to
              time, and rules prescribed thereunder. During the arbitration,
              except for the matters under dispute, Nav Bharat Sewa Sanstha and
              You shall continue to exercise their respective remaining rights
              and fulfill their respective remaining obligations under these
              Terms of Use.
            </p>
            <p>
              The arbitration shall be conducted by a sole arbitrator jointly
              appointed by Nav Bharat Sewa Sanstha and You. If parties fail to
              appoint an arbitrator within 30 (thirty) days after the service of
              the notice of arbitration, such arbitrator shall be appointed in
              accordance with the provisions of the Arbitration and Conciliation
              Act, 1996. The seat and venue of arbitration shall be [Hyderabad],
              India.
            </p>
            <p>
              The language of the arbitration proceedings and of all written
              decisions and correspondence relating to the arbitration shall be
              English.
            </p>
            <p>
              You agree and acknowledge that the provisions of these Terms of
              Use, including but not limited to Paragraph 17 (Privacy and
              Confidentiality) and Paragraph 18 (Intellectual Property), are of
              importance to Nav Bharat Sewa Sanstha, and monetary compensation
              may not constitute adequate relief and remedy to Nav Bharat Sewa
              Sanstha for non-performance by You of your obligations thereunder.
              Accordingly, Nav Bharat Sewa Sanstha shall be entitled to claim
              specific performance and seek mandatory and/or perpetual
              injunctions requiring You to perform your duties and obligations
              under such provisions.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>21. SEVERABILITY</h5>
            <p>
              If any term, provision, covenant, or restriction of these Terms of
              Use is held by a court of competent jurisdiction to be invalid,
              void, or unenforceable, the remainder of the terms, provisions,
              covenants, and restrictions of these Terms of Use shall remain in
              full force and effect and shall in no way be affected, impaired,
              or invalidated.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>22. NO WAIVER</h5>
            <p>
              The rights and remedies available under these Terms of Use may be
              exercised as often as necessary and are cumulative and not
              exclusive of rights or remedies provided by law. They may be
              waived only in writing. Delay in exercising or non-exercise of any
              such right or remedy does not constitute a waiver of that right or
              remedy, or any other right or remedy.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>23. NOTICES</h5>
            <p>
              Unless otherwise provided herein, all notices and other
              communications shall be deemed to be served to any Donor, any
              Trust, and/or any Beneficiaries through notifications on the
              Portal or at the registered email ID provided by such user as soon
              as We send such notice. It is the sole responsibility of such
              users to update their respective information, including but not
              limited to the e-mail addresses, phone numbers, and postal
              addresses (if any). We shall not be responsible for non-receipt of
              any such communication sent by Us.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>24. FORCE MAJEURE</h5>
            <p>
              We shall not be liable or responsible for the non-performance or
              delay in carrying out any Service(s) due to any act, event, or
              circumstance beyond Our reasonable control, which may affect the
              performance and/or execution of Our Services under the Terms of
              Use, including, but not limited to fire, flood, explosion, war,
              riots, acts of Government Authorities, or any events or
              circumstances analogous to the foregoing. The Donors and/or Trusts
              and/or Beneficiaries hereby irrevocably, wholly, and completely
              indemnify Us in the manner and terms set forth in Paragraph 15.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5>25. GENERAL</h5>
            <p>
              These Terms of Use comprise the full and complete agreement
              between You and Nav Bharat Sewa Sanstha with respect to the use of
              DigiDonation and supersede and cancel all prior communications,
              understandings, and agreements between You and Nav Bharat Sewa
              Sanstha, whether written or oral, expressed or implied, with
              respect thereto. Nothing herein creates any power or authority for
              any party, nor does anything herein contain any provision to
              assume or create any obligation or responsibility on any party on
              behalf of each other. The Terms of Use will not be construed to
              create or imply any partnership, agency, or joint venture
              relationship.
            </p>
            <p>
              Nav Bharat Sewa Sanstha shall be entitled to assign their rights
              and obligations hereunder to any affiliate, third party or as part
              of any restructuring, business combination, merger or acquisition
              without any prior notice provided to any other party.
            </p>
            <p>
              Any notice to be given in connection with these Terms of Use shall
              be delivered via email to Nav Bharat Sewa Sanstha at
              digidonations@gmail.com or to You at the email address provided by
              You in Your profile. You consent to the use of electronic
              communications in order to the electronic delivery of notices in
              relation to any matter under these Terms of Use.
            </p>
          </Grid>
        </Grid>
      </div>
{/* <Footer /> */}
    </Box>
  );
};

export default TermsConditions;
