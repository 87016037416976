import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DonateMonthly from "./donateMonthlyPayment";
import DonateOnce from "./donateOnce";
import { UNSAFE_NavigationContext } from "react-router-dom";
// import { useBackListener } from "../../helper/helpingFuntions";
const Checkout = () => {
  const location = useLocation("");
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(location?.state?.isRecurring);
  const [activeLink, setActiveLink] = useState("donateOnce");
  console.log("location in checkout", location?.state?.from)

  useEffect(() => {
    if(location?.state?.from === undefined){
      navigate('/')
    }
  },[])



  return (
    <Box className="homePageWrapper">
      <div className="request-header">
        <Grid container spacing={3} className="row">
          <Grid xs={12} md={12} paddingTop={"6px"} paddingBottom={0}>
            <div className="tabsSection" style={{ textAlign: "center" }}>
              {/* Render buttons */}
              <div>
                <>
                  {activeTab === false ?
                    <>
                      <Link
                        className={`nav-link ${activeLink === "donateOnce" ? "active" : ""
                          }`}
                        onClick={() => {
                          setActiveTab(false);
                          setActiveLink("donateOnce");
                        }}
                      >
                        Donate Once
                      </Link>
                      <Link
                        className={`nav-link ${activeLink === "donateMonthly" ? "active" : ""
                          }`}
                        onClick={() => {
                          setActiveTab(false);
                          setActiveLink("donateMonthly");
                        }}
                      >
                        Donate Monthly
                      </Link>
                    </>
                    : activeTab === true ?
                      <Link
                        className={`nav-link ${activeLink === "donateMonthly" ? "active" : ""
                          }`}
                        onClick={() => {
                          setActiveTab(!activeTab);
                          setActiveLink("donateMonthly");
                        }}
                      >
                        Donate Monthly
                      </Link> : null
                  }
                </>
              </div>

              <Grid container spacing={3} className="row">
                <Grid xs={12}>
                  <p>
                    {activeTab === false
                      ? `By supporting Digidonation, you are helping us reach out
                      to more campaigns like this and scale our impact. Learn
                      More`
                      : "Your First Donation Will Be Support To This Campaign"}
                  </p>
                </Grid>
                {activeTab === false && activeLink === "donateOnce" ? (
                  <>
                    <DonateOnce />
                  </>
                ) :
                  activeLink === "donateMonthly" && activeTab === false ? <DonateMonthly /> :
                    (
                      <DonateMonthly />
                    )}

              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>

    </Box>
  );
};

export default Checkout;
