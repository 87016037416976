import "./index.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Layout from "./components/layout";
import Homepage from "./pages/homepage";
import ExploreCampaignsPage from "./pages/exploreCampaigns";
import DonateMonthlyPage from "./pages/donateMonthly/donateMonthly";
import RequestCampaign from "./pages/requestCampaign";
import ExploreCampaginDetail from "./pages/exploreCampaginDetail";
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Checkout from "./pages/CheckoutPage/Checkout";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import DonateMonthlyCampaignDetailPage from "./pages/DonateMonthlyCampaignDetailPage/DonateMonthlyCampaignDetailPage";
import FaqPage from "./pages/FaqPage/FaqPage";

import TestWrap from "./shared/testWrap";



import AboutUs from "./pages/AboutUs/AboutUs";
import Blogs from "./pages/Blogs/Blogs";
import Signin from './pages/SignUpJourney/Signin';
import Signup from "./pages/SignUpJourney/Signup"
import ForgotPassword from "./pages/SignUpJourney/ForgotPassword"
import BlogDetailPage from "./pages/Blogs/BlogDetailPage/BlogDetailPage";
import { GlobalContextProvider } from "./context/globalContext";


function App() {
  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      if (process.env.NODE_ENV === "development") {
        // Code for local development environment
        console.log("Running in development mode");
      } else if (process.env.NODE_ENV === "production") {
        // Code for production environment
        console.log("Running in production mode");
        console.log = () => { };
      } else {
        // Fallback code for other environments
        console.log("Running in an unknown environment");
      }
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }
  return (
    <>
      <BrowserRouter>
        <GlobalContextProvider>
          <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options
              className: "",
              duration: 5000,
              style: {
                background: "#363636",
                color: "#fff",
              },

              // Default options for specific types
              success: {
                duration: 5000,
                theme: {
                  primary: "green",
                  secondary: "black",
                },
              },
            }}
          />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Homepage />} />
              <Route
                path="/explore-campaigns"
                element={<ExploreCampaignsPage />}
              />
              <Route
                path="/explore-campaigns/:id"
                element={<ExploreCampaginDetail />}
              />
              {/*<Route path='/explore-campaigns/:id' element={<SingleCampaignDetails />} />*/}
              <Route path="/donate-monthly" element={<DonateMonthlyPage />} />
              <Route path="/request-campaign" element={<RequestCampaign />} />
              {/* <Route path='/campagin-detail' element={<ExploreCampaginDetail />} /> */}
              <Route path="/terms-of-use" element={<TermsConditions />} />
              <Route path="/faq" element={<FaqPage />} />

              <Route path="/privacy-policy" element={<PrivacyPolicy />} />



              <Route path='/dm-campaign-detail/:id' element={<DonateMonthlyCampaignDetailPage />} />
              <Route path='/about-us' element={<AboutUs />} />
              <Route path='/blogs' element={<Blogs />} />
              <Route path='/blog-detail/:id' element={<BlogDetailPage />} />
            </Route>

            {/* <Route path='/checkout' element={<Checkout />} /> */}
            <Route path='/checkout' element={<TestWrap component={<Checkout />} />} />



            <Route path="/sign-up" element={<Signup />} />
            <Route path="/sign-in" element={<Signin />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />

          </Routes>
        </GlobalContextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;

